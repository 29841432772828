import * as Actions from '../actions';

const initialState = {
    workOrderList: [],
    workOrders: {},
    trips: [],
    searchText: '',
    technicians: [],
    date: new Date(),
    loading: true,
    dragging: false,
    infoWindow: null,
    selectedWO: null,
    schedule: {
        board: {},
        list: [],
    },
    dispatchFolderDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    },
    dispatchBoardDialog: {
        type: 'new',
        props: {
            open: false
        },
        data: null
    },
    selectedBoard: 'default'
};

const dispatchBoardsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_BOARD_DATE:
            {
                const { date } = action;
                return {
                    ...state,
                    date,
                    loading: true,
                    trips: [],
                    selectedWO: null
                }
            }
        case Actions.SET_SELECTED_DISPATCH_BOARD:
            {
                const { selectedBoard } = action;
                return {
                    ...state,
                    selectedBoard
                }
            }
        case Actions.SET_SELECTED_WO: {
            const { selectedWO } = action;
            return {
                ...state,
                selectedWO
            }
        }
        case Actions.SET_DRAGGING:
            {
                const { dragging } = action;
                return {
                    ...state,
                    dragging,
                }
            }
        case Actions.OPEN_INFO_WINDOW:
            {
                const { id } = action;
                return {
                    ...state,
                    infoWindow: id,
                }
            }
        case Actions.SET_SEARCH_TEXT:
            {
                return {
                    ...state,
                    searchText: action.searchText
                };
            }
        case Actions.OPEN_NEW_DISPATCH_FOLDER_DIALOG:
            {
                return {
                    ...state,
                    dispatchFolderDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: null
                    }
                };
            }
        case Actions.CLOSE_NEW_DISPATCH_FOLDER_DIALOG:
            {
                return {
                    ...state,
                    dispatchFolderDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_DISPATCH_FOLDER_DIALOG:
            {
                return {
                    ...state,
                    dispatchFolderDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_DISPATCH_FOLDER_DIALOG:
            {
                return {
                    ...state,
                    dispatchFolderDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_NEW_DISPATCH_BOARD_DIALOG:
            {
                return {
                    ...state,
                    dispatchBoardDialog: {
                        type: 'new',
                        props: {
                            open: true
                        },
                        data: null
                    }
                };
            }
        case Actions.CLOSE_NEW_DISPATCH_BOARD_DIALOG:
            {
                return {
                    ...state,
                    dispatchBoardDialog: {
                        type: 'new',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.OPEN_EDIT_DISPATCH_BOARD_DIALOG:
            {
                return {
                    ...state,
                    dispatchBoardDialog: {
                        type: 'edit',
                        props: {
                            open: true
                        },
                        data: action.data
                    }
                };
            }
        case Actions.CLOSE_EDIT_DISPATCH_BOARD_DIALOG:
            {
                return {
                    ...state,
                    dispatchBoardDialog: {
                        type: 'edit',
                        props: {
                            open: false
                        },
                        data: null
                    }
                };
            }
        case Actions.SET_WORK_ORDERS:
            {
                const { WorkOrders } = action;
                const { workOrders } = state;
                let wobj = {};
                WorkOrders.map((obj) => {
                    wobj[obj.ID] = {
                        ...obj,
                        value: workOrders[obj.ID] && workOrders[obj.ID].value ? workOrders[obj.ID].value : 2,
                        tripValue: workOrders[obj.ID] && workOrders[obj.ID].tripValue ? workOrders[obj.ID].tripValue : 2,
                        open: workOrders[obj.ID] && workOrders[obj.ID].open ? workOrders[obj.ID].open : false,
                    };

                });
                return {
                    ...state,
                    workOrderList: WorkOrders,
                    workOrders: wobj,
                }
            }
        case Actions.SET_WORK_ORDER_INFO:
            {
                const { WorkOrder } = action;
                let { workOrders, workOrderList } = state;
                workOrders[WorkOrder.ID] = WorkOrder;

                return {
                    ...state,
                    workOrders
                }
            }
        case Actions.SET_SCHEDULE:
            {
                const { schedule, trips, technicians } = action;
                return {
                    ...state,
                    technicians,
                    schedule,
                    trips,
                    loading: false,
                }
            }
        case Actions.RESET_SCHEDULE:
            {
                const { schedule, trips, technicians } = action;
                return {
                    ...state,
                    technicians: [],
                    schedule: {
                        board: {},
                        list: [],
                    },
                    trips: [],
                }
            }
        case Actions.TRIPS_LOADED:
            {
                const { loading } = action;
                return {
                    ...state,
                    loading,
                }
            }
        default:
            {
                return state;
            }
    }
};

export default dispatchBoardsReducer;
