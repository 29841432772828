import _ from '@lodash'
import {
  AppBar,
  Avatar,
  Button,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grow,
  Icon,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Slide,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { DatePicker } from '@material-ui/pickers'
import { drawDOM, exportPDF } from '@progress/kendo-drawing'
import { saveAs } from '@progress/kendo-file-saver'
import axios from 'axios'
import classNames from 'classnames'
import parse from 'html-react-parser'
import EmailLogDialog from 'main/content/apps/mail/EmailLogDialog'
import MailCompose from 'main/content/apps/mail/MailCompose'
import ConfirmationDialog from 'main/content/components/dialogs/confirmation'
import { formatPhone } from 'main/functions'
import moment from 'moment'
import React, { Component } from 'react'
import Media from 'react-media'
import { connect } from 'react-redux'
import SignatureCanvas from 'react-signature-canvas'
import { bindActionCreators } from 'redux'
import { updateInvoiceNotes } from 'store/actions'
import CacheManager from 'store/middlewares/cache'
import Autocomplete from '../../components/autocomplete/Autocomplete'
import ChangeDialog from '../../components/change-log/ChangeDialog'
import DebounceButton from '../../components/debounce-button/DebounceButton'
import ChecklistGenerator from '../checklists/ChecklistGenerator'
import { openEditCustomerSiteDialog } from '../customer-sites/store/actions'
import { openEditCustomerDialog } from '../customers/store/actions'
import { openEditEstimateDialog } from '../estimates/store/actions'
import { openMinimizedDialog } from '../minimized-dialogs/store/actions/minimized-dialogs.actions'
import TimecardLaborDialog from '../timecards/TimecardLaborDialog'
import { updateTrip } from '../trips/store/actions'
import * as WorkOrderScopeActions from '../work-order-scopes/store/actions'
import { openEditWorkOrderUsageDialog } from '../work-order-usages/store/actions'
import { openEditWorkOrderDialog } from '../work-orders/store/actions'
import format from 'string-template'
import InvoicePayment from './InvoicePayment'
import InvoicePaymentRecord from './InvoicePaymentRecord'
import * as Actions from './store/actions'
import { openPreloadWorkInvoiceDialog } from './store/actions/invoices.actions'
import DraggableDialog from '../draggable-dialog/DraggableDialog'
import Loading from '../../components/loading/loading'
import InvoiceAddPaymentComponent from './InvoiceAddPaymentComponent'
import { showMessage } from 'store/actions'

const SlideUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const offline = new CacheManager()

const styles = theme => ({
  root: {},
  paper: {
    margin: 12,
    minHeight: 'calc(100% - 64px)',
  },
  formControl: {
    marginBottom: 24,
    marginTop: 24,
  },
  customerSelect: {
    padding: 2,
    minHeight: 512,
    minWidth: '25%',
    border: '1px solid lightgrey',
    marginRight: 12,
    marginBottom: 24,
    borderRadius: 5,
    background: 'rgba(0, 0, 0, .05)',
  },
  customerCard: {
    borderRadius: 5,
    boxShadow: '2px 4px 8px 0px rgba(0, 0, 0, .5)',
  },
  customerAvatar: {
    backgroundColor: theme.palette.primary.main,
  },
  chip: {
    color: '#fff',
    backgroundColor: '#3f3f3f',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    marginBottom: 12,
    marginLeft: '-4px !important',
    height: 24,
    maxWidth: '66%',
  },
  chipLabel: {
    paddingLeft: 8,
    fontWeight: 'bold',
    color: '#fff',
    paddingBottom: 2,
  },
  chipAvatar: {
    marginLeft: '0 !important',
    color: '#3f3f3f',
    backgroundColor: '#3f3f3f',
    border: '3px solid #3f3f3f',
    width: '32px !important',
    height: '32px !important',
  },
  chipAvatarIcon: {
    color: '#3f3f3f',
    backgroundColor: '#fff',
  },
  signature: {
    border: '1px dotted #3f3f3f',
    borderRadius: 5,
  },
  signaturePlaceholder: {},
  signatureImg: {},
  clearBtn: {
    backgroundColor: '#d60202',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#8c0202',
    },
    '&:active': {
      backgroundColor: '#790101',
    },
    '&:focus': {
      backgroundColor: '#8c0202',
    },
  },
  okBtn: {
    backgroundColor: '#3f3f3f',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#111111',
    },
    '&:active': {
      backgroundColor: 'black',
    },
    '&:focus': {
      backgroundColor: '#111111',
    },
  },
  signaturePaper: {
    padding: 16,
    marginLeft: 8,
    marginRight: 8,
  },
  postedMatch: {
    color: theme.palette.secondary.dark,
  },
  postedNoMatch: {
    color: theme.palette.error[500],
  },
  notPosted: {
    color: '#5f5f5f',
  },
  postedBatch: {
    color: theme.palette.primary.main,
  },
  userAvatar: {
    backgroundColor: '#555555',
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  qty: {
    backgroundColor: '#3f3f3f',
    height: 18,
    minWidth: 24,
    fontSize: 13,
    paddingLeft: 6,
    paddingRight: 6,
    marginRight: 6,
    marginTop: 3,
    float: 'left',
    borderRadius: 12,
    color: '#fff',
    textAlign: 'center',
  },
  invHeader: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    fontSize: 36,
    fontWeight: 'bold',
    color: '#333',
  },
  invSubHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'right',
    marginTop: 4,
    color: '#333',
  },
  dividerPlain: {
    color: '#fff',
  },
  dividerHighlight: {
    background: theme.palette.primary.main,
    color: '#fff',
  },
})

const newInvoiceState = {
  scopesToRender: [],
  shouldRenderDiv: false,
  ID: null,
  Co: null,
  Invoice: null,
  InvoiceNumber: null,
  InvoiceDate: null,
  Status: null,
  Customer: null,
  PayTerms: null,
  DueDate: null,
  DiscountDate: null,
  DiscountRate: null,
  Description: null,
  WorkOrder: null,
  FirstName: null,
  LastName: null,
  BillName: null,
  BillContact: null,
  BillAddress1: null,
  BillAddress2: null,
  BillCity: null,
  BillState: null,
  BillZip: null,
  BillCountry: null,
  BillPhone: null,
  BillEmail: null,
  Technician: null,
  Notes: null,
  DeliveredYN: null,
  DeliveredDate: null,
  DeliveredBy: null,
  SummaryLevel: 'D',
  FormOfPayment: null,
  PaymentConfirmation: null,
  PaymentsCustomer: null,
  PaymentsInvoice: null,
  VoidDate: null,
  hideVoidedPayments: false,
  VoidedBy: null,
  EnteredBy: null,
  EnteredDate: null,
  UpdatedBy: null,
  UpdatedDate: null,
  AttachmentID: null,
  isSigning: false,
  Signature: null,
  SignedBy: null,
  Location: null,
  Site: null,
  Data: {
    ErrMsg: null,
    ID: null,
    Co: null,
    Invoice: null,
    InvoiceNumber: null,
    InvoiceDate: null,
    Status: null,
    Customer: null,
    PayTerms: null,
    DueDate: null,
    DiscountDate: null,
    DiscountRate: null,
    Description: null,
    WorkOrder: null,
    BillName: null,
    BillContact: null,
    BillAddress1: null,
    BillAddress2: null,
    BillCity: null,
    BillState: null,
    BillZip: null,
    BillCountry: null,
    BillPhone: null,
    BillEmail: null,
    Technician: null,
    Notes: null,
    DeliveredYN: null,
    DeliveredDate: null,
    DeliveredBy: null,
    SummaryLevel: 'D',
    FormOfPayment: null,
    PaymentConfirmation: null,
    PaymentsInvoice: null,
    PaymentsCustomer: null,
    VoidDate: null,
    VoidedBy: null,
    EnteredBy: null,
    EnteredDate: null,
    UpdatedBy: null,
    UpdatedDate: null,
    AttachmentID: null,
    isSigning: false,
    Signature: null,
    SignedBy: null,
    Location: null,
    Data: {
      WorkOrder: null,
      Scopes: [],
      Usage: [],
      InvoiceDetails: [],
      Payments: [],
      PaymentDetail: [],
      Attachments: [],
      PaymentMethods: [],
      ErrMsg: null,
    },
  },
  EditFOP: true,
  confirm: false,
  labor: false,
  OfflineID: null,
  addPayment: false,
  isDatepickerOpen: false,
  isDueDatepickerOpen: false,
  showWarrantyDetail: false,
  confirmDelete: false,
  promptPayment: false,
  addPayment: false,
  viewPayment: false,
  closeAfter: false,
  checklistsPDF: null,
  checklistResults: '',
  showEmailLogs: false,
  printPDF: null,
  addScope: false,
  syncing: false,
  editLine: false,
  editingLine: false,
  refreshing: false,
  viewChanges: false,
  editSummary: {},
  editInvoice: false,
  dialogHeight: null,
  minHeight: null,
  location: null,
  isCreatingCustomerPayments: false,
  IsFinanced: false,
  IsApprovedAccount: false,
}

class InvoiceDialog extends Component {
  state = { ...newInvoiceState }

  ref = React.createRef()

  componentDidUpdate(prevProps, prevState, snapshot) {
    const invoiceNotes = this.props.notes
    /**
     * After Dialog Open
     */
    newInvoiceState.Co = this.props.Co

    if (
      !prevProps.invoiceDialog.props.open &&
      this.props.invoiceDialog.props.open
    ) {
      /**
       * Dialog type: 'edit'
       * Update State
       */
      if (
        this.props.invoiceDialog.type === 'edit' &&
        this.props.invoiceDialog.data &&
        !_.isEqual(this.props.invoiceDialog.data, prevProps.invoiceDialog.data)
      ) {
        this.setState(
          {
            ...newInvoiceState,
            ...this.props.invoiceDialog.data,
            isCreatingCustomerPayments: true,
          },
          this.createPaymentsCustomer,
        )
      }

      /**
       * Dialog type: 'new'
       * Update State
       */
      if (
        this.props.invoiceDialog.type === 'new' &&
        !_.isEqual(
          { ...newInvoiceState, ...this.props.invoiceDialog.data },
          prevState,
        )
      ) {
        let { InvoiceNumber, Notes } = this.props.invoiceDialog.data

        const { Scopes } = this.props.workOrderDialog.data.Data
        //const shouldRenderDiv = _.some(Scopes, { ReadyToBillYN: 'N'})
        const shouldRenderDivIndex = _.findIndex(Scopes, function (o) {
          return o.ReadyToBillYN === undefined || o.ReadyToBillYN === 'N'
        })
        const shouldRenderDiv = shouldRenderDivIndex > -1 ? true : false
        //const shouldRenderDiv = true;

        let scopesToRender = []

        if (shouldRenderDiv) {
          scopesToRender = _.filter(Scopes, function (o) {
            return o.ReadyToBillYN === undefined || o.ReadyToBillYN === 'N'
          })
          window['warn'](
            newInvoiceState.scopesToRender,
            newInvoiceState.shouldRenderDiv,
            shouldRenderDiv,
            scopesToRender,
          )
        }

        if (invoiceNotes) {
          const notes = invoiceNotes[InvoiceNumber]
          if (notes) {
            Notes = notes.Notes
          }
          this.setState(
            {
              ...newInvoiceState,
              ...this.props.invoiceDialog.data,
              Notes,
              shouldRenderDiv,
              scopesToRender,
              isCreatingCustomerPayments: true,
            },
            this.createPaymentsCustomer,
          )
        } else {
          this.setState(
            {
              ...newInvoiceState,
              ...this.props.invoiceDialog.data,
              Notes,
              shouldRenderDiv,
              scopesToRender,
              isCreatingCustomerPayments: true,
            },
            this.createPaymentsCustomer,
          )
        }
      }
    }
    if (
      this.props.invoiceDialog.type === 'update' &&
      this.props.invoiceDialog.data &&
      !_.isEqual(this.props.invoiceDialog.data, prevProps.invoiceDialog.data)
    ) {
      this.props.invoiceDialog.type = !this.props.invoiceDialog.data.Invoice
        ? 'new'
        : 'edit'
      const canEmail = Boolean(
        !prevProps.invoiceDialog.data.Invoice &&
          this.props.invoiceDialog.data.Invoice,
      )
      const inv = this.props.invoiceDialog.data.Data
      const scope =
        inv.Data && inv.Data.Scopes && inv.Data.Scopes.length > 0
          ? inv.Data.Scopes[0]
          : null
      const billCust = inv.Data.Customer
        ? inv.Data.Customer
        : scope
          ? scope.Data.Customer || {}
          : {}
      this.setState({ ...this.props.invoiceDialog.data }, () => {
        const { trip } = this.props.invoiceDialog.props
        if (canEmail) {
          if (
            billCust.InvoiceReviewYN !== 'Y' &&
            billCust.MailInvoiceYN !== 'Y'
          ) {
            this.openEmail(true, Boolean(trip && trip.Status < 8))
          } else {
            this.closeComposeDialog()
          }
        }
      })
    }
  }

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  closeComposeDialog = () => {
    this.props.invoiceDialog.type === 'edit'
      ? this.props.closeEditInvoiceDialog()
      : this.props.closeNewInvoiceDialog()
    this.setState({
      ...this.state,
      removedFromSplitscreen: true,
      screenOverlay: null,
      posReset: true,
    })
  }

  closeDialogForSplitscreen = () => {
    this.props.customerDialog.type === 'edit'
      ? this.props.closeEditCustomerDialog()
      : this.props.closeNewCustomerDialog()
  }

  canBeSubmitted() {
    const { InvoiceNumber, Description } = this.state
    return (
      InvoiceNumber &&
      Description &&
      InvoiceNumber.length > 0 &&
      Description.length > 0
    )
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || ''
    sliceSize = sliceSize || 512

    var byteCharacters = atob(b64Data)
    var byteArrays = []

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize)

      var byteNumbers = new Array(slice.length)
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      var byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }

    var blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  formatDate(date) {
    const dt = new Date(date ? date : new Date().toLocaleDateString('en-US'))
    const mm = dt.getMonth() + 1
    const dd = dt.getDate()
    const yyyy = dt.getFullYear()
    const formatted =
      yyyy + '-' + (mm <= 9 ? '0' + mm : mm) + '-' + (dd <= 9 ? '0' + dd : dd)
    return formatted
  }

  sigPad = {}

  clear = () => {
    this.sigPad.clear()
  }

  trim = () => {
    this.setState({
      ...this.state,
      Signature: this.sigPad.getCanvas().toDataURL('image/png'),
      isSigning: false,
    })
  }

  componentDidMount() {
    // this.sigPad = this.sigPad || {};
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('orientationchange', this.handleResize)
  }

  componentWillUnmount() {
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('orientationchange', this.handleResize)
  }

  handleResize = () => {
    // Get the current signature data before resizing
    if (this.sigPad && typeof this.sigPad.toDataURL === 'function') {
      const signatureData = this.sigPad.toDataURL()
      // Handle the resize without clearing the canvas
      this.sigPad.clear()
      this.sigPad.fromDataURL(signatureData)
      // window['warn']('SIG: ', signatureData)
    }
  }

  // getPDF = () => {
  //     const input = document.getElementById('invoice-print');
  //     html2canvas(input)
  //         .then((canvas) => {
  //             const imgData = canvas.toDataURL('image/png');
  //             const pdf = new jsPDF();
  //             pdf.addImage(imgData, 'PNG', 0, 0);
  //             pdf.save(`${this.state.InvoiceNumber}.pdf`);
  //         });
  // }

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }

  exportToPDF = () => {
    const { InvoiceNumber } = this.state
    let gridElement = document.getElementById('invoice-print')
    if (!window.cordova) {
      drawDOM(gridElement, {
        keepTogether: '.no-break',
        paperSize: 'letter',
        margin: 20,
        scale: 0.6,
      })
        .then(group => {
          return exportPDF(group)
        })
        .then(dataUri => {
          var data = dataUri.split(';base64,')[1]
          const blob = this.b64toBlob(data, 'application/pdf')
          saveAs(dataUri, InvoiceNumber)
        })
    } else {
      const { cordova, pdf } = window
      var formCss = ''
      var styles = document.getElementsByTagName('style')
      for (var i = 0; i < styles.length; i++) {
        formCss += `<style>${styles[i].innerHTML}</style>`
      }
      formCss += '<style> .no-print { display:none !important; } </style>'
      let options = {
        documentSize: 'A4',
        type: 'share',
        fileName: `${InvoiceNumber}.pdf`,
      }
      const html = document.getElementById('invoice-print').innerHTML
      pdf
        .fromData(
          `<html><head>${formCss}</head><body>${html}</body></html>`,
          options,
        )
        .then(stats => window['warn']('status', stats)) // it will
        .catch(err => console.err(err))
    }
  }

  emailInvoice = (closeAfter, confirm) => {
    const { InvoiceNumber, InvoiceDate, Notes } = this.state
    const { Co, WorkOrder } = this.state
    const { businessUnits, divisions, templates, companies } = this.props
    const { Scopes, Customer } = this.state.Data.Data
    const { InvoiceDetails, Contacts } = this.state.Data.Data
    const { BusinessUnit, Division } =
      Scopes && Scopes.length > 0
        ? Scopes[0]
        : InvoiceDetails &&
            InvoiceDetails.length > 0 &&
            InvoiceDetails[0].Data &&
            InvoiceDetails[0].Data.Agreement
          ? InvoiceDetails[0].Data.Agreement
          : null
    let margin = {
      top: '.16 in',
      bottom: '.16 in',
      left: '.16 in',
      right: '.16 in',
    }
    if (BusinessUnit && Division && businessUnits && templates) {
      const div = _.find(divisions, { Co, BusinessUnit, Division })
      const {
        InvoiceMarginTop,
        InvoiceMarginBottom,
        InvoiceMarginLeft,
        InvoiceMarginRight,
      } = div
      margin = {
        top: `${((InvoiceMarginTop / 330) * 11).toFixed(2)} in`,
        bottom: `${((InvoiceMarginBottom / 330) * 11).toFixed(2)} in`,
        left: `${((InvoiceMarginLeft / 255) * 8.5).toFixed(2)} in`,
        right: `${((InvoiceMarginRight / 255) * 8.5).toFixed(2)} in`,
      }
    }
    let gridElement = document.getElementById('invoice-print')
    drawDOM(gridElement, {
      keepTogether: '.no-break',
      paperSize: 'letter',
      margin,
      scale: 0.65,
    })
      .then(group => {
        return exportPDF(group)
      })
      .then(dataUri => {
        let html = ''
        const { Co, ID, checklistsPDF } = this.state
        const file = this.dataURLtoFile(dataUri, `${InvoiceNumber}.pdf`)
        const { businessUnits, divisions, templates, companies } = this.props
        const co = _.find(companies, { Co })
        const { Scopes } = this.state.Data.Data
        const { InvoiceDetails, Contacts } = this.state.Data.Data
        const billingContact = Contacts
          ? _.find(Contacts, { BillingYN: 'Y' })
          : null
        const { BusinessUnit, Division } =
          Scopes && Scopes.length > 0
            ? Scopes[0]
            : InvoiceDetails &&
                InvoiceDetails.length > 0 &&
                InvoiceDetails[0].Data &&
                InvoiceDetails[0].Data.Agreement
              ? InvoiceDetails[0].Data.Agreement
              : null
        if (BusinessUnit && Division && businessUnits && templates) {
          const bu = _.find(businessUnits, { Co, BusinessUnit })
          const div = _.find(divisions, { Co, BusinessUnit, Division })
          if (bu && div) {
            const template =
              div.Data.InvoiceEmailTemplate || bu.Data.InvoiceEmailTemplate
            const templateData = {
              Co: Co,
              WorkOrder: WorkOrder,
              Invoice: InvoiceNumber,
              Customer: Customer.Customer,
              Name:
                Customer.Name || `${Customer.FirstName} ${Customer.LastName}`,
              Address: Customer.Address1,
              Phone: Customer.Phone,
              Date: new Date(InvoiceDate).toLocaleDateString('en-US'),
              Notes: Notes,
            }
            window['warn']('invoice email templateData', templateData)
            html = template ? format(template.Markup, templateData) : ''
          }
        }
        const Attachments = [file]
        if (checklistsPDF) {
          Attachments.push(checklistsPDF)
        }
        const data = {
          composeDialog: true,
          Title: 'Email Invoice',
          Icon: 'email',
          To: `${
            billingContact &&
            billingContact.Data.Contact &&
            billingContact.Data.Contact.Email
              ? billingContact.Data.Contact.Email
              : this.state.BillEmail
          };`,
          Subject: `Your Invoice from ${co.Data.Co.Name}`,
          Head: '',
          Message: '',
          Body: html,
          Attachments,
          Type: 'INVOICE',
          RecordID: ID,
          role: 'invoice',
        }
        this.setState({
          ...this.state,
          invoiceEmail: data,
          confirm,
          closeAfter,
        })
      })
  }

  buildInvoiceEmail = () => {
    const { Co } = this.state
    let Body = ``
    let Head = ``
    const data = {
      Head,
      Body,
    }
    return data
  }

  handleDateChange = event => {
    // window["warn"](event);
    const InvoiceDate = event._d
    this.setState({ ...this.state, InvoiceDate, DueDate: InvoiceDate })
  }

  handleDueDateChange = event => {
    // window["warn"](event);
    const DueDate = event._d
    this.setState({ ...this.state, DueDate })
  }

  openDatePicker = e => {
    this.setDatepickerOpen(true)
  }

  openDueDatePicker = e => {
    this.setDueDatepickerOpen(true)
  }

  renderDateLabel = e => (
    <span onClick={this.openDatePicker} className='cursor-pointer'>
      {new Date(this.state.InvoiceDate).toLocaleDateString('en-US')}
    </span>
  )

  setNotes = (InvoiceNumber, Notes) => {
    let { notes } = this.props
    const old = new Date()
    old.setMonth(old.getMonth() - 1)
    Object.keys(notes).map((note, index) => {
      if (new Date(note.Date) < old) {
        notes.splice(index, 1)
      }
    })
    if (notes) {
      notes[InvoiceNumber] = { Notes, Date: new Date() }
      this.props.updateNotes(notes)
    } else {
      notes = {}
      notes[InvoiceNumber] = { Notes, Date: new Date() }
      this.props.updateNotes(notes)
    }
  }

  formatDollars = num => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num)
  }

  handlePayment = payment => {
    const { Co, Data } = this.state
    if (payment) {
      if (Data.Data && Data.Data.PaymentDetail) {
        Data.Data.PaymentDetail.push(payment)
      }
      if (Data.Data && Data.Data.Payments) {
        // we need to remove an existing payment if any
        const newPaymentsArray = Data.Data.Payments.filter(
          pmt => pmt.PaymentID !== payment.PaymentID,
        )

        newPaymentsArray.push(
          !payment.ID
            ? {
                Co,
                PaymentID: payment.ID,
                PaymentDateTime: payment.PaymentDateTime,
                PaymentMethodType: payment.Data.PaymentMethod
                  ? payment.Data.PaymentMethod.PaymentMethodType
                  : payment.PaymentType,
                Nickname: payment.Data.PaymentMethod
                  ? payment.Data.PaymentMethod.Nickname
                  : '',
                CardLastFour: payment.Data.PaymentMethod
                  ? payment.Data.PaymentMethod.CardLastFour
                  : null,
                Amount: _.sumBy(
                  _.filter(
                    payment.Data.Allocations,
                    o =>
                      o.Co === Co &&
                      o.Invoice === (this.state.Invoice || o.Invoice) &&
                      o.InvoiceNumber === this.state.InvoiceNumber,
                  ),
                  'Amount',
                ),
                Check: payment.Check,
                Memo: payment.Memo,
                Data: payment.Data,
              }
            : payment,
        )

        Data.Data.Payments = newPaymentsArray
      }
      this.setState({
        ...this.state,
        PaymentsInvoice: payment.PaymentsInvoice,
        Data,
      })
    }
  }

  handleVoidPayment = payment => {
    const { User } = this.props
    const { Data } = this.state
    if (payment) {
      payment.VoidDate = payment.VoideDate || new Date()
      payment.VoidedBy = payment.VoidedBy || User.UserName
      if (Data.Data && Data.Data.Payments) {
        const index = _.findIndex(Data.Data.Payments, {
          PaymentID: payment.PaymentID,
        })
        // window["warn"]('Voided Payment: ', payment, index, Data);
        if (index > -1) {
          Data.Data.Payments.splice(index, 1, payment)
        }
      }
      if (Data.Data && Data.Data.PaymentDetail) {
        const index = _.findIndex(Data.Data.PaymentDetail, {
          PaymentID: payment.PaymentID,
        })
        // window["warn"]('Voided Payment: ', payment, index, Data);
        if (index > -1) {
          Data.Data.PaymentDetail.splice(index, 1, payment)
        }
      }
      this.setState({ ...this.state, Data })
    }
  }

  setDatepickerOpen = bool => {
    const { isDatepickerOpen } = this.state
    if (isDatepickerOpen !== bool) {
      this.setState({ isDatepickerOpen: bool })
    }
  }

  setDueDatepickerOpen = bool => {
    const { isDueDatepickerOpen } = this.state
    if (isDueDatepickerOpen !== bool) {
      this.setState({ isDueDatepickerOpen: bool })
    }
  }

  formatPhone = str => {
    return formatPhone(str).formatted
  }

  createPaymentsCustomer = () => {
    const {
      Co,
      Customer,
      FirstName,
      LastName,
      Name,
      BillName,
      BillPhone,
      BillEmail,
      BillAddress1,
      BillAddress2,
      BillCity,
      BillState,
      BillCountry,
      BillZip,
      PaymentsCustomer,
      total,
    } = this.state
    if (!PaymentsCustomer) {
      const customer = {
        Co,
        Customer,
        firstname: FirstName || '',
        lastname: LastName || '',
        company: BillName || Name || '',
        phone: BillPhone ? BillPhone.replace(/[^a-zA-Z0-9]/g, '') : '',
        email: BillEmail || '',
        address_1: BillAddress1,
        address_2: BillAddress2 || '',
        address_city: BillCity,
        address_state: BillState,
        address_zip: BillZip,
        reference: Customer.toString(),
      }
      const request = axios.post(
        `${window['apiLocation']}/api/Omni/CreateCustomer`,
        customer,
      )
      request.then(response => {
        if (response.data.id) {
          this.setState({
            ...this.state,
            PaymentsCustomer: response.data.id,
            isCreatingCustomerPayments: false,
          })
        } else if (response.data.address_state && response.data.email) {
          this.props.showMessage({
            message: `Stax Error: The email must be a valid email address and the address state must be 2 characters.`,
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'warning',
          })
          this.setState({ ...this.state, isCreatingCustomerPayments: false })
        } else if (response.data.address_state) {
          this.props.showMessage({
            message: `Stax Error: The address state must be 2 characters.`,
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'warning',
          })
          this.setState({ ...this.state, isCreatingCustomerPayments: false })
        } else if (response.data.email) {
          this.props.showMessage({
            message: `Stax Error: The email must be a valid email address.`,
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'warning',
          })
          this.setState({ ...this.state, isCreatingCustomerPayments: false })
        } else if (response === null) {
          this.setState({
            ...this.state,
            isCreatingCustomerPayments: false,
          })
        }
      })
    } else {
      this.setState({ ...this.state, isCreatingCustomerPayments: false })
    }
  }

  openEmail = (closeAfter, confirm) => {
    const inv = this.state.Data
    const checklists = []
    const {
      classes,
      companies,
      users,
      divisions,
      serviceUnitTypes,
      workOrderDialog,
    } = this.props
    const woData = workOrderDialog.data
    if (woData && woData.WorkOrder === this.state.WorkOrder) {
      const { Data } = woData
      const { Scopes, Site } = Data
      Scopes.map(scope => {
        if (
          _.filter(
            inv.Data.InvoiceDetails,
            o =>
              o.Scope === scope.Scope ||
              (o.Data.Usage && o.Data.Usage.Scope === scope.Scope),
          ).length > 0
        ) {
          if (scope.Data && scope.Data.ServiceUnits) {
            scope.Data.ServiceUnits.map(su => {
              if (su.Data && su.Data.ChecklistResults) {
                su.Data.ChecklistResults.map(checklist => {
                  checklist.info =
                    scope &&
                    [scope].map(scope => {
                      const { Co, BusinessUnit, Division } = scope || {}
                      const division = _.find(divisions, {
                        Co,
                        BusinessUnit,
                        Division,
                      })
                      const technician = _.find(users, {
                        UserName: checklist.EnteredBy,
                      })
                      const unit = _.find(Site.Data.Units, {
                        UnitID: su.UnitID,
                      })
                      return (
                        <div className='w-full' key={scope.ID}>
                          <div className='w-full flex justify-between my-24'>
                            {division && division.InvoiceHeaderImg && (
                              <img
                                className='h-48 sm:h-96'
                                src={division.InvoiceHeaderImg}
                              />
                            )}
                            <span className='sm:mt-12'>
                              <label
                                className={classNames(
                                  classes.invHeader,
                                  'sm:pr-8 text-20 sm:text-36',
                                )}
                              >
                                CHECKLIST
                              </label>
                              <div
                                className={classNames(
                                  classes.invSubHeader,
                                  'sm:pr-8 text-10 sm:text-16',
                                )}
                              >
                                Work Order #{this.state.WorkOrder}-{scope.Scope}
                              </div>
                            </span>
                          </div>

                          <div className='flex mb-24 justify-between p-16 pr-4'>
                            <div className='cursor-pointer'>
                              <div className='w-full text-16 font-bold text-left'>
                                {Data.Customer.Name &&
                                Data.Customer.Name.length > 0
                                  ? Data.Customer.Name
                                  : `${Data.Customer.FirstName} ${Data.Customer.LastName}`}
                              </div>
                              <div className='w-full text-12 text-left'>
                                {Data.Customer.Address1}
                              </div>
                              {Data.Customer.Address2 && (
                                <div className='w-full text-12 text-left'>
                                  {Data.Customer.Address2}
                                </div>
                              )}
                              <div className='w-full text-12 text-left'>
                                {`${Data.Customer.City}, ${Data.Customer.State} ${Data.Customer.Zip}`}
                              </div>
                            </div>

                            <div>
                              <div
                                className='flex cursor-pointer'
                                onClick={this.openDatePicker}
                              >
                                <img
                                  src='assets/icons/calendar_icon.png'
                                  className='w-32 h-32 mr-8'
                                />
                                <div className='w-full'>
                                  <div className='text-14 font-bold'>
                                    {checklist.ResultID
                                      ? moment(checklist.EnteredDate).format(
                                          'dddd',
                                        )
                                      : moment(Date()).format('dddd')}
                                  </div>
                                  <div className='text-10'>
                                    {checklist.ResultID
                                      ? moment(checklist.EnteredDate).format(
                                          'MMM DD YYYY',
                                        )
                                      : moment(Date()).format('MMM DD YYYY')}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='flex mt-24'>
                            <div className='w-full'>
                              <div
                                className='w-full flex justify-between rounded-full mb-12'
                                style={{ backgroundColor: '#3f3f3f' }}
                              >
                                <div
                                  className={classNames(
                                    'w-auto rounded-full py-6 px-32 text-14 font-bold truncate cursor-pointer',
                                    classes.dividerHighlight,
                                  )}
                                >
                                  {checklist.Name}
                                </div>
                                <div
                                  className={classNames(
                                    'w-2/3 py-8 px-24 text-12 font-bold text-right truncate',
                                    classes.dividerPlain,
                                  )}
                                >
                                  {`${Data.Site.Address1} ${Data.Site.City}, ${Data.Site.State}, ${Data.Site.Zip}`}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='w-full flex justify-between'>
                            <div className='text-12 text-left pl-16'>
                              {/* <div className="w-full">
                                                        <strong className="mr-8 font-bold">Task:</strong>
                                                        <label>{task.Data.Task.Name}</label>
                                                    </div> */}
                              {unit.UnitType &&
                                _.find(serviceUnitTypes, {
                                  UnitType: unit.UnitType,
                                }) && (
                                  <div className='w-full'>
                                    <strong className='mr-8 font-bold'>
                                      Unit Type:
                                    </strong>
                                    <label>
                                      {
                                        _.find(serviceUnitTypes, {
                                          UnitType: unit.UnitType,
                                        }).Name
                                      }
                                    </label>
                                  </div>
                                )}
                              <div className='w-full'>
                                <strong className='mr-8 font-bold'>
                                  Unit ID:
                                </strong>
                                <label>{`${unit.UnitID} - ${unit.Name}`}</label>
                              </div>
                              {unit.Manufacturer && (
                                <div className='w-full'>
                                  <strong className='mr-8 font-bold'>
                                    Manufacturer:
                                  </strong>
                                  <label>{unit.Manufacturer}</label>
                                </div>
                              )}
                              {unit.Model && (
                                <div className='w-full'>
                                  <strong className='mr-8 font-bold'>
                                    Model:
                                  </strong>
                                  <label>{unit.Model}</label>
                                </div>
                              )}
                              {unit.SerialNumber && (
                                <div className='w-full'>
                                  <strong className='mr-8 font-bold'>
                                    Serial #:
                                  </strong>
                                  <label>{unit.SerialNumber}</label>
                                </div>
                              )}
                            </div>
                            {technician && (
                              <div
                                className='flex mr-24 mt-4'
                                style={{ marginBottom: -28 }}
                              >
                                <img
                                  src='assets/icons/person_icon.png'
                                  className='w-24 h-24 mr-4'
                                />
                                <div className='w-full'>
                                  <div className='text-12 font-bold mt-4'>
                                    {`${technician.FirstName} ${technician.LastName}`}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    })
                  checklist.footerInfo =
                    scope &&
                    [scope].map(scope => {
                      const { Co } = scope || {}
                      const co = (_.find(companies, { Co }) || { Data: {} })
                        .Data.Co
                      if (co) {
                        return (
                          <div className='mx-24' key={scope.ID}>
                            <div className='w-full sm:flex sm:justify-between'>
                              <div className='mt-24'>
                                <div className='w-full flex'>
                                  <img
                                    src='assets/icons/location_icon.png'
                                    className='w-32 h-32 mr-8'
                                  />
                                  <div className='w-full'>
                                    <div className='text-14 font-bold'>
                                      {co.Name}
                                    </div>
                                    <div className='text-10'>{co.Address}</div>
                                    <div className='text-10'>
                                      {`${co.City}, ${co.State} ${co.Zip}`}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='mt-24'>
                                <div className='w-full flex'>
                                  <img
                                    src='assets/icons/email_icon.png'
                                    className='w-32 h-32 mr-8'
                                  />
                                  <div className='w-full'>
                                    <div className='text-14 font-bold'>
                                      Email
                                    </div>
                                    <div className='text-10'>{co.Email}</div>
                                  </div>
                                </div>
                              </div>
                              <div className='mt-24'>
                                <div className='w-full flex'>
                                  <img
                                    src='assets/icons/phone_icon.png'
                                    className='w-32 h-32 mr-8'
                                  />
                                  <div className='w-full'>
                                    <div className='text-14 font-bold'>
                                      Phone
                                    </div>
                                    <div className='text-10'>
                                      {this.formatPhone(co.Phone)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    })
                  checklists.push(checklist)
                })
              }
            })
          }
          if (scope.Data && scope.Data.ChecklistResults) {
            scope.Data.ChecklistResults.map(checklist => {
              const technician = _.find(users, {
                UserName: checklist.EnteredBy,
              })
              checklist.info =
                scope &&
                [scope].map(scope => {
                  const { Co, BusinessUnit, Division } = scope || {}
                  const division = _.find(divisions, {
                    Co,
                    BusinessUnit,
                    Division,
                  })
                  return (
                    <div className='w-full' key={scope.ID}>
                      <div className='w-full flex justify-between my-24'>
                        {division && division.InvoiceHeaderImg && (
                          <img
                            className='h-48 sm:h-96'
                            src={division.InvoiceHeaderImg}
                          />
                        )}
                        <span className='sm:mt-12'>
                          <label
                            className={classNames(
                              classes.invHeader,
                              'sm:pr-8 text-20 sm:text-36',
                            )}
                          >
                            CHECKLIST
                          </label>
                          <div
                            className={classNames(
                              classes.invSubHeader,
                              'sm:pr-8 text-10 sm:text-16',
                            )}
                          >
                            Work Order #{this.state.WorkOrder}-{scope.Scope}
                          </div>
                        </span>
                      </div>

                      <div className='flex mb-24 justify-between p-16 pr-4'>
                        <div className='cursor-pointer'>
                          <div className='w-full text-16 font-bold text-left'>
                            {Data.Customer.Name && Data.Customer.Name.length > 0
                              ? Data.Customer.Name
                              : `${Data.Customer.FirstName} ${Data.Customer.LastName}`}
                          </div>
                          <div className='w-full text-12 text-left'>
                            {Data.Customer.Address1}
                          </div>
                          {Data.Customer.Address2 && (
                            <div className='w-full text-12 text-left'>
                              {Data.Customer.Address2}
                            </div>
                          )}
                          <div className='w-full text-12 text-left'>
                            {`${Data.Customer.City}, ${Data.Customer.State} ${Data.Customer.Zip}`}
                          </div>
                        </div>

                        <div>
                          <div
                            className='flex cursor-pointer'
                            onClick={this.openDatePicker}
                          >
                            <img
                              src='assets/icons/calendar_icon.png'
                              className='w-32 h-32 mr-8'
                            />
                            <div className='w-full'>
                              <div className='text-14 font-bold'>
                                {checklist.ResultID
                                  ? moment(checklist.EnteredDate).format('dddd')
                                  : moment(Date()).format('dddd')}
                              </div>
                              <div className='text-10'>
                                {checklist.ResultID
                                  ? moment(checklist.EnteredDate).format(
                                      'MMM DD YYYY',
                                    )
                                  : moment(Date()).format('MMM DD YYYY')}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='flex mt-24'>
                        <div className='w-full'>
                          <div
                            className='w-full flex justify-between rounded-full mb-12'
                            style={{ backgroundColor: '#3f3f3f' }}
                          >
                            <div
                              className={classNames(
                                'w-auto rounded-full py-6 px-32 text-14 font-bold truncate cursor-pointer',
                                classes.dividerHighlight,
                              )}
                            >
                              {checklist.Name}
                            </div>
                            <div
                              className={classNames(
                                'w-2/3 py-8 px-24 text-12 font-bold text-right truncate',
                                classes.dividerPlain,
                              )}
                            >
                              {`${Data.Site.Address1} ${Data.Site.City}, ${Data.Site.State}, ${Data.Site.Zip}`}
                            </div>
                          </div>
                        </div>
                      </div>
                      {technician && (
                        <div className='w-full flex justify-end'>
                          <div
                            className='flex mr-24 mt-4'
                            style={{ marginBottom: -28 }}
                          >
                            <img
                              src='assets/icons/person_icon.png'
                              className='w-24 h-24 mr-4'
                            />
                            <div className='w-full'>
                              <div className='text-12 font-bold mt-4'>
                                {`${technician.FirstName} ${technician.LastName}`}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })
              checklist.footerInfo =
                scope &&
                [scope].map(scope => {
                  const { Co } = scope || {}
                  const co = (_.find(companies, { Co }) || { Data: {} }).Data.Co
                  if (co) {
                    return (
                      <div className='mx-24' key={scope.ID}>
                        <div className='w-full sm:flex sm:justify-between'>
                          <div className='mt-24'>
                            <div className='w-full flex'>
                              <img
                                src='assets/icons/location_icon.png'
                                className='w-32 h-32 mr-8'
                              />
                              <div className='w-full'>
                                <div className='text-14 font-bold'>
                                  {co.Name}
                                </div>
                                <div className='text-10'>{co.Address}</div>
                                <div className='text-10'>
                                  {`${co.City}, ${co.State} ${co.Zip}`}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='mt-24'>
                            <div className='w-full flex'>
                              <img
                                src='assets/icons/email_icon.png'
                                className='w-32 h-32 mr-8'
                              />
                              <div className='w-full'>
                                <div className='text-14 font-bold'>Email</div>
                                <div className='text-10'>{co.Email}</div>
                              </div>
                            </div>
                          </div>
                          <div className='mt-24'>
                            <div className='w-full flex'>
                              <img
                                src='assets/icons/phone_icon.png'
                                className='w-32 h-32 mr-8'
                              />
                              <div className='w-full'>
                                <div className='text-14 font-bold'>Phone</div>
                                <div className='text-10'>
                                  {this.formatPhone(co.Phone)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                })
              checklists.push(checklist)
            })
          }
          if (scope.Data && scope.Data.AgreementTasks) {
            scope.Data.AgreementTasks.map(task => {
              if (task.Data.Task.Data.Units) {
                task.Data.Task.Data.Units.map(unit => {
                  unit.Data.ChecklistResults.map(checklist => {
                    checklist.info =
                      scope &&
                      [scope].map(scope => {
                        const { Co, BusinessUnit, Division } = scope || {}
                        const division = _.find(divisions, {
                          Co,
                          BusinessUnit,
                          Division,
                        })
                        const technician = _.find(users, {
                          UserName: checklist.EnteredBy,
                        })
                        return (
                          <div className='w-full' key={scope.ID}>
                            <div className='w-full flex justify-between my-24'>
                              {division && division.InvoiceHeaderImg && (
                                <img
                                  className='h-48 sm:h-96'
                                  src={division.InvoiceHeaderImg}
                                />
                              )}
                              <span className='sm:mt-12'>
                                <label
                                  className={classNames(
                                    classes.invHeader,
                                    'sm:pr-8 text-20 sm:text-36',
                                  )}
                                >
                                  CHECKLIST
                                </label>
                                <div
                                  className={classNames(
                                    classes.invSubHeader,
                                    'sm:pr-8 text-10 sm:text-16',
                                  )}
                                >
                                  Work Order #{this.state.WorkOrder}-
                                  {scope.Scope}
                                </div>
                              </span>
                            </div>

                            <div className='flex mb-24 justify-between p-16 pr-4'>
                              <div className='cursor-pointer'>
                                <div className='w-full text-16 font-bold text-left'>
                                  {Data.Customer.Name &&
                                  Data.Customer.Name.length > 0
                                    ? Data.Customer.Name
                                    : `${Data.Customer.FirstName} ${Data.Customer.LastName}`}
                                </div>
                                <div className='w-full text-12 text-left'>
                                  {Data.Customer.Address1}
                                </div>
                                {Data.Customer.Address2 && (
                                  <div className='w-full text-12 text-left'>
                                    {Data.Customer.Address2}
                                  </div>
                                )}
                                <div className='w-full text-12 text-left'>
                                  {`${Data.Customer.City}, ${Data.Customer.State} ${Data.Customer.Zip}`}
                                </div>
                              </div>

                              <div>
                                <div
                                  className='flex cursor-pointer'
                                  onClick={this.openDatePicker}
                                >
                                  <img
                                    src='assets/icons/calendar_icon.png'
                                    className='w-32 h-32 mr-8'
                                  />
                                  <div className='w-full'>
                                    <div className='text-14 font-bold'>
                                      {checklist.ResultID
                                        ? moment(checklist.EnteredDate).format(
                                            'dddd',
                                          )
                                        : moment(Date()).format('dddd')}
                                    </div>
                                    <div className='text-10'>
                                      {checklist.ResultID
                                        ? moment(checklist.EnteredDate).format(
                                            'MMM DD YYYY',
                                          )
                                        : moment(Date()).format('MMM DD YYYY')}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='flex mt-24'>
                              <div className='w-full'>
                                <div
                                  className='w-full flex justify-between rounded-full mb-12'
                                  style={{ backgroundColor: '#3f3f3f' }}
                                >
                                  <div
                                    className={classNames(
                                      'w-auto rounded-full py-6 px-32 text-14 font-bold truncate cursor-pointer',
                                      classes.dividerHighlight,
                                    )}
                                  >
                                    {checklist.Name}
                                  </div>
                                  <div
                                    className={classNames(
                                      'w-2/3 py-8 px-24 text-12 font-bold text-right truncate',
                                      classes.dividerPlain,
                                    )}
                                  >
                                    {`${Data.Site.Address1} ${Data.Site.City}, ${Data.Site.State}, ${Data.Site.Zip}`}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='w-full flex justify-between'>
                              <div className='text-12 text-left pl-16'>
                                <div className='w-full'>
                                  <strong className='mr-8 font-bold'>
                                    Task:
                                  </strong>
                                  <label>{task.Data.Task.Name}</label>
                                </div>
                                {unit.UnitType &&
                                  _.find(serviceUnitTypes, {
                                    UnitType: unit.UnitType,
                                  }) && (
                                    <div className='w-full'>
                                      <strong className='mr-8 font-bold'>
                                        Unit Type:
                                      </strong>
                                      <label>
                                        {
                                          _.find(serviceUnitTypes, {
                                            UnitType: unit.UnitType,
                                          }).Name
                                        }
                                      </label>
                                    </div>
                                  )}
                                <div className='w-full'>
                                  <strong className='mr-8 font-bold'>
                                    Unit ID:
                                  </strong>
                                  <label>{`${unit.UnitID} - ${unit.Name}`}</label>
                                </div>
                                {unit.Manufacturer && (
                                  <div className='w-full'>
                                    <strong className='mr-8 font-bold'>
                                      Manufacturer:
                                    </strong>
                                    <label>{unit.Manufacturer}</label>
                                  </div>
                                )}
                                {unit.Model && (
                                  <div className='w-full'>
                                    <strong className='mr-8 font-bold'>
                                      Model:
                                    </strong>
                                    <label>{unit.Model}</label>
                                  </div>
                                )}
                                {unit.SerialNumber && (
                                  <div className='w-full'>
                                    <strong className='mr-8 font-bold'>
                                      Serial #:
                                    </strong>
                                    <label>{unit.SerialNumber}</label>
                                  </div>
                                )}
                              </div>
                              {technician && (
                                <div
                                  className='flex mr-24 mt-4'
                                  style={{ marginBottom: -28 }}
                                >
                                  <img
                                    src='assets/icons/person_icon.png'
                                    className='w-24 h-24 mr-4'
                                  />
                                  <div className='w-full'>
                                    <div className='text-12 font-bold mt-4'>
                                      {`${technician.FirstName} ${technician.LastName}`}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      })
                    checklist.footerInfo =
                      scope &&
                      [scope].map(scope => {
                        const { Co } = scope || {}
                        const co = (_.find(companies, { Co }) || { Data: {} })
                          .Data.Co
                        if (co) {
                          return (
                            <div className='mx-24' key={scope.ID}>
                              <div className='w-full sm:flex sm:justify-between'>
                                <div className='mt-24'>
                                  <div className='w-full flex'>
                                    <img
                                      src='assets/icons/location_icon.png'
                                      className='w-32 h-32 mr-8'
                                    />
                                    <div className='w-full'>
                                      <div className='text-14 font-bold'>
                                        {co.Name}
                                      </div>
                                      <div className='text-10'>
                                        {co.Address}
                                      </div>
                                      <div className='text-10'>
                                        {`${co.City}, ${co.State} ${co.Zip}`}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='mt-24'>
                                  <div className='w-full flex'>
                                    <img
                                      src='assets/icons/email_icon.png'
                                      className='w-32 h-32 mr-8'
                                    />
                                    <div className='w-full'>
                                      <div className='text-14 font-bold'>
                                        Email
                                      </div>
                                      <div className='text-10'>{co.Email}</div>
                                    </div>
                                  </div>
                                </div>
                                <div className='mt-24'>
                                  <div className='w-full flex'>
                                    <img
                                      src='assets/icons/phone_icon.png'
                                      className='w-32 h-32 mr-8'
                                    />
                                    <div className='w-full'>
                                      <div className='text-14 font-bold'>
                                        Phone
                                      </div>
                                      <div className='text-10'>
                                        {this.formatPhone(co.Phone)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      })
                    checklists.push(checklist)
                  })
                })
              }
            })
          }
        }
      })
    }
    if (checklists.length > 0) {
      var checklistResults = (
        <ChecklistGenerator
          readOnly={true}
          checklists={checklists}
          onGenerate={checklistsPDF =>
            this.setState({ checklistResults: '', checklistsPDF }, () =>
              this.emailInvoice(closeAfter, confirm),
            )
          }
          fileName='Inspection Reports'
        />
      )
      // window["warn"]('ChecklistData: ', checklists, checklistResults);
      this.setState({ checklistResults })
    } else {
      this.setState({ checklistResults: '', checklistsPDF: null }, () =>
        this.emailInvoice(closeAfter, confirm),
      )
    }
  }

  printPDF = () => {
    const { Co, InvoiceNumber } = this.state
    const { businessUnits, divisions, templates, companies } = this.props
    const { Scopes } = this.state.Data.Data
    const { InvoiceDetails, Contacts } = this.state.Data.Data
    const { BusinessUnit, Division } =
      Scopes && Scopes.length > 0
        ? Scopes[0]
        : InvoiceDetails &&
            InvoiceDetails.length > 0 &&
            InvoiceDetails[0].Data &&
            InvoiceDetails[0].Data.Agreement
          ? InvoiceDetails[0].Data.Agreement
          : null
    let margin = {
      top: '.16 in',
      bottom: '.16 in',
      left: '.16 in',
      right: '.16 in',
    }
    if (BusinessUnit && Division && businessUnits && templates) {
      const div = _.find(divisions, { Co, BusinessUnit, Division })
      const {
        InvoiceMarginTop,
        InvoiceMarginBottom,
        InvoiceMarginLeft,
        InvoiceMarginRight,
      } = div
      margin = {
        top: `${((InvoiceMarginTop / 330) * 11).toFixed(2)} in`,
        bottom: `${((InvoiceMarginBottom / 330) * 11).toFixed(2)} in`,
        left: `${((InvoiceMarginLeft / 255) * 8.5).toFixed(2)} in`,
        right: `${((InvoiceMarginRight / 255) * 8.5).toFixed(2)} in`,
      }
    }
    let gridElement = document.getElementById('invoice-print')
    drawDOM(gridElement, {
      keepTogether: '.prevent-split',
      paperSize: 'letter',
      margin,
      scale: 0.65,
    })
      .then(group => {
        return exportPDF(group)
      })
      .then(dataUri => {
        this.setState({ printPDF: dataUri, anchorEl: null })
      })
  }

  AppBarContents = (matches, minimizeFxn, closeFxn) => {
    const {
      theme,
      classes,
      invoiceDialog,
      addInvoice,
      securables,
      users,
      updateInvoice,
      updateInvoiceDialog,
      taxCodes,
      removeInvoice,
      formOfPayments,
      materials,
      laborTypes,
      openEditWorkOrderUsageDialog,
      openEditWorkOrderDialog,
      openEditCustomerSiteDialog,
      openEditCustomerDialog,
      Co,
      companies,
      technicians,
      divisions,
      addAgreementInvoice,
    } = this.props
    const { trip, readOnly } = invoiceDialog.props
    const inv = this.state.Data
    const { Contacts } = inv.Data
    var contact = _.find(Contacts, o => {
      return o.Data.Contact.Name === inv.BillContact
    })
    const { invoiceEmail, showWarrantyDetail, editLine } = this.state
    const assignments = inv.Data.Assignments
    const billableDetails = inv.ID
      ? inv.Data.InvoiceDetails
      : _.filter(
          inv.Data.InvoiceDetails,
          l =>
            (l.Data.Usage && l.Data.Usage.NonBillableYN !== 'Y') ||
            (l.Data.Scope && l.Data.Scope.NonBillableYN !== 'Y'),
        )
    const lines = _.flatten(
      _.map(billableDetails, _.property('Data.InvoiceLines')),
    )
    let sub = _.sumBy(lines, o => {
      return Number(o.Amount)
    })
    let tax = _.sumBy(lines, o => {
      return Number(o.TaxAmount)
    })
    const co = _.find(companies, { Co }).Data.Co
    let total = sub + tax
    let payments =
      inv.Data && inv.Data.Payments
        ? _.sumBy(inv.Data.Payments, pmt =>
            _.sumBy(
              _.filter(
                pmt.Data.Allocations || pmt.Data.Payment.Data.Allocations,
                o =>
                  !o.VoidDate &&
                  o.Co === inv.Co &&
                  o.Invoice === (inv.Invoice || o.Invoice) &&
                  o.InvoiceNumber === inv.InvoiceNumber,
              ),
              'Amount',
            ),
          )
        : 0
    let due = total - payments
    // window["warn"]('Invoice Data: ', this.state, inv, lines, payments, total, due);
    const User = !this.state.EnteredBy
      ? this.props.User
      : _.findLast(this.props.Users, n => {
          return n.UserName === this.state.EnteredBy
        })
    const accessLevel = _.find(securables, { Securable: 'invoices' })
    const userAvatar = '../../assets/images/avatars/profile.jpg'
    const batched = _.sumBy(billableDetails || {}, d => {
      return _.sumBy(d.Data.InvoiceLines || {}, o => {
        return o.Data && o.Data.VPARBL ? o.Data.VPARBL.Amount : 0
      })
    })
    const posted = _.sumBy(billableDetails || {}, d => {
      return _.sumBy(d.Data.InvoiceLines || {}, o => {
        return o.Data && o.Data.VPARTL ? o.Data.VPARTL.Amount : 0
      })
    })
    if (trip) {
      const start = moment(trip.InProgressTime || trip.EnRouteTime)
      const end = moment(trip.CompletedTime)
      const sec = end.diff(start, 'seconds')
      trip.ActualDuration = sec / 60 / 60
    }
    const paymentTypes = {
      visa: 'Visa',
      mastercard: 'MasterCard',
      master: 'MasterCard',
      discover: 'Discover',
      american_express: 'American Express',
      amex: 'American Express',
      am_ex: 'American Express',
    }
    const scope =
      inv.Data && inv.Data.Scopes && inv.Data.Scopes.length > 0
        ? inv.Data.Scopes[0]
        : null
    const billCust = inv.Data.Customer
      ? inv.Data.Customer
      : scope
        ? scope.Data.Customer || {}
        : {}
    const agreement =
      inv.Data &&
      billableDetails &&
      billableDetails.length > 0 &&
      billableDetails[0].Data &&
      billableDetails[0].Data.Agreement
        ? billableDetails[0].Data.Agreement
        : null
    const division = scope
      ? _.find(divisions, {
          Co,
          BusinessUnit: scope.BusinessUnit,
          Division: scope.Division,
        })
      : agreement
        ? _.find(divisions, {
            Co,
            BusinessUnit: agreement.BusinessUnit,
            Division: agreement.Division,
          })
        : null
    let pmts = [...(inv.Data && inv.Data.Payments ? inv.Data.Payments : [])]

    return (
      <>
        {Boolean(inv.ID) && (
          <IconButton
            style={{
              position: 'absolute',
              right: 144,
              top: !matches.small ? 8 : 4,
              color: 'white',
              //display: matches ? 'block' : 'none'
            }}
            className='dialog-header-icon'
            onClick={minimizeFxn}
          >
            <Icon>minimize</Icon>
          </IconButton>
        )}
        {inv.ID && (
          <IconButton
            style={{
              position: 'absolute',
              right: 104,
              top: matches ? 8 : 4,
              color: 'white',
            }}
            onClick={e => {
              this.setState({ anchorEl: e.target })
            }}
            className='dialog-header-icon'
          >
            <Icon>more_vert</Icon>
          </IconButton>
        )}
        {this.state.scopesToRender.length >= 1 &&
          this.props.invoiceDialog.type === 'new' && (
            <IconButton
              style={{
                height: 48,
                width: 48,
                position: 'absolute',
                right: 60,
                top: matches ? 8 : 4,
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
              }}
              onClick={() => this.setState({ shouldRenderDiv: true })}
            >
              <Icon style={{ color: 'white', fontSize: 25 }}>info</Icon>
            </IconButton>
          )}
        <Menu
          id='invoice-menu'
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={() => this.setState({ anchorEl: null })}
        >
          {Boolean(inv.ID) && (
            <MenuItem
              onClick={() =>
                this.setState({ refreshing: true }, () => {
                  Promise.all([
                    this.props.refreshEditInvoiceDialog(this.state),
                  ]).then(() => {
                    this.setState({ refreshing: false, anchorEl: null })
                  })
                })
              }
            >
              <Icon
                className={classNames('mr-8', this.state.refreshing && 'spin')}
              >
                refresh
              </Icon>
              Refresh Invoice
            </MenuItem>
          )}
          <MenuItem
            onClick={() =>
              this.setState({ showEmailLogs: true, anchorEl: null })
            }
          >
            <Icon className='mr-8'>send</Icon>View Email Log
          </MenuItem>
          {!readOnly && (
            <MenuItem
              onClick={() => {
                this.setState({ anchorEl: null, viewChanges: true })
              }}
            >
              <Icon className='mr-8'>history</Icon>View Changes
            </MenuItem>
          )}
          {pmts.some(
            pmt =>
              Boolean(pmt.VoidDate) ||
              (pmt.Data &&
                pmt.Data.Payment &&
                Boolean(pmt.Data.Payment.VoidDate)),
          ) && (
            <MenuItem
              onClick={() =>
                this.setState({
                  hideVoidedPayments: !this.state.hideVoidedPayments,
                  anchorEl: null,
                })
              }
            >
              <Icon style={{ marginRight: 8 }}>
                {this.state.hideVoidedPayments
                  ? 'visibility_off'
                  : 'visibility_on'}
              </Icon>
              {`${
                this.state.hideVoidedPayments ? 'Show' : 'Hide'
              } Voided Payments`}
            </MenuItem>
          )}
          <MenuItem onClick={this.printPDF}>
            <Icon className='mr-8'>print</Icon>View PDF
          </MenuItem>
        </Menu>

        {!readOnly && (
          <ChangeDialog
            type='INVOICE'
            rec={this.state.ID}
            restore={data => this.setState({ ...data, viewChanges: false })}
            accessLevel={accessLevel}
            data={this.state}
            open={this.state.viewChanges}
            onClose={() => this.setState({ viewChanges: false })}
          />
        )}

        {
          // PDF Dialog
          this.state.printPDF && (
            <Dialog
              classes={{
                paper: classNames(
                  'mt-0 mb-0 w-full h-full',
                  !matches && 'full-screen-dialog',
                ),
              }}
              open={this.state.printPDF}
              onClose={() => this.setState({ printPDF: null, anchorEl: null })}
              maxWidth='sm'
              fullScreen={!matches}
            >
              <AppBar position='static' className='dialog-header'>
                <Toolbar className='flex w-full'>
                  <Typography variant='subtitle1' color='inherit'>
                    <Icon className='mr-6 align-middle mb-2'>print</Icon>
                    {inv.InvoiceNumber}.pdf
                  </Typography>
                </Toolbar>
                <IconButton
                  style={{
                    position: 'absolute',
                    right: 10,
                    top: matches.medium ? 8 : 4,
                    color: 'white',
                  }}
                  onClick={() => {
                    this.setState({ printPDF: null, anchorEl: null })
                  }}
                  className='dialog-header-icon'
                >
                  <Icon>close</Icon>
                </IconButton>
              </AppBar>
              <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>
                <iframe
                  src={this.state.printPDF}
                  className='w-full overflow-auto'
                  style={{ height: 'calc(100% - 24px)' }}
                />
              </DialogContent>
            </Dialog>
          )
        }

        {!readOnly && editLine && editLine.line && editLine.detail && (
          <Dialog
            classes={{
              paper: classNames(
                'mt-0 mb-0 w-full',
                !matches && 'full-screen-dialog',
              ),
            }}
            open={Boolean(editLine)}
            onClose={() => this.setState({ editLine: null })}
            maxWidth='xs'
            fullScreen={!matches}
            TransitionComponent={!matches ? SlideUp : Grow}
          >
            <AppBar position='static' className='dialog-header'>
              <Toolbar className='flex w-full'>
                <Typography variant='subtitle1' color='inherit'>
                  <Icon className='mr-6 align-middle mb-2'>edit</Icon>Edit
                  Invoice Detail
                </Typography>
              </Toolbar>
              <IconButton
                style={{
                  position: 'absolute',
                  right: 10,
                  top: matches.medium ? 8 : 4,
                  color: 'white',
                }}
                onClick={() => {
                  this.setState({ editLine: null })
                }}
                className='dialog-header-icon'
              >
                <Icon>close</Icon>
              </IconButton>
            </AppBar>
            <DialogContent classes={{ root: 'min-h-384 pt-24' }}>
              <TextField
                className='mb-24'
                label='Description'
                id='description'
                name='editLine.line.Description'
                value={editLine.line.Description || ''}
                onChange={this.handleChange}
                variant='outlined'
                fullWidth
                required
              />
              <TextField
                className='mb-24'
                label='Price'
                id='amount'
                name='editLine.line.Price'
                value={editLine.line.Price || ''}
                onChange={e => {
                  const taxCode = _.find(taxCodes, {
                    TaxCode: editLine.line.TaxCode,
                  })
                  const Price = e.target.value
                  this.setState({
                    editLine: {
                      ...editLine,
                      line: {
                        ...editLine.line,
                        Price,
                        TaxAmount:
                          new Date() >=
                          new Date(taxCode.EffectiveDate || new Date())
                            ? Price * (taxCode.NewRate / 100)
                            : Price * (taxCode.OldRate / 100),
                      },
                    },
                  })
                }}
                InputProps={{
                  startAdornment: Boolean(editLine.line.Price) && (
                    <InputAdornment position='start'>$</InputAdornment>
                  ),
                  type: 'number',
                }}
                variant='outlined'
                fullWidth
                required
                disabled={
                  Boolean(editLine.scope.Data.Estimate) &&
                  editLine.detail.Scope === scope.Scope
                }
                helperText={
                  Boolean(editLine.scope.Data.Estimate) &&
                  editLine.detail.Scope === scope.Scope ? (
                    <div
                      className='text-grey text-11 text-center w-full'
                      style={{ marginBottom: -16 }}
                    >
                      *Scope Price linked to Estimate #
                      {editLine.scope.Data.Estimate.EstimateNumber}*
                    </div>
                  ) : undefined
                }
              />
              <Autocomplete
                className='mb-24'
                title='Tax Code'
                options={taxCodes}
                menuItemComponent={value => {
                  return (
                    value.ActiveYN === 'Y' && (
                      <MenuItem value={value.TaxCode}>
                        {`${value.Description} - ${value.TaxCode}`}
                      </MenuItem>
                    )
                  )
                }}
                portal={true}
                value={editLine.line.TaxCode || ''}
                onSelect={option =>
                  this.setState({
                    editLine: {
                      ...editLine,
                      line: {
                        ...editLine.line,
                        TaxCode: option.TaxCode,
                        TaxAmount:
                          new Date() >= new Date(option.EffectiveDate)
                            ? editLine.line.Price * (option.NewRate / 100)
                            : editLine.line.Price * (option.OldRate / 100),
                      },
                    },
                  })
                }
                required
              />
              <TextField
                className='mb-24'
                label='Tax Amount'
                id='taxAmount'
                name='editLine.line.TaxAmount'
                value={editLine.line.TaxAmount || ''}
                onChange={this.handleChange}
                InputProps={{
                  startAdornment: Boolean(editLine.line.TaxAmount) && (
                    <InputAdornment position='start'>$</InputAdornment>
                  ),
                  type: 'number',
                }}
                variant='outlined'
                fullWidth
              />
            </DialogContent>
            <DialogActions className='dialog-actions justify-between pl-16'>
              <DebounceButton
                buttonText={'Save'}
                processingText={'Saving'}
                isDisabled={this.state.editingLine}
                variant={'contained'}
                color={'primary'}
                clickFxn={() => {
                  this.setState({ editingLine: true }, () =>
                    Promise.all([
                      this.props.updateInvoiceDetail(this.state, {
                        ...editLine.detail,
                        ...editLine.line,
                      }),
                    ]).then(() => {
                      this.setState({ editLine: null, editingLine: false })
                    }),
                  )
                }}
                debounceInterval={1000}
              />
              <IconButton
                onClick={() => {
                  this.setState({ editingLine: true }, () =>
                    Promise.all([
                      this.props.removeInvoiceDetail(this.state, {
                        ...editLine.detail,
                        ...editLine.line,
                      }),
                    ]).then(() => {
                      this.setState({ editLine: null, editingLine: false })
                    }),
                  )
                }}
                disabled={this.state.editingLine}
              >
                <Icon>delete</Icon>
              </IconButton>
            </DialogActions>
          </Dialog>
        )}
        {inv.ID && (
          <Tooltip title='Email Invoice'>
            <IconButton
              style={{
                position: 'absolute',
                right: 56,
                top: matches ? 8 : 4,
                color: 'white',
              }}
              onClick={() => {
                this.openEmail()
              }}
              className='dialog-header-icon'
            >
              <Icon>email</Icon>
            </IconButton>
          </Tooltip>
        )}
        <IconButton
          style={{
            position: 'absolute',
            right: 10,
            top: matches ? 8 : 4,
            color: 'white',
          }}
          onClick={e => {
            if (
              payments &&
              payments > 0 &&
              !this.state.ID &&
              !this.state.OfflineID
            ) {
              this.setState({ promptSubmit: true })
            } else {
              closeFxn(e)
            }
          }}
          className='dialog-header-icon'
        >
          <Icon>close</Icon>
        </IconButton>
        <ConfirmationDialog
          open={this.state.promptSubmit}
          title={
            <div>
              <Icon className='mr-6 align-middle mb-4'>receipt</Icon>Invoice
              Incomplete
            </div>
          }
          content={
            <div className='w-full pt-8 pb-8'>{`This Invoice has ${this.formatDollars(
              payments,
            )} in payments applied but has not yet been completed - Please select "Finish" to complete this Invoice.`}</div>
          }
          confirmText='Finish'
          cancelText='Cancel'
          onConfirm={() =>
            this.setState({ ...this.state, promptSubmit: false }, () => {
              if (agreement) {
                addAgreementInvoice({
                  ...this.state,
                  Data: {
                    ...this.state.Data,
                    PaymentDetail: inv.Data.PaymentDetail,
                  },
                })
                this.openEmail()
              } else {
                Promise.all([
                  addInvoice({
                    ...this.state,
                    Data: {
                      ...this.state.Data,
                      PaymentDetail: inv.Data.PaymentDetail,
                    },
                  }),
                  this.openEmail(true, trip && trip.Status < 8),
                ])
              }
            })
          }
          onCancel={() => this.setState({ ...this.state, promptSubmit: false })}
        />
      </>
    )
  }

  ToolbarContents = () => {
    const {
      theme,
      classes,
      invoiceDialog,
      addInvoice,
      securables,
      users,
      updateInvoice,
      updateInvoiceDialog,
      taxCodes,
      removeInvoice,
      formOfPayments,
      materials,
      laborTypes,
      openEditWorkOrderUsageDialog,
      openEditWorkOrderDialog,
      openEditCustomerSiteDialog,
      openEditCustomerDialog,
      Co,
      companies,
      technicians,
      divisions,
      addAgreementInvoice,
    } = this.props
    const inv = this.state.Data

    const assignments = inv.Data.Assignments
    const billableDetails = inv.ID
      ? inv.Data.InvoiceDetails
      : _.filter(
          inv.Data.InvoiceDetails,
          l =>
            (l.Data.Usage && l.Data.Usage.NonBillableYN !== 'Y') ||
            (l.Data.Scope && l.Data.Scope.NonBillableYN !== 'Y'),
        )
    const batched = _.sumBy(billableDetails || {}, d => {
      return _.sumBy(d.Data.InvoiceLines || {}, o => {
        return o.Data && o.Data.VPARBL ? o.Data.VPARBL.Amount : 0
      })
    })
    const posted = _.sumBy(billableDetails || {}, d => {
      return _.sumBy(d.Data.InvoiceLines || {}, o => {
        return o.Data && o.Data.VPARTL ? o.Data.VPARTL.Amount : 0
      })
    })

    return (
      <Typography variant='subtitle1' color='inherit'>
        {Boolean(inv.Data.VPARBH || inv.Data.VPARTH) && (
          <Tooltip
            title={
              <div>
                Batched: {this.formatDollars(batched)}
                <br />
                Posted: {this.formatDollars(posted)}
              </div>
            }
            placement='top'
          >
            <Icon className='mr-8 mb-4' style={{ verticalAlign: 'middle' }}>
              {Boolean(inv.Data.VPARBH) ? 'cloud_upload' : 'cloud_done'}
            </Icon>
          </Tooltip>
        )}
        {invoiceDialog.type === 'new'
          ? `New Invoice #${inv.InvoiceNumber}`
          : `Invoice #${inv.InvoiceNumber}`}
      </Typography>
    )
  }

  closeWarningDialog = () => {
    this.setState({ shouldRenderDiv: false })
  }

  render() {
    const {
      theme,
      classes,
      invoiceDialog,
      addInvoice,
      securables,
      users,
      updateInvoice,
      updateInvoiceDialog,
      taxCodes,
      removeInvoice,
      formOfPayments,
      materials,
      laborTypes,
      openEditWorkOrderUsageDialog,
      openEditWorkOrderDialog,
      openEditCustomerSiteDialog,
      openEditCustomerDialog,
      Co,
      companies,
      technicians,
      divisions,
      addAgreementInvoice,
    } = this.props
    const { isCreatingCustomerPayments } = this.state
    const { trip, readOnly } = invoiceDialog.props
    const inv = this.state.Data
    const { Contacts } = inv.Data
    var contact = _.find(Contacts, o => {
      return o.Data.Contact.Name === inv.BillContact
    })
    const { invoiceEmail, showWarrantyDetail, editLine } = this.state
    const assignments = inv.Data.Assignments
    const billableDetails = inv.ID
      ? inv.Data.InvoiceDetails
      : _.filter(
          inv.Data.InvoiceDetails,
          l =>
            (l.Data.Usage && l.Data.Usage.NonBillableYN !== 'Y') ||
            (l.Data.Scope && l.Data.Scope.NonBillableYN !== 'Y'),
        )
    const lines = _.flatten(
      _.map(billableDetails, _.property('Data.InvoiceLines')),
    )
    let sub = _.sumBy(lines, o => {
      return Number(o.Amount)
    })
    let tax = _.sumBy(lines, o => {
      return Number(o.TaxAmount)
    })
    const co = _.find(companies, { Co }).Data.Co
    let total = sub + tax
    let payments =
      inv.Data && inv.Data.Payments
        ? _.sumBy(inv.Data.Payments, pmt =>
            _.sumBy(
              _.filter(
                pmt.Data.Allocations || pmt.Data.Payment.Data.Allocations,
                o =>
                  !o.VoidDate &&
                  o.Co === inv.Co &&
                  o.Invoice === (inv.Invoice || o.Invoice) &&
                  o.InvoiceNumber === inv.InvoiceNumber,
              ),
              'Amount',
            ),
          )
        : 0
    let due = total - payments
    // window["warn"]('Invoice Data: ', this.state, inv, lines, payments, total, due);
    const User = !this.state.EnteredBy
      ? this.props.User
      : _.findLast(this.props.Users, n => {
          return n.UserName === this.state.EnteredBy
        })
    const accessLevel = _.find(securables, { Securable: 'invoices' })
    const userAvatar = '../../assets/images/avatars/profile.jpg'
    const batched = _.sumBy(billableDetails || {}, d => {
      return _.sumBy(d.Data.InvoiceLines || {}, o => {
        return o.Data && o.Data.VPARBL ? o.Data.VPARBL.Amount : 0
      })
    })
    const posted = _.sumBy(billableDetails || {}, d => {
      return _.sumBy(d.Data.InvoiceLines || {}, o => {
        return o.Data && o.Data.VPARTL ? o.Data.VPARTL.Amount : 0
      })
    })
    if (trip) {
      const start = moment(trip.InProgressTime || trip.EnRouteTime)
      const end = moment(trip.CompletedTime)
      const sec = end.diff(start, 'seconds')
      trip.ActualDuration = sec / 60 / 60
    }
    const paymentTypes = {
      visa: 'Visa',
      mastercard: 'MasterCard',
      master: 'MasterCard',
      discover: 'Discover',
      american_express: 'American Express',
      amex: 'American Express',
      am_ex: 'American Express',
    }
    const scope =
      inv.Data && inv.Data.Scopes && inv.Data.Scopes.length > 0
        ? inv.Data.Scopes[0]
        : null
    const billCust = inv.Data.Customer
      ? inv.Data.Customer
      : scope
        ? scope.Data.Customer || {}
        : {}
    const agreement =
      inv.Data &&
      billableDetails &&
      billableDetails.length > 0 &&
      billableDetails[0].Data &&
      billableDetails[0].Data.Agreement
        ? billableDetails[0].Data.Agreement
        : null
    const division = scope
      ? _.find(divisions, {
          Co,
          BusinessUnit: scope.BusinessUnit,
          Division: scope.Division,
        })
      : agreement
        ? _.find(divisions, {
            Co,
            BusinessUnit: agreement.BusinessUnit,
            Division: agreement.Division,
          })
        : null
    let pmts = [...(inv.Data && inv.Data.Payments ? inv.Data.Payments : [])]

    // window["warn"]('Trip Info: ', trip);
    // window["warn"]('Edit Line Info: ', editLine);

    // window["warn"]('Division Data: ', division)
    // window["warn"]('Divisions Data: ', divisions)

    window['warn']('Invoice Dialog: ', invoiceDialog, this.props.estimates)

    const dialogProps = {
      ...invoiceDialog.props,
      className: classes.root,
      onClose: this.closeComposeDialog,
      fullWidth: true,
      maxWidth: 'md',
      //hideBackdrop: true,
      disableEnforceFocus: true,
    }

    const allContacts =
      this.state.Data &&
      this.state.Data.Customer &&
      this.state.Data.Site &&
      this.state.Data.Site.Data &&
      this.state.Data.Customer.Data &&
      this.state.Data.Site.Data.Contacts &&
      this.state.Data.Customer.Data.Contacts
        ? _.uniqBy(
            [
              ...this.state.Data.Site.Data.Contacts,
              ...this.state.Data.Customer.Data.Contacts,
            ],
            contact => contact.Data.Contact.Email,
          )
        : []

    const isLoading =
      isCreatingCustomerPayments === true &&
      co.PaymentsToken != null &&
      co.PaymentsToken != undefined

    return (
      <Media query='(min-width: 1200px)' /**/>
        {matches => (
          <React.Fragment>
            {invoiceEmail && (
              <MailCompose
                contacts={allContacts}
                dbAttachments={[
                  ...(inv.Data.Attachments ? inv.Data.Attachments : []),
                  ...(inv.Data.WorkOrder
                    ? inv.Data.WorkOrder.Data.Attachments
                    : []),
                ]}
                onClose={() =>
                  this.setState(
                    {
                      invoiceEmail: null,
                      checklistResults: '',
                      checklistsPDF: null,
                    },
                    () => {
                      if (
                        this.state.closeAfter &&
                        (!trip || trip.Status === 8)
                      ) {
                        this.closeComposeDialog()
                      }
                    },
                  )
                }
                hideButton={true}
                data={invoiceEmail}
              />
            )}
            <EmailLogDialog
              title={`Invoice #${inv.InvoiceNumber} Email Log`}
              type='INVOICE'
              rec={inv.ID}
              open={this.state.showEmailLogs}
              onClose={() => this.setState({ showEmailLogs: false })}
            />
            <DraggableDialog
              SlideUp={SlideUp}
              matches={matches}
              dialogProps={{ ...dialogProps }}
              AppBarChildren={this.AppBarContents}
              ToolbarChildren={this.ToolbarContents}
              closeComposeDialog={this.closeComposeDialog.bind(this)}
              closeDialogForSplitscreen={this.closeDialogForSplitscreen.bind(
                this,
              )}
              header={`Invoice #${this.state.Data.InvoiceNumber}`}
              type='Invoice'
              picRef={this.ref.current}
              icon='receipt'
              dialogState={this.state}
              reopenDialogFxn={this.props.openPreloadWorkInvoiceDialog}
              description={this.state.Description}
              storeDialog={invoiceDialog}
              TypeState={this.state.InvoiceNumber}
              screenOverlay={this.state.screenOverlay}
              //posSet={this.state.posSet}
              //initialWidthSet={initialWidthSet}
              //minHeight={this.state.minHeight}
              //location={this.state.location}
              //heightUpdateFxn={this.heightUpdateFxn.bind(this)}
              //dialogHeight={this.state.dialogHeight}
            >
              {/* -------- Invoice Data -------- */}
              <DialogContent classes={{ root: 'p-12' }} ref={this.ref}>
                <Dialog
                  open={Boolean(this.state.shouldRenderDiv)}
                  maxWidth='sm'
                  fullWidth={true}
                  onClose={this.closeWarningDialog}
                >
                  <AppBar position='static' color='primary'>
                    <Toolbar className='flex w-full'>
                      <Typography color='inherit'>
                        {this.state.scopesToRender.length === 1
                          ? `WARNING! There is 1 scope that is not ready to be billed`
                          : `WARNING! There are ${this.state.scopesToRender.length} scopes that are not ready to be billed`}
                      </Typography>
                      <IconButton
                        style={{
                          height: 30,
                          width: 30,
                          position: 'absolute',
                          right: 10,
                          display: 'flex',
                          justifyContent: 'center',
                          alignContent: 'center',
                        }}
                        onClick={this.closeWarningDialog}
                      >
                        <Icon style={{ color: 'white', fontSize: 25 }}>
                          close
                        </Icon>
                      </IconButton>
                    </Toolbar>
                  </AppBar>
                  <DialogContent>
                    {this.state.scopesToRender.map(scope => {
                      return (
                        <List>
                          <ListItem style={{ paddingLeft: 0 }}>
                            <Icon
                              style={{
                                color: '#4dbce9',
                                marginRight: 5,
                                fontSize: 30,
                              }}
                            >
                              account_circle
                            </Icon>
                            <ListItemText primary={scope.LeadTechnician} />
                          </ListItem>
                          <Collapse in={true}>
                            <List component='div' disablePadding>
                              <ListItem style={{ paddingLeft: 10 }}>
                                <Icon
                                  style={{
                                    color: '#4dbce9',
                                    marginRight: 5,
                                    fontSize: 25,
                                  }}
                                >
                                  assignment
                                </Icon>
                                WorkOrder: #{scope.WorkOrder}
                              </ListItem>
                              <ListItem style={{ paddingLeft: 10 }}>
                                <Icon
                                  style={{
                                    color: '#4dbce9',
                                    marginRight: 5,
                                    fontSize: 25,
                                  }}
                                >
                                  build
                                </Icon>
                                Service Type: {scope.ServiceType}
                              </ListItem>
                            </List>
                          </Collapse>
                        </List>
                      )
                    })}
                  </DialogContent>
                </Dialog>
                {isLoading && <Loading />}
                {!isLoading && (
                  <>
                    <div style={{ width: 0, height: 0, overflow: 'hidden' }}>
                      {this.state.checklistResults}
                    </div>
                    <div id='invoice-print' className='inv-print sm:mx-24'>
                      <div className='w-full flex justify-between my-24'>
                        {division && division.InvoiceHeaderImg && (
                          <img
                            className='h-48 sm:h-96'
                            src={division.InvoiceHeaderImg}
                          />
                        )}
                        <span className='sm:mt-12 flex flex-col items-end'>
                          <label
                            className={classNames(
                              classes.invHeader,
                              'text-20 sm:text-36',
                            )}
                          >
                            INVOICE
                          </label>
                          <div
                            className={classNames(
                              classes.invSubHeader,
                              'text-10 sm:text-16',
                            )}
                          >
                            #{this.state.InvoiceNumber}
                            {this.state.Status < 1 && (
                              <Icon
                                style={{ padingTop: 5, marginRight: -16 }}
                                className='ml-4 no-print text-16 cursor-pointer align-middle mb-2'
                                onClick={() => {
                                  this.setState({
                                    ...this.state,
                                    editInvoice: !this.state.editInvoice,
                                  })
                                }}
                              >
                                edit
                              </Icon>
                            )}
                          </div>
                          <br />
                          {this.state.editInvoice && this.state.Status < 1 && (
                            <TextField
                              className='mt-6'
                              label='Invoice Number'
                              id='invoiceNumber'
                              name='InvoiceNumber'
                              value={this.state.InvoiceNumber || ''}
                              onChange={this.handleChange}
                              onBlur={() => {
                                this.setState({
                                  ...this.state,
                                  editInvoice: false,
                                })
                              }}
                              variant='outlined'
                            />
                          )}
                        </span>
                      </div>

                      <div className='flex mb-24 justify-between px-16'>
                        <div
                          className='cursor-pointer'
                          onClick={() => {
                            openEditCustomerDialog({
                              Co: this.state.Co,
                              Customer: this.state.Customer,
                              Name: this.state.BillName,
                            })
                          }}
                        >
                          <div className='w-full text-16 font-bold text-left'>
                            {this.state.BillName}
                            <Icon
                              onClick={e => {
                                e.stopPropagation()
                                this.setState({
                                  ...this.state,
                                  EditBillContact: true,
                                })
                              }}
                              className='cursor-pointer text-16 ml-4 no-print align-middle'
                            >
                              contacts
                            </Icon>
                          </div>
                          {inv.BillContact && (
                            <div className='w-full text-12 text-left'>
                              C/O: {inv.BillContact}
                            </div>
                          )}
                          {this.state.EditBillContact && Contacts && (
                            <div onClick={e => e.stopPropagation()}>
                              <Autocomplete
                                className='my-12 no-print min-w-256'
                                title='Billing Contact'
                                options={[
                                  { Data: { Contact: { Name: 'None' } } },
                                  ..._.filter(Contacts, { BillingYN: 'Y' }),
                                ]}
                                menuItemComponent={value => {
                                  return (
                                    <MenuItem value={value.Contact}>
                                      <div className='flex'>
                                        {`${value.Data.Contact.Name}`}
                                      </div>
                                    </MenuItem>
                                  )
                                }}
                                noflip={true}
                                portal={true}
                                value={contact ? contact.Contact : ''}
                                onSelect={option =>
                                  this.setState({
                                    ...this.state,
                                    BillContact: option.Contact
                                      ? option.Data.Contact.Name
                                      : null,
                                    Data: {
                                      ...this.state.Data,
                                      BillContact: option.Contact
                                        ? option.Data.Contact.Name
                                        : null,
                                    },
                                    EditBillContact: false,
                                  })
                                }
                                required
                                onBlur={() =>
                                  this.setState({
                                    ...this.state,
                                    EditBillContact: false,
                                  })
                                }
                              />
                              <br />
                            </div>
                          )}
                          <div className='w-full text-12 text-left'>
                            {this.state.BillAddress1}
                          </div>
                          {this.state.BillAddress2 && (
                            <div className='w-full text-12 text-left'>
                              {this.state.BillAddress2}
                            </div>
                          )}
                          <div className='w-full text-12 text-left'>
                            {`${this.state.BillCity}, ${this.state.BillState} ${this.state.BillZip}`}
                          </div>
                          {inv.BillPhone && (
                            <div className='w-full text-12 text-left no-print'>
                              {this.formatPhone(inv.BillPhone)}
                            </div>
                          )}
                          {inv.BillEmail && (
                            <div className='w-full text-12 text-left no-print'>
                              {inv.BillEmail}
                            </div>
                          )}
                          {billCust.ApprovedAccountYN === 'Y' && (
                            <div className='capitalize font-bold no-print'>
                              <Icon
                                color='primary'
                                className='mr-4 text-14 align-middle mb-2'
                              >
                                security
                              </Icon>
                              Approved Account
                            </div>
                          )}
                          {billCust.InvoiceReviewYN === 'Y' && (
                            <div className='capitalize font-bold no-print'>
                              <Icon
                                color='error'
                                className='mr-4 text-18 align-middle mb-4'
                              >
                                visibility_on
                              </Icon>
                              Invoice Review Required
                            </div>
                          )}
                          {billCust.MailInvoiceYN === 'Y' && (
                            <div className='capitalize font-bold no-print'>
                              <Icon
                                color='error'
                                className='mr-4 text-18 align-middle mb-4'
                              >
                                mail
                              </Icon>
                              Send Invoice Via Mail
                            </div>
                          )}
                        </div>

                        <div>
                          <DatePicker
                            inputVariant='outlined'
                            onOpen={() => this.setDatepickerOpen(true)}
                            onClose={() => this.setDatepickerOpen(false)}
                            open={this.state.isDatepickerOpen}
                            label='Date'
                            inputProps={{
                              style: {
                                padding: 12,
                              },
                            }}
                            TextFieldComponent={() => ''}
                            value={this.state.InvoiceDate}
                            onChange={this.handleDateChange}
                            showTodayButton
                            autoOk
                          />
                          <div
                            className='flex cursor-pointer'
                            onClick={this.openDatePicker}
                          >
                            <img
                              src='assets/icons/calendar_icon.png'
                              className='w-32 h-32 mr-8'
                            />
                            <div className='w-full'>
                              <div className='text-14 font-bold'>
                                {moment(this.state.InvoiceDate).format('dddd')}
                              </div>
                              <div className='text-10'>
                                {moment(this.state.InvoiceDate).format(
                                  'MMM DD, YYYY',
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='flex mt-24'>
                        <div className='w-full'>
                          <div
                            className='w-full flex justify-between rounded-full mb-12'
                            style={{ backgroundColor: '#3f3f3f' }}
                          >
                            <div
                              onClick={() =>
                                openEditWorkOrderDialog({
                                  Co: this.state.Co,
                                  WorkOrder: this.state.WorkOrder,
                                })
                              }
                              className={classNames(
                                'w-auto rounded-full py-6 px-32 text-14 font-bold truncate cursor-pointer',
                                classes.dividerHighlight,
                              )}
                            >
                              {this.state.WorkOrder
                                ? `Work Order #${this.state.WorkOrder}`
                                : 'Agreement Billing'}
                            </div>
                            <div
                              className={classNames(
                                'w-2/3 py-8 px-24 text-12 font-bold text-right truncate',
                                classes.dividerPlain,
                              )}
                            >
                              {this.state.Location}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='mx-24 mt-12'>
                        {!inv.Data.Scopes ||
                          (inv.Data.Scopes.length < 1 &&
                            billableDetails.map((detail, index) => {
                              const {
                                Co,
                                Invoice,
                                InvoiceDetail,
                                WorkOrder,
                                Scope,
                                UsageLine,
                                Data,
                                Agreement,
                                AgreementCycle,
                              } = detail
                              const lines = Data ? Data.InvoiceLines : []
                              const postedTotal = _.sumBy(lines, o => {
                                return o.Data && o.Data.VPARTL
                                  ? o.Data.VPARTL.Amount -
                                      o.Data.VPARTL.TaxAmount
                                  : 0.0
                              })
                              const est =
                                scope &&
                                scope.Data &&
                                (!scope.Invoice ||
                                  scope.Invoice === inv.Invoice) &&
                                scope.Data.Estimate
                              const batchTotal = _.sumBy(lines, o => {
                                return o.Data && o.Data.VPARBL
                                  ? o.Data.VPARBL.Amount -
                                      o.Data.VPARBL.TaxAmount || 0
                                  : 0.0
                              })
                              // console.warn(lines, postedTotal);
                              let line = {
                                Qty: 1,
                                UOM: 'EA',
                                Description: `${lines[0].Description}`,
                                Price: _.sumBy(lines, 'Amount'),
                              }
                              if (line) {
                                return (
                                  <React.Fragment>
                                    <Chip
                                      classes={{
                                        avatar: classes.chipAvatar,
                                        label: classes.chipLabel,
                                      }}
                                      avatar={
                                        <Avatar src='assets/images/logos/SP_Cog_Gradient.png' />
                                      }
                                      label={`Agreement #${Agreement} Cycle #${AgreementCycle}`}
                                      // onClick={handleClick}
                                      // color="primary"
                                      className={classes.chip}
                                    />
                                    <Table
                                      className={classNames(
                                        classes.table,
                                        'mb-24',
                                      )}
                                    >
                                      <TableHead>
                                        <TableRow className='h-24'>
                                          <TableCell className='w-1/6 p-0 table-cell font-bold'>
                                            Qty
                                          </TableCell>
                                          <TableCell className='w-1/6 p-0 table-cell font-bold'>
                                            UOM
                                          </TableCell>
                                          <TableCell className='w-1/2 table-cell p-0 font-bold'>
                                            Description
                                          </TableCell>
                                          <TableCell className='w-1/6 text-right p-0 table-cell font-bold'>
                                            Price
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow
                                          key={index}
                                          hover
                                          className='cursor-pointer h-24'
                                        >
                                          <TableCell className='w-1/6 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                            {Number(line.Qty).toFixed(2)}
                                          </TableCell>
                                          <TableCell className='w-1/6 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                            {line.UOM}
                                          </TableCell>
                                          <TableCell className='w-1/2 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                            {line.Description}
                                          </TableCell>
                                          <TableCell className='w-1/6 text-right font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                            {est && est.DiscountTotal
                                              ? this.formatDollars(
                                                  line.Price +
                                                    est.DiscountTotal,
                                                )
                                              : this.formatDollars(line.Price)}
                                            {/* {this.formatDollars(line.Price)} */}
                                            {(inv.Data.VPARBH ||
                                              inv.Data.VPARTH) && (
                                              <Tooltip
                                                title={
                                                  <div>
                                                    Batched:{' '}
                                                    {this.formatDollars(
                                                      batchTotal,
                                                    )}
                                                    <br />
                                                    Posted:{' '}
                                                    {this.formatDollars(
                                                      postedTotal,
                                                    )}
                                                  </div>
                                                }
                                                placement='top'
                                              >
                                                <Icon
                                                  color={
                                                    this.formatDollars(
                                                      postedTotal,
                                                    ) ===
                                                    this.formatDollars(
                                                      line.Price,
                                                    )
                                                      ? 'secondary'
                                                      : this.formatDollars(
                                                            batchTotal,
                                                          ) ===
                                                          this.formatDollars(
                                                            line.Price,
                                                          )
                                                        ? 'action'
                                                        : 'error'
                                                  }
                                                  className={classNames(
                                                    'mb-4 mt-4 ml-36 text-20 no-print',
                                                  )}
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    marginRight: -56,
                                                  }}
                                                >
                                                  {this.formatDollars(
                                                    postedTotal,
                                                  ) ===
                                                  this.formatDollars(line.Price)
                                                    ? 'cloud_done'
                                                    : this.formatDollars(
                                                          batchTotal,
                                                        ) ===
                                                        this.formatDollars(
                                                          line.Price,
                                                        )
                                                      ? 'cloud_upload'
                                                      : 'error'}
                                                </Icon>
                                              </Tooltip>
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </React.Fragment>
                                )
                              }
                            }))}
                        {inv.Data.Scopes.map((scope, index) => {
                          const assigned = assignments
                            ? _.find(assignments, { Scope: scope.Scope })
                            : true
                          const est =
                            scope &&
                            scope.Data &&
                            (!scope.Invoice || scope.Invoice === inv.Invoice) &&
                            scope.Data.Estimate
                          const estPayments =
                            est && est.Data && est.Data.Payments
                              ? _.sumBy(
                                  _.filter(
                                    est.Data.Payments,
                                    p =>
                                      (!p.InvoiceNumber ||
                                        p.InvoiceNumber ===
                                          inv.InvoiceNumber) &&
                                      _.findIndex(inv.Data.Payments, {
                                        PaymentID: p.PaymentID,
                                      }) < 0,
                                  ),
                                  o =>
                                    o.Data &&
                                    o.Data.Payment &&
                                    o.Data.Payment.VoidDate
                                      ? 0
                                      : o.Total || o.Amount,
                                )
                              : 0
                          const pmts = [
                            ...(est && est.Data && est.Data.Payments
                              ? _.filter(
                                  est.Data.Payments,
                                  p =>
                                    (!p.InvoiceNumber ||
                                      p.InvoiceNumber === inv.InvoiceNumber) &&
                                    _.findIndex(inv.Data.Payments, {
                                      PaymentID: p.PaymentID,
                                    }) < 0,
                                )
                              : []),
                            ...pmts,
                          ]
                          // payments += estPayments;
                          due = due - estPayments
                          let scopeTotal = 0
                          const billableAmt = _.sumBy(
                            _.filter(
                              billableDetails,
                              o =>
                                o.Scope === scope.Scope ||
                                (o.Data.Usage &&
                                  o.Data.Usage.Scope === scope.Scope),
                            ),
                            o => _.sumBy(o.Data.InvoiceLines, 'Amount'),
                          )
                          const leadTech = _.find(technicians, {
                            Technician: scope.LeadTechnician,
                          })
                          window['warn']('Scope Estimate 123: ', scope.Data)
                          // window["warn"](assignments, assigned);
                          if (
                            _.filter(
                              billableDetails,
                              o =>
                                o.Scope === scope.Scope ||
                                (o.Data.Usage &&
                                  o.Data.Usage.Scope === scope.Scope),
                            ).length > 0
                          ) {
                            return (
                              <div className='w-full mb-16'>
                                <div className='w-full flex justify-between'>
                                  <Chip
                                    classes={{
                                      avatar: classes.chipAvatar,
                                      label: classes.chipLabel,
                                    }}
                                    avatar={
                                      <Avatar src='assets/images/logos/SP_Cog_Gradient.png' />
                                    }
                                    label={`${
                                      _.findLast(this.props.services, n => {
                                        return n.Service === scope.Service
                                      }).Description
                                    }${
                                      scope.CustomerPO
                                        ? ` - PO #${scope.CustomerPO}`
                                        : ''
                                    }`}
                                    // onClick={handleClick}
                                    // color="primary"
                                    className={classes.chip}
                                  />
                                  {leadTech && (
                                    <div className='flex'>
                                      <img
                                        src='assets/icons/person_icon.png'
                                        className='w-24 h-24 mr-4'
                                      />
                                      <div className='w-full'>
                                        <div className='text-12 font-bold mt-4 flex'>
                                          <div>{`${
                                            leadTech.FirstName
                                          } ${leadTech.LastName.substr(
                                            0,
                                            1,
                                          )}`}</div>
                                          <div className='no-print'>{`${leadTech.LastName.substr(
                                            1,
                                            leadTech.LastName.length - 1,
                                          )}`}</div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                {scope.NotToExceed &&
                                  billableAmt > scope.NotToExceed && (
                                    <div className='w-full mb-12 no-print'>
                                      <div
                                        className='p-8 rounded py-12 w-full cursor-pointer'
                                        style={{
                                          backgroundColor: '#f9f9f9',
                                          border: '1px dashed red',
                                        }}
                                      >
                                        <Typography className='text-14 font-bold text-red w-full text-center'>
                                          <Icon
                                            color='error'
                                            className='mr-6 text-20 mb-6 align-middle'
                                          >
                                            error
                                          </Icon>
                                          PRICE LIMIT EXCEEDED
                                        </Typography>
                                        <div className='w-full'>
                                          <div className='italic mt-4 text-center text-12'>
                                            This scope's total price exceeds the
                                            specified limit of{' '}
                                            {this.formatDollars(
                                              scope.NotToExceed,
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                {scope.IsWarrantyYN === 'Y' && (
                                  <FormControlLabel
                                    className='float-right mr-0 no-print'
                                    control={
                                      <Switch
                                        checked={showWarrantyDetail}
                                        name='showWarrantyDetail'
                                        onChange={() =>
                                          this.setState({
                                            ...this.state,
                                            showWarrantyDetail:
                                              !showWarrantyDetail,
                                          })
                                        }
                                        color='primary'
                                      />
                                    }
                                    label='Show Detail'
                                  />
                                )}
                                <Table
                                  className={classNames(
                                    classes.table,
                                    'clear mb-12',
                                  )}
                                >
                                  <TableHead>
                                    <TableRow className='h-24'>
                                      <TableCell className='w-1/6 p-0 table-cell font-bold'>
                                        Qty
                                      </TableCell>
                                      <TableCell className='w-1/6 p-0 table-cell font-bold'>
                                        UOM
                                      </TableCell>
                                      <TableCell className='w-1/2 table-cell p-0 font-bold'>
                                        Description
                                      </TableCell>
                                      <TableCell className='w-1/6 text-right p-0 table-cell font-bold'>
                                        Price
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {scope.IsWarrantyYN === 'Y' &&
                                      !showWarrantyDetail && (
                                        <TableRow
                                          key={index}
                                          hover
                                          className='cursor-pointer h-24'
                                        >
                                          <TableCell className='w-1/6 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                            {(1).toFixed(2)}
                                          </TableCell>
                                          <TableCell className='w-1/6 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                            {'EA'}
                                          </TableCell>
                                          <TableCell className='w-1/2 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                            {'Warranty Service - No Charge'}
                                          </TableCell>
                                          <TableCell className='w-1/6 text-right font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                            $0.00
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    {billableDetails.map((detail, index) => {
                                      const {
                                        Co,
                                        Invoice,
                                        InvoiceDetail,
                                        WorkOrder,
                                        Scope,
                                        UsageLine,
                                        Data,
                                        WarrantyYN,
                                      } = detail
                                      const lines = Data
                                        ? Data.InvoiceLines
                                        : []
                                      const postedTotal = _.sumBy(lines, o => {
                                        return o.Data && o.Data.VPARTL
                                          ? o.Data.VPARTL.Amount -
                                              o.Data.VPARTL.TaxAmount
                                          : 0.0
                                      })
                                      const batchTotal = _.sumBy(lines, o => {
                                        return o.Data && o.Data.VPARBL
                                          ? o.Data.VPARBL.Amount -
                                              o.Data.VPARBL.TaxAmount || 0
                                          : 0.0
                                      })
                                      const svc = Data.Scope
                                        ? _.find(this.props.services, {
                                            Service: Data.Scope.Service,
                                          })
                                        : {} //problem line
                                      // console.warn(lines, postedTotal);
                                      let line = null
                                      if (
                                        Scope &&
                                        scope.Scope === Scope &&
                                        WarrantyYN !== 'Y'
                                      ) {
                                        line = {
                                          Qty: 1,
                                          UOM: 'FP',
                                          Description:
                                            detail.Description ||
                                            (est
                                              ? ` Per Quote #${scope.Data.Estimate.EstimateNumber}`
                                              : scope.Summary
                                                ? 'Flat Price - Summary Below'
                                                : svc
                                                  ? `${Data.Scope.Service} - ${svc.Description}`
                                                  : `${Data.Scope.Description}`),
                                          Price: Data.Scope.Price,
                                          Tax: _.sumBy(lines, 'TaxAmount'),
                                          TaxAmount: _.sumBy(
                                            lines,
                                            'TaxAmount',
                                          ),
                                          TaxCode: lines[0]
                                            ? lines[0].TaxCode
                                            : null,
                                        }
                                      }
                                      if (
                                        Scope &&
                                        scope.Scope === Scope &&
                                        WarrantyYN === 'Y'
                                      ) {
                                        if (lines.length > 0) {
                                          const {
                                            Amount,
                                            TaxAmount,
                                            Description,
                                          } = lines[0]
                                          line = {
                                            Qty: 1,
                                            UOM: 'EA',
                                            Description:
                                              detail.Description || Description,
                                            Price: Amount,
                                            Tax: TaxAmount,
                                            TaxAmount,
                                            TaxCode: scope.TaxCode,
                                          }
                                        }
                                      }
                                      if (
                                        /*UsageLine && */ Data.Usage &&
                                        Data.Usage.Scope === scope.Scope &&
                                        Data.Usage.Type !== 6 &&
                                        Data.Usage.Type !== 7 &&
                                        Data.Usage.Type !== 8
                                      ) {
                                        line = {
                                          Qty: Data.Usage.Qty,
                                          UOM: Data.Usage.UOM,
                                          Description:
                                            detail.Description ||
                                            `${Data.Usage.Description}${
                                              Data.Usage.Type === 2 &&
                                              Data.Usage.Material
                                                ? ` (Part #${Data.Usage.Material})`
                                                : ''
                                            }`,
                                          Price: Data.Usage.PriceTotal,
                                          Tax: _.sumBy(lines, 'TaxAmount'),
                                          TaxAmount: _.sumBy(
                                            lines,
                                            'TaxAmount',
                                          ),
                                          TaxCode: lines[0].TaxCode,
                                        }
                                      }
                                      if (line) {
                                        if (
                                          scope.IsWarrantyYN === 'Y' &&
                                          !inv.ID
                                        ) {
                                          if (
                                            !Data.Usage ||
                                            Data.Usage.AddOnYN !== 'Y'
                                          ) {
                                            scopeTotal -= line.Price
                                            sub -= line.Price
                                            tax -= line.Tax || 0
                                            total = sub + tax
                                            due = total - payments
                                          }
                                        }
                                        if (
                                          scope.IsWarrantyYN !== 'Y' ||
                                          showWarrantyDetail ||
                                          (Data.Usage &&
                                            Data.Usage.AddOnYN === 'Y')
                                        ) {
                                          return (
                                            <TableRow
                                              key={index}
                                              hover
                                              onClick={e => {
                                                if (!inv.ID) {
                                                  if (
                                                    Data.Usage &&
                                                    Data.Usage.Status !==
                                                      'INVOICED' &&
                                                    Data.Usage.Status !==
                                                      'INVOICE PENDING' &&
                                                    assigned
                                                  ) {
                                                    openEditWorkOrderUsageDialog(
                                                      {
                                                        ...Data.Usage,
                                                        Data: { Scope: scope },
                                                      },
                                                      inv.Data.Customer.Data
                                                        .Taxability,
                                                    )
                                                  }
                                                } else {
                                                  this.setState({
                                                    editLine: {
                                                      detail,
                                                      line,
                                                      scope,
                                                    },
                                                  })
                                                }
                                                window['warn'](this.state)
                                              }}
                                              className='cursor-pointer h-24'
                                            >
                                              <TableCell className='w-1/6 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                                {Number(line.Qty).toFixed(2)}
                                              </TableCell>
                                              <TableCell className='w-1/6 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                                {line.UOM}
                                              </TableCell>
                                              <TableCell className='w-1/2 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                                {line.Description}
                                                {(scope.PriceMethod !== 'T' ||
                                                  scope.IsWarrantyYN === 'Y') &&
                                                Data.Usage &&
                                                Data.Usage.AddOnYN === 'Y'
                                                  ? ' - Add-On'
                                                  : ''}
                                              </TableCell>
                                              <TableCell className='w-1/6 text-right font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                                {est && est.DiscountTotal
                                                  ? this.formatDollars(
                                                      line.Price +
                                                        est.DiscountTotal,
                                                    )
                                                  : this.formatDollars(
                                                      line.Price,
                                                    )}
                                                {/* {this.formatDollars(line.Price)} */}
                                                {(inv.Data.VPARBH ||
                                                  inv.Data.VPARTH) && (
                                                  <Tooltip
                                                    title={
                                                      <div>
                                                        Batched:{' '}
                                                        {this.formatDollars(
                                                          batchTotal,
                                                        )}
                                                        <br />
                                                        Posted:{' '}
                                                        {this.formatDollars(
                                                          postedTotal,
                                                        )}
                                                      </div>
                                                    }
                                                    placement='top'
                                                  >
                                                    <Icon
                                                      color={
                                                        this.formatDollars(
                                                          postedTotal,
                                                        ) ===
                                                        this.formatDollars(
                                                          line.Price,
                                                        )
                                                          ? 'secondary'
                                                          : this.formatDollars(
                                                                batchTotal,
                                                              ) ===
                                                              this.formatDollars(
                                                                line.Price,
                                                              )
                                                            ? 'action'
                                                            : 'error'
                                                      }
                                                      className={classNames(
                                                        'mb-4 mt-4 ml-36 text-20 no-print',
                                                      )}
                                                      style={{
                                                        verticalAlign: 'middle',
                                                        marginRight: -56,
                                                      }}
                                                    >
                                                      {this.formatDollars(
                                                        postedTotal,
                                                      ) ===
                                                      this.formatDollars(
                                                        line.Price,
                                                      )
                                                        ? 'cloud_done'
                                                        : this.formatDollars(
                                                              batchTotal,
                                                            ) ===
                                                            this.formatDollars(
                                                              line.Price,
                                                            )
                                                          ? 'cloud_upload'
                                                          : 'error'}
                                                    </Icon>
                                                  </Tooltip>
                                                )}
                                              </TableCell>
                                            </TableRow>
                                          )
                                        }
                                      }
                                    })}
                                    {scope.IsWarrantyYN === 'Y' &&
                                      !inv.ID &&
                                      showWarrantyDetail && (
                                        <TableRow
                                          key={index}
                                          hover
                                          className='cursor-pointer h-24'
                                        >
                                          <TableCell className='w-1/6 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                            {(1).toFixed(2)}
                                          </TableCell>
                                          <TableCell className='w-1/6 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                            {'EA'}
                                          </TableCell>
                                          <TableCell className='w-1/2 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                            {'Warranty Service - Credit'}
                                          </TableCell>
                                          <TableCell className='w-1/6 text-right font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                            {this.formatDollars(scopeTotal)}
                                          </TableCell>
                                        </TableRow>
                                      )}

                                    {est && est.DiscountTotal && (
                                      <TableRow
                                        key={index}
                                        hover
                                        className='cursor-pointer h-24'
                                      >
                                        <TableCell className='w-1/6 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                          {(1).toFixed(2)}
                                        </TableCell>
                                        <TableCell className='w-1/6 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                          {'EA'}
                                        </TableCell>
                                        <TableCell className='w-1/2 font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                          Discount
                                        </TableCell>
                                        <TableCell className='w-1/6 text-right font-bold table-cell p-0 border-none invoice-line border-b-0'>
                                          {this.formatDollars(
                                            est.DiscountTotal * -1,
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                                {/* <div className="w-full no-print mb-36" style={{ marginTop: -8 }} onClick={() => { this.setState({ ...this.state, addPayment: true }) }}><strong className="ml-4 text-12 float-right cursor-pointer">Add Line</strong><Icon className="cursor-pointer text-14 ml-4 align-middle float-right no-print" color="primary">add_circle_outline</Icon></div> */}

                                {est &&
                                  est.Data &&
                                  est.Data.Components &&
                                  _.findIndex(billableDetails, {
                                    Scope: scope.Scope,
                                  }) > -1 && (
                                    <div
                                      className='w-full p-8 rounded-12 mb-12 cursor-pointer'
                                      style={{ border: '1px solid #f1f1f1' }}
                                      onClick={
                                        !readOnly
                                          ? () =>
                                              this.props.openEditEstimateDialog(
                                                est,
                                              )
                                          : undefined
                                      }
                                    >
                                      {
                                        //Basic Detail
                                        est.ComponentDetail === 'B' && (
                                          <ul>
                                            {_.filter(
                                              est.Data.Components,
                                              o => o.DeleteYN !== 'Y',
                                            ).map((value, index) => {
                                              const {
                                                Co,
                                                BusinessUnit,
                                                Division,
                                                Assembly,
                                                Material,
                                                LaborType,
                                                Qty,
                                                UOM,
                                              } = value
                                              const assembly =
                                                value.Data.Assembly
                                              const material = _.find(
                                                materials,
                                                {
                                                  Co,
                                                  Material,
                                                },
                                              )
                                              const laborType = _.find(
                                                laborTypes,
                                                {
                                                  Co,
                                                  BusinessUnit,
                                                  Division,
                                                  LaborType,
                                                },
                                              )
                                              // window["warn"](value, assembly, assemblies);
                                              if (assembly) {
                                                return (
                                                  <li>{`${Qty} - ${
                                                    value.Name || assembly.Name
                                                  }`}</li>
                                                )
                                              }
                                              if (!assembly) {
                                                var item = value
                                                const {
                                                  ImageID,
                                                  CustomImgURL,
                                                } = item
                                                return (
                                                  <li className='cursor-pointer'>{`${
                                                    value.Qty
                                                  } ${value.UOM} - ${
                                                    value.Name ||
                                                    item.Description ||
                                                    ''
                                                  }`}</li>
                                                )
                                              }
                                            })}
                                          </ul>
                                        )
                                      }
                                      {
                                        //Basic Detail
                                        est.ComponentDetail === 'S' && (
                                          <div>
                                            {_.filter(
                                              est.Data.Components,
                                              o => o.DeleteYN !== 'Y',
                                            ).map((value, index) => {
                                              const {
                                                Co,
                                                BusinessUnit,
                                                Division,
                                                Assembly,
                                                Material,
                                                LaborType,
                                                Qty,
                                                UOM,
                                              } = value
                                              const assembly =
                                                value.Data.Assembly
                                              const material = _.find(
                                                materials,
                                                {
                                                  Co,
                                                  Material,
                                                },
                                              )
                                              const laborType = _.find(
                                                laborTypes,
                                                {
                                                  Co,
                                                  BusinessUnit,
                                                  Division,
                                                  LaborType,
                                                },
                                              )
                                              // window["warn"](value, assembly, assemblies);
                                              if (assembly) {
                                                if (
                                                  assembly.Data &&
                                                  assembly.Data.Images
                                                ) {
                                                  assembly.Data.Image = _.find(
                                                    assembly.Data.Images,
                                                    { PrimaryYN: 'Y' },
                                                  )
                                                  if (
                                                    !assembly.Data.Image &&
                                                    assembly.Data.Images
                                                      .length > 0
                                                  ) {
                                                    assembly.Data.Image =
                                                      assembly.Data.Images[0]
                                                  }
                                                }
                                                return (
                                                  <div
                                                    className='prevent-split flex mb-8 pt-6'
                                                    style={{
                                                      borderTop:
                                                        index > 0 &&
                                                        '1px solid #f1f1f1',
                                                    }}
                                                  >
                                                    <div className='w-1/6 pt-6 pr-6'>
                                                      <img
                                                        alt={assembly.Code}
                                                        crossOrigin='Anonymous'
                                                        className='w-full component-img'
                                                        src={
                                                          assembly.Data.Image
                                                            ? `${window['apiLocation']}/api/AssemblyImage?ID=${assembly.Data.Image.ID}`
                                                            : 'assets/images/logos/SP_Cog_Gradient.png'
                                                        }
                                                      />
                                                    </div>
                                                    <div className='w-full'>
                                                      <Typography className='w-full font-bold text-16'>
                                                        {/* <div className={classNames(classes.qty)}>{value.Qty}</div> */}
                                                        {value.Name ||
                                                          assembly.Name}
                                                        {value.AddonYN ===
                                                          'Y' && (
                                                          <Typography
                                                            color='secondary'
                                                            className='font-bold text-10 mt-4 mr-6 float-right px-4 rounded bg-grey-darkest'
                                                          >
                                                            ADD-ON
                                                          </Typography>
                                                        )}
                                                      </Typography>
                                                      <Typography
                                                        className='clear'
                                                        variant='caption'
                                                      >
                                                        {parse(
                                                          value.Description ||
                                                            assembly.Description,
                                                        )}
                                                      </Typography>
                                                    </div>
                                                  </div>
                                                )
                                              }
                                              if (!assembly) {
                                                var item = value
                                                const {
                                                  ImageID,
                                                  CustomImgURL,
                                                } = item
                                                return (
                                                  <div
                                                    className='prevent-split flex mt-8 pt-6 cursor-pointer'
                                                    style={{
                                                      borderTop:
                                                        '1px solid #f1f1f1',
                                                    }}
                                                  >
                                                    <div className='w-1/6 pt-6 pr-6'>
                                                      <img
                                                        alt={Material}
                                                        crossOrigin='Anonymous'
                                                        className='w-full component-img'
                                                        src={
                                                          CustomImgURL ||
                                                          'assets/images/logos/SP_Cog_Gradient.png'
                                                        }
                                                      />
                                                    </div>
                                                    <div className='w-full'>
                                                      <Typography className='w-full font-bold text-16'>
                                                        {/* <div className={classNames(classes.qty)}>{`${value.Qty} ${value.UOM}`}</div> */}
                                                        {value.Name ||
                                                          item.Description}
                                                        {value.AddonYN ===
                                                          'Y' && (
                                                          <Typography
                                                            color='secondary'
                                                            className='font-bold text-10 mt-4 mr-6 float-right px-4 rounded bg-grey-darkest'
                                                          >
                                                            ADD-ON
                                                          </Typography>
                                                        )}
                                                      </Typography>
                                                      <Typography
                                                        className='clear'
                                                        variant='caption'
                                                      >
                                                        {parse(
                                                          value.Description ||
                                                            item.Description ||
                                                            '',
                                                        )}
                                                      </Typography>
                                                    </div>
                                                  </div>
                                                )
                                              }
                                            })}
                                          </div>
                                        )
                                      }
                                      {
                                        //Basic Detail
                                        est.ComponentDetail === 'F' && (
                                          <div>
                                            {_.filter(
                                              est.Data.Components,
                                              o => o.DeleteYN !== 'Y',
                                            ).map((value, index) => {
                                              const {
                                                Co,
                                                BusinessUnit,
                                                Division,
                                                Assembly,
                                                Material,
                                                LaborType,
                                                Qty,
                                                UOM,
                                              } = value
                                              const assembly =
                                                value.Data.Assembly
                                              const material = _.find(
                                                materials,
                                                {
                                                  Co,
                                                  Material,
                                                },
                                              )
                                              const laborType = _.find(
                                                laborTypes,
                                                {
                                                  Co,
                                                  BusinessUnit,
                                                  Division,
                                                  LaborType,
                                                },
                                              )
                                              // window["warn"](value, assembly, assemblies);
                                              if (assembly) {
                                                if (
                                                  assembly.Data &&
                                                  assembly.Data.Images
                                                ) {
                                                  assembly.Data.Image = _.find(
                                                    assembly.Data.Images,
                                                    { PrimaryYN: 'Y' },
                                                  )
                                                  if (
                                                    !assembly.Data.Image &&
                                                    assembly.Data.Images
                                                      .length > 0
                                                  ) {
                                                    assembly.Data.Image =
                                                      assembly.Data.Images[0]
                                                  }
                                                }
                                                return (
                                                  <div
                                                    className='prevent-split flex mb-8 pt-6'
                                                    style={{
                                                      borderTop:
                                                        index > 0 &&
                                                        '1px solid #f1f1f1',
                                                    }}
                                                  >
                                                    <div className='w-1/6 pt-6 pr-6'>
                                                      <img
                                                        alt={assembly.Code}
                                                        crossOrigin='Anonymous'
                                                        className='w-full component-img'
                                                        src={
                                                          assembly.Data.Image
                                                            ? `${window['apiLocation']}/api/AssemblyImage?ID=${assembly.Data.Image.ID}`
                                                            : 'assets/images/logos/SP_Cog_Gradient.png'
                                                        }
                                                      />
                                                    </div>
                                                    <div className='w-full'>
                                                      <Typography className='w-full font-bold text-16'>
                                                        <div
                                                          className={classNames(
                                                            classes.qty,
                                                          )}
                                                        >
                                                          {value.Qty} EA
                                                        </div>
                                                        {value.Name ||
                                                          assembly.Name}
                                                        {value.AddonYN ===
                                                          'Y' && (
                                                          <Typography
                                                            color='secondary'
                                                            className='font-bold text-10 mt-4 ml-6 float-right px-4 rounded bg-grey-darkest'
                                                          >
                                                            ADD-ON
                                                          </Typography>
                                                        )}
                                                        {assembly.Code && (
                                                          <Typography
                                                            color='secondary'
                                                            className='font-bold text-12 mt-4 float-right'
                                                          >
                                                            {assembly.Code}
                                                          </Typography>
                                                        )}
                                                      </Typography>
                                                      <Typography
                                                        className='clear'
                                                        variant='caption'
                                                      >
                                                        {parse(
                                                          value.Description ||
                                                            assembly.Description,
                                                        )}
                                                      </Typography>
                                                      {(value.Detail ||
                                                        assembly.Detail) && (
                                                        <Divider className='my-8' />
                                                      )}
                                                      {(value.Detail ||
                                                        assembly.Detail) && (
                                                        <Typography
                                                          className='clear'
                                                          variant='caption'
                                                        >
                                                          {value.Detail ||
                                                            assembly.Detail}
                                                        </Typography>
                                                      )}
                                                      <Typography className='font-bold text-12 my-12 relative'>
                                                        <Typography className='font-bold text-12 float-right'>
                                                          {this.formatDollars(
                                                            value.Price,
                                                          )}
                                                        </Typography>
                                                      </Typography>
                                                    </div>
                                                  </div>
                                                )
                                              }
                                              if (!assembly) {
                                                var item = value
                                                const {
                                                  ImageID,
                                                  CustomImgURL,
                                                } = item
                                                return (
                                                  <div
                                                    className='prevent-split flex mt-8 pt-6 cursor-pointer'
                                                    style={{
                                                      borderTop:
                                                        '1px solid #f1f1f1',
                                                    }}
                                                  >
                                                    <div className='w-1/6 pt-6 pr-6'>
                                                      <img
                                                        alt={Material}
                                                        crossOrigin='Anonymous'
                                                        className='w-full component-img'
                                                        src={
                                                          CustomImgURL ||
                                                          'assets/images/logos/SP_Cog_Gradient.png'
                                                        }
                                                      />
                                                    </div>
                                                    <div className='w-full'>
                                                      <Typography className='w-full font-bold text-16'>
                                                        <div
                                                          className={classNames(
                                                            classes.qty,
                                                          )}
                                                        >{`${value.Qty} ${value.UOM}`}</div>
                                                        {value.Name ||
                                                          item.Description}
                                                        {value.AddonYN ===
                                                          'Y' && (
                                                          <Typography
                                                            color='secondary'
                                                            className='font-bold text-10 mt-4 ml-6 float-right px-4 rounded bg-grey-darkest'
                                                          >
                                                            ADD-ON
                                                          </Typography>
                                                        )}
                                                        <Typography
                                                          color='secondary'
                                                          className='font-bold text-12 mt-4 float-right'
                                                        >
                                                          {Material}
                                                        </Typography>
                                                      </Typography>
                                                      <Typography
                                                        className='clear'
                                                        variant='caption'
                                                      >
                                                        {parse(
                                                          value.Description ||
                                                            item.Description ||
                                                            '',
                                                        )}
                                                      </Typography>
                                                      <Typography className='font-bold text-12 my-12 relative'>
                                                        <Typography className='font-bold text-12 float-right'>
                                                          {this.formatDollars(
                                                            value.Price,
                                                          )}
                                                        </Typography>
                                                      </Typography>
                                                    </div>
                                                  </div>
                                                )
                                              }
                                            })}
                                          </div>
                                        )
                                      }
                                    </div>
                                  )}
                                {/* {scope.Summary &&
                                                                <div className={classNames("relative pb-12")} style={{ display: 'block', borderBottom: '1px solid lightgrey' }} key={index}>
                                                                    <span className=" w-full"><b className="text-12">Technician Summary:</b></span>
                                                                    <div className="w-full text-12 italic mt-8" style={{ whiteSpace: 'normal' }}>"{scope.Summary}"</div>
                                                                </div>
                                                            } */}
                                {/*scope.Summary && scope.Summary.length > 0 && */}
                                <React.Fragment>
                                  <div
                                    className={classNames(
                                      'w-full justify-between',
                                      !scope.Summary && 'no-print',
                                    )}
                                  >
                                    <span className='text-12 cursor-pointer'>
                                      <b className='text-12'>
                                        <Icon className='no-print mr-6 text-14 align-middle mb-4'>
                                          speaker_notes
                                        </Icon>
                                        Technician Summary:
                                      </b>
                                    </span>
                                    {this.state.editSummary[scope.Scope] && (
                                      <Icon
                                        className='no-print text-20 mr-4 mt-4 float-right cursor-pointer'
                                        color='action'
                                        onClick={() => {
                                          this.state.editSummary[scope.Scope] =
                                            null
                                          this.setState({
                                            ...this.state,
                                            ...this.state.editSummary,
                                          })
                                        }}
                                      >
                                        close
                                      </Icon>
                                    )}
                                  </div>
                                  <div className='w-full mt-6 pr-4'>
                                    {!this.state.editSummary[scope.Scope] &&
                                      !scope.Summary && (
                                        <MenuItem
                                          className='font-bold mb-8 relative py-8 pb-6 text-14 no-print'
                                          style={{
                                            border: '1px dotted lightgrey',
                                            borderRadius: 5,
                                          }}
                                          key={index}
                                          onClick={() => {
                                            if (
                                              !this.state.editSummary[
                                                scope.Scope
                                              ]
                                            ) {
                                              this.state.editSummary[
                                                scope.Scope
                                              ] = _.cloneDeepWith(scope)
                                              this.setState({
                                                ...this.state,
                                                ...this.state.editSummary,
                                              })
                                            }
                                          }}
                                        >
                                          <div className='w-full text-center'>
                                            <Icon
                                              className={classNames(
                                                'no-print cursor-pointer mr-6 text-20 align-middle mb-4',
                                              )}
                                              color='primary'
                                            >
                                              add
                                            </Icon>
                                            Add Summary
                                          </div>
                                        </MenuItem>
                                      )}
                                    {!this.state.editSummary[scope.Scope] &&
                                      scope.Summary && (
                                        <MenuItem
                                          className='mb-8 relative py-8'
                                          style={{
                                            border: '1px dotted lightgrey',
                                            borderRadius: 5,
                                          }}
                                          key={index}
                                          onClick={() => {
                                            if (
                                              !this.state.editSummary[
                                                scope.Scope
                                              ]
                                            ) {
                                              this.state.editSummary[
                                                scope.Scope
                                              ] = _.cloneDeepWith(scope)
                                              this.setState({
                                                ...this.state,
                                                ...this.state.editSummary,
                                              })
                                            }
                                          }}
                                        >
                                          <div
                                            className='text-12 italic'
                                            style={{ whiteSpace: 'normal' }}
                                          >
                                            "{scope.Summary}"
                                          </div>
                                        </MenuItem>
                                      )}
                                    {this.state.editSummary[scope.Scope] && (
                                      <TextField
                                        className='mb-8 no-print'
                                        id='summary'
                                        name={`scope${scope.Scope}Summary`}
                                        value={
                                          (
                                            this.state.editSummary[
                                              scope.Scope
                                            ] || scope
                                          ).Summary || ''
                                        }
                                        onChange={e => {
                                          const editSummary = _.cloneDeepWith(
                                            this.state.editSummary,
                                          )
                                          editSummary[scope.Scope].Summary =
                                            e.target.value
                                          this.setState({
                                            ...this.state,
                                            editSummary,
                                          })
                                        }}
                                        variant='outlined'
                                        multiline
                                        rows={5}
                                        fullWidth
                                        disabled={
                                          !this.state.editSummary[scope.Scope]
                                        }
                                      />
                                    )}
                                    {this.state.editSummary[scope.Scope] && (
                                      <Button
                                        variant='contained'
                                        className='mb-12 mt-4 no-print'
                                        color='primary'
                                        onClick={() => {
                                          this.props.updateWorkOrderScope({
                                            ...scope,
                                            Summary:
                                              this.state.editSummary[
                                                scope.Scope
                                              ].Summary,
                                          })

                                          // Filter for current scope and update Invoice Data
                                          let newState = _.cloneDeepWith(
                                            invoiceDialog.data,
                                          )
                                          const Scopes =
                                            newState.Data.Data.Scopes
                                          let scopeIndex = _.findIndex(
                                            Scopes,
                                            o => {
                                              return o.ID === scope.ID
                                            },
                                          )
                                          newState.Data.Data.Scopes[
                                            scopeIndex
                                          ].Summary =
                                            this.state.editSummary[
                                              scope.Scope
                                            ].Summary
                                          updateInvoiceDialog(newState)

                                          this.state.editSummary[scope.Scope] =
                                            null

                                          this.setState({
                                            ...this.state,
                                            ...this.state.editSummary,
                                          })
                                        }}
                                        fullWidth
                                      >
                                        Update Summary
                                      </Button>
                                    )}
                                  </div>
                                </React.Fragment>
                                {/**/}
                              </div>
                            )
                          }
                        })}
                        {Boolean(inv.ID) &&
                          (_.filter(
                            inv.Data.Scopes,
                            o =>
                              o.ReadyToBillYN === 'Y' &&
                              o.PriceMethod === 'F' &&
                              !o.Invoice,
                          ).length > 0 ||
                            _.filter(
                              inv.Data.Usage,
                              o =>
                                o.Status === 'NEW' &&
                                _.find(
                                  inv.Data.Scopes,
                                  s =>
                                    s.WorkOrder === o.WorkOrder &&
                                    s.Scope === o.Scope &&
                                    s.ReadyToBillYN === 'Y',
                                ),
                            ).length > 0) && (
                            <Button
                              onClick={() => {
                                this.setState({ syncing: true }, () => {
                                  Promise.all([
                                    this.props.syncInvoice({ ...this.state }),
                                  ]).then(() => {
                                    this.setState({ syncing: false })
                                  })
                                })
                              }}
                              variant='contained'
                              color='primary'
                              className='w-full text-center mb-12 no-print'
                            >
                              <Icon
                                className={classNames(
                                  'mr-6 mb-0 align-middle',
                                  this.state.syncing && 'spin',
                                )}
                              >
                                refresh
                              </Icon>
                              SYNC{this.state.syncing ? 'ING' : ''} BILLABLE
                              ITEMS
                            </Button>
                          )}
                        {/*
                                            inv.Data.Scopes && _.filter(inv.Data.Scopes, (scope) => _.filter(billableDetails, (o) => o.Scope === scope.Scope || (o.Data.Usage && o.Data.Usage.Scope === scope.Scope)).length < 1).length > 0 &&
                                            <div className="cursor-pointer w-full no-print mb-36" style={{ marginTop: -8 }}>
                                                {!this.state.addScope ?
                                                    <div onClick={() => { this.setState({ ...this.state, addScope: true }) }} className="w-full border-dotted border-1 rounded-lg border-grey-light py-8 text-center">
                                                        <SPRoundIcon className="text-18 ml-4 align-middle mb-4" color="primary" />
                                                        <strong className="ml-4 text-12 cursor-pointer">Add Scope</strong>
                                                    </div> :
                                                    <div className="w-full border-dotted border-1 rounded-lg bg-grey-lightest border-grey-light p-4">
                                                        <SPRoundIcon className="cursor-pointer text-18 mb-4 align-middle no-print" color="primary" />
                                                        <strong className="ml-4 text-12 cursor-pointer">Additional Scopes</strong>
                                                        <Icon onClick={() => { this.setState({ ...this.state, addScope: false }) }} className="cursor-pointer text-16 align-middle no-print float-right" color="action">close</Icon>
                                                        <div className="w-full min-h-32 border-grey-light border-t-1 pt-12" >
                                                            {
                                                                _.filter(inv.Data.Scopes, (scope) => _.filter(billableDetails, (o) => o.Scope === scope.Scope || (o.Data.Usage && o.Data.Usage.Scope === scope.Scope)).length < 1).map((scope, index) => {
                                                                    const assigned = assignments ? _.find(assignments, { Scope: scope.Scope }) : true;
                                                                    const est = scope && scope.Data && (!scope.Invoice || scope.Invoice === inv.Invoice) && scope.Data.Estimate;
                                                                    let scopeTotal = 0;
                                                                    const billableAmt = (scope.PriceMethod === 'F' ? scope.Price : 0) + _.sumBy(_.filter(inv.Data.Usage, (o) => o.Scope === scope.Scope && o.Status === 'NEW'), (o) => _.sumBy(o.Data.InvoiceLines, 'PriceTotal'));
                                                                    // const leadTech = _.find(technicians, { Technician: scope.LeadTechnician });
                                                                    window["warn"]('Scope Estimate: ', est, scope.Invoice, inv.Invoice, scope, inv);
                                                                    return (
                                                                        <div className="w-full px-8">
                                                                            <div className="w-full flex justify-between">
                                                                                <Chip
                                                                                    classes={{ avatar: classes.chipAvatar, label: classes.chipLabel, }}
                                                                                    avatar={<Avatar src="assets/images/logos/SP_Cog_Gradient.png" />}
                                                                                    label={`${_.findLast(this.props.services, (n) => { return n.Service === scope.Service }).Description} - ${this.formatDollars(billableAmt)}`}
                                                                                    // onClick={handleClick}
                                                                                    // color="primary"
                                                                                    className={classes.chip}
                                                                                />
                                                                                <Button
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    className="h-24 text-12"
                                                                                    onClick={() => { this.setState({ ...this.state, addScope: false }) }}
                                                                                >
                                                                                    Add
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            */}
                      </div>

                      <div className='w-full' style={{ marginBottom: -20 }}>
                        <div className='w-full flex justify-end rounded-full'>
                          <div
                            className={classNames(
                              'w-auto rounded-full px-24 text-12 font-bold text-right flex justify-between',
                            )}
                          >
                            <div className='text-12 mt-4 mr-24 font-bold'>
                              Subtotal:
                            </div>
                            <div className='text-16 min-w-96 font-bold'>
                              {this.formatDollars(sub)}
                            </div>
                          </div>
                        </div>
                        <div className='w-full flex justify-end rounded-full'>
                          <div
                            className={classNames(
                              'w-auto rounded-full px-24 text-12 font-bold text-right flex justify-between',
                            )}
                          >
                            <div className='text-12 mt-4 mr-24 font-bold'>
                              Tax:
                            </div>
                            <div className='text-16 min-w-96 font-bold'>
                              {this.formatDollars(tax)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex my-24'>
                        <div className='w-full'>
                          <div
                            className='w-full flex justify-between rounded-full'
                            style={{ backgroundColor: '#3f3f3f' }}
                          >
                            <DatePicker
                              inputVariant='outlined'
                              onOpen={() => this.setDueDatepickerOpen(true)}
                              onClose={() => this.setDueDatepickerOpen(false)}
                              open={this.state.isDueDatepickerOpen}
                              label='Due Date'
                              inputProps={{
                                style: {
                                  padding: 12,
                                },
                              }}
                              TextFieldComponent={() => ''}
                              minDate={this.state.InvoiceDate}
                              value={this.state.DueDate}
                              onChange={this.handleDueDateChange}
                              showTodayButton
                              autoOk
                            />
                            <div
                              className={classNames(
                                'w-2/3 py-8 pl-24 text-12 font-bold cursor-pointer truncate',
                                classes.dividerPlain,
                              )}
                              onClick={this.openDueDatePicker}
                            >
                              DUE BY{' '}
                              {moment(this.state.DueDate).format(
                                'MMM DD, YYYY',
                              )}
                            </div>
                            <div
                              className={classNames(
                                'w-auto rounded-full px-24 text-12 font-bold text-right flex justify-between',
                                classes.dividerHighlight,
                              )}
                            >
                              <div className='text-12 mt-8 mr-24 font-bold'>
                                Total:
                              </div>
                              <div className='text-20 min-w-96 font-bold'>
                                {this.formatDollars(total)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='no-break'>
                        <div className='m-24 pt-0 sm:flex'>
                          <div className='w-full mr-16'>
                            <strong style={{ marginRight: 4 }}>Notes:</strong>
                            <Icon
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  EditNotes: true,
                                })
                              }}
                              className='cursor-pointer text-16 ml-4 no-print align-middle'
                              style={{ marginTop: -6 }}
                            >
                              edit
                            </Icon>
                            <br />
                            {this.state.Notes}
                            {this.state.EditNotes && (
                              <TextField
                                className='mt-6'
                                label='Notes'
                                id='notes'
                                name='Notes'
                                value={this.state.Notes}
                                onChange={this.handleChange}
                                onBlur={() => {
                                  this.setState(
                                    { ...this.state, EditNotes: false },
                                    () => {
                                      const { InvoiceNumber, Notes } =
                                        this.state
                                      this.setNotes(InvoiceNumber, Notes)
                                    },
                                  )
                                }}
                                variant='outlined'
                                multiline
                                rows={5}
                                autoFocus
                                fullWidth
                              />
                            )}
                          </div>
                          <div className='w-full sm:w-2/3 mt-24 sm:mt-0'>
                            <div className='w-full mb-6'>
                              <span>
                                <strong style={{ marginRight: 4 }}>
                                  Customer Signature:
                                </strong>
                                {this.state.SignedBy}
                                <Icon
                                  onClick={() => {
                                    this.setState({
                                      ...this.state,
                                      EditSignedBy: true,
                                    })
                                  }}
                                  className='cursor-pointer text-16 ml-4 no-print align-middle'
                                  style={{ marginTop: -6 }}
                                >
                                  edit
                                </Icon>
                              </span>
                              <br />
                              {!this.state.isSigning &&
                                this.state.EditSignedBy && (
                                  <TextField
                                    className={classes.formControl}
                                    label='Signed By'
                                    id='signedBy'
                                    name='SignedBy'
                                    value={this.state.SignedBy}
                                    onChange={this.handleChange}
                                    onBlur={() => {
                                      this.setState({
                                        ...this.state,
                                        EditSignedBy: false,
                                      })
                                    }}
                                    variant='outlined'
                                    autoFocus
                                    fullWidth
                                  />
                                )}
                            </div>
                            <div
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  isSigning: true,
                                })
                              }}
                              style={{
                                border: '1px dotted #3f3f3f',
                                width: 'calc(100% - 2px)',
                                height: 96,
                                borderRadius: 5,
                              }}
                            >
                              {this.state.Signature && (
                                <img
                                  style={{ height: 96 }}
                                  src={this.state.Signature}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='m-24 mt-0'>
                          <div className={classNames('w-full mb-12')}>
                            <InvoiceAddPaymentComponent
                              due={due}
                              readOnly={readOnly}
                              isFinanced={this.state.IsFinanced}
                              isApprovedAccount={this.state.IsApprovedAccount}
                              onIsFinancedChange={() =>
                                this.setState(prevState => ({
                                  ...prevState,
                                  IsFinanced: !prevState.IsFinanced,
                                  IsApprovedAccount: false,
                                }))
                              }
                              onIsApprovedAccountChange={() =>
                                this.setState(prevState => ({
                                  ...prevState,
                                  IsApprovedAccount:
                                    !prevState.IsApprovedAccount,
                                  IsFinanced: false,
                                }))
                              }
                              onAddPaymentClick={() => {
                                if (
                                  !this.state.IsFinanced &&
                                  !this.state.IsApprovedAccount
                                ) {
                                  this.setState({
                                    ...this.state,
                                    addPayment: true,
                                  })
                                }
                              }}
                            />
                            <div></div>
                            {pmts.map(pmt => {
                              const voided =
                                Boolean(pmt.VoidDate) ||
                                (pmt.Data &&
                                  pmt.Data.Payment &&
                                  Boolean(pmt.Data.Payment.VoidDate))
                              const { hideVoidedPayments } = this.state
                              {
                                if (!voided || !hideVoidedPayments) {
                                  return (
                                    <div
                                      onClick={() =>
                                        this.setState({
                                          viewPayment: !readOnly ? pmt : null,
                                        })
                                      }
                                      className='w-full flex pt-8 pb-8 text-11 font-bold relative cursor-pointer'
                                    >
                                      <div className='mr-16'>
                                        {new Date(
                                          pmt.PaymentDateTime,
                                        ).toLocaleDateString('en-US')}
                                      </div>
                                      {pmt.Check ? (
                                        <React.Fragment>
                                          <div
                                            className={classNames(
                                              voided
                                                ? 'mr-6 line-through'
                                                : 'mr-16',
                                            )}
                                          >{`Check #${pmt.Check}`}</div>
                                        </React.Fragment>
                                      ) : pmt.CardLastFour ? (
                                        <React.Fragment>
                                          <img
                                            src={`assets/images/cards/${pmt.PaymentMethodType}.png`}
                                            className='w-20 mr-4'
                                            style={{ height: 14 }}
                                          />
                                          <div
                                            className={classNames(
                                              voided
                                                ? 'mr-6 line-through'
                                                : 'mr-16',
                                            )}
                                          >{`${
                                            paymentTypes[pmt.PaymentMethodType]
                                          } ending in ${
                                            pmt.CardLastFour
                                          }`}</div>
                                        </React.Fragment>
                                      ) : pmt.Data &&
                                        pmt.Data.PaymentMethod &&
                                        pmt.Data.PaymentMethod.CardLastFour ? (
                                        <React.Fragment>
                                          <img
                                            src={`assets/images/cards/${pmt.Data.PaymentMethod.PaymentMethodType}.png`}
                                            className='w-20 mr-4'
                                            style={{ height: 14 }}
                                          />
                                          <div
                                            className={classNames(
                                              voided
                                                ? 'mr-6 line-through'
                                                : 'mr-16',
                                            )}
                                          >{`${
                                            paymentTypes[
                                              pmt.Data.PaymentMethod
                                                .PaymentMethodType
                                            ]
                                          } ending in ${
                                            pmt.Data.PaymentMethod.CardLastFour
                                          }`}</div>
                                        </React.Fragment>
                                      ) : pmt.Data &&
                                        pmt.Data.Payment &&
                                        pmt.Data.Payment.PaymentType ===
                                          'CASH' ? (
                                        <React.Fragment>
                                          <div
                                            className={classNames(
                                              voided
                                                ? 'mr-6 line-through'
                                                : 'mr-16',
                                            )}
                                          >{`CASH${
                                            pmt.Memo ? ` - ${pmt.Memo}` : ''
                                          }`}</div>
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          <div
                                            className={classNames(
                                              voided
                                                ? 'mr-6 line-through'
                                                : 'mr-16',
                                            )}
                                          >{`OTHER${
                                            pmt.Memo ? ` - ${pmt.Memo}` : ''
                                          }`}</div>
                                        </React.Fragment>
                                      )}
                                      {voided ? (
                                        <div className={classNames('mr-16')}>
                                          {' '}
                                          - VOIDED
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                      <div
                                        className={classNames(
                                          'pin-r absolute',
                                          voided ? 'line-through' : '',
                                        )}
                                      >{`${this.formatDollars(
                                        _.sumBy(
                                          _.filter(
                                            pmt.Data.Allocations ||
                                              pmt.Data.Payment.Data.Allocations,
                                            o =>
                                              !o.VoidDate &&
                                              o.Co === inv.Co &&
                                              o.Invoice ===
                                                (inv.Invoice || o.Invoice) &&
                                              o.InvoiceNumber ===
                                                inv.InvoiceNumber,
                                          ),
                                          'Amount',
                                        ) /*pmt.Amount || pmt.PaymentTotal || 0*/,
                                      )}`}</div>
                                    </div>
                                  )
                                }
                              }
                            })}

                            <div
                              className='w-full'
                              style={{ marginBottom: -20 }}
                            >
                              <div className='w-full flex justify-end rounded-full'>
                                <div
                                  className={classNames(
                                    'w-auto rounded-full text-12 font-bold text-right flex justify-between',
                                  )}
                                >
                                  <div className='text-12 mt-4 mr-24 font-bold'>
                                    Total Paid:
                                  </div>
                                  <div className='text-12 mt-4 min-w-96 font-bold'>
                                    {this.formatDollars(total - due)}
                                  </div>
                                </div>
                              </div>
                              <div className='w-full flex justify-end rounded-full'>
                                <div
                                  className={classNames(
                                    'w-auto rounded-full text-12 px-12 font-bold text-right flex justify-between text-white py-4',
                                  )}
                                  style={{
                                    background: '#3f3f3f',
                                    marginRight: -12,
                                  }}
                                >
                                  <div className='text-12 mr-24 font-bold'>
                                    Balance Due:
                                  </div>
                                  <div
                                    className={classNames(
                                      'text-12 min-w-96 font-bold',
                                    )}
                                  >
                                    {due > 0
                                      ? this.formatDollars(due)
                                      : '$0.00'}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {this.state.addPayment && (
                              <InvoicePayment
                                open={this.state.addPayment}
                                data={{ ...this.state, total: due }}
                                paymentMethods={inv.Data.PaymentMethods}
                                onPayment={this.handlePayment}
                                onClose={() => {
                                  this.setState({
                                    ...this.state,
                                    addPayment: false,
                                  })
                                }}
                              />
                            )}
                            {this.state.viewPayment && (
                              <InvoicePaymentRecord
                                open={this.state.viewPayment}
                                data={{ ...this.state }}
                                payment={{ ...this.state.viewPayment }}
                                onVoid={this.handleVoidPayment}
                                onClose={() => {
                                  this.setState({
                                    ...this.state,
                                    viewPayment: false,
                                  })
                                }}
                              />
                            )}
                          </div>
                        </div>
                        <div className='w-full h-auto'>
                          {division && division.InvoiceDisclaimer && (
                            <div className='mx-24 mb-12'>
                              <strong style={{ marginRight: 4 }}>
                                Terms and Conditions:
                              </strong>
                              <div
                                className='font-bold text-red w-full mt-6'
                                style={{ fontSize: 9 }}
                              >
                                {division.InvoiceDisclaimer}
                              </div>
                            </div>
                          )}
                          <div className='mx-24'>
                            <div className='w-full sm:flex sm:justify-between'>
                              <div className='mt-24'>
                                <div className='w-full flex'>
                                  <img
                                    src='assets/icons/location_icon.png'
                                    className='w-32 h-32 mr-8'
                                  />
                                  <div className='w-full'>
                                    <div className='text-14 font-bold'>
                                      {co.Name}
                                    </div>
                                    <div className='text-10'>{co.Address}</div>
                                    <div className='text-10'>
                                      {`${co.City}, ${co.State} ${co.Zip}`}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='mt-24'>
                                <div className='w-full flex'>
                                  <img
                                    src='assets/icons/email_icon.png'
                                    className='w-32 h-32 mr-8'
                                  />
                                  <div className='w-full'>
                                    <div className='text-14 font-bold'>
                                      Email
                                    </div>
                                    <div className='text-10'>{co.Email}</div>
                                  </div>
                                </div>
                              </div>
                              <div className='mt-24'>
                                <div className='w-full flex'>
                                  <img
                                    src='assets/icons/phone_icon.png'
                                    className='w-32 h-32 mr-8'
                                  />
                                  <div className='w-full'>
                                    <div className='text-14 font-bold'>
                                      Phone
                                    </div>
                                    <div className='text-10'>
                                      {this.formatPhone(co.Phone)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Dialog
                        open={this.state.isSigning}
                        classes={{ paper: classes.signaturePaper }}
                        fullWidth
                        maxWidth='sm'
                        onClose={() => {
                          if (this.sigPad.isEmpty()) {
                            this.setState({ ...this.state, isSigning: false })
                          }
                        }}
                      >
                        <div className='w-full mb-12'>
                          <span>
                            <strong style={{ marginRight: 4 }}>
                              Customer Signature:
                            </strong>
                            {this.state.SignedBy}
                            <Icon
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  EditSignedBy: true,
                                })
                              }}
                              className='cursor-pointer text-16 ml-4 no-print align-middle'
                              style={{ marginTop: -6 }}
                            >
                              edit
                            </Icon>
                          </span>
                          <br />
                          {this.state.isSigning && this.state.EditSignedBy && (
                            <TextField
                              className={classes.formControl}
                              label='Signed By'
                              id='signedBy'
                              name='SignedBy'
                              value={this.state.SignedBy}
                              onChange={this.handleChange}
                              onBlur={() => {
                                this.setState({
                                  ...this.state,
                                  EditSignedBy: false,
                                })
                              }}
                              variant='outlined'
                              autoFocus
                              fullWidth
                            />
                          )}
                        </div>
                        <div
                          className='w-full'
                          style={{
                            border: '1px dotted #3f3f3f',
                            borderRadius: 5,
                          }}
                        >
                          <SignatureCanvas
                            ref={ref => {
                              this.sigPad = ref
                            }}
                            penColor='#66aef9'
                            canvasProps={{
                              className: 'sigCanvas',
                              style: { width: '100%', height: 150 },
                            }}
                          />
                        </div>
                        <div className='flex w-full mt-16'>
                          <Button
                            variant='contained'
                            className={classes.clearBtn}
                            fullWidth
                            onClick={this.clear}
                          >
                            Clear
                          </Button>
                          <div className='min-w-12'></div>
                          <Button
                            variant='contained'
                            className={classes.okBtn}
                            fullWidth
                            onClick={this.trim}
                          >
                            OK
                          </Button>
                        </div>
                      </Dialog>
                      {/*division && division.InvoiceFooterImg &&
                                        <img className="w-full" src={division.InvoiceFooterImg} />
                                    */}
                    </div>
                  </>
                )}
              </DialogContent>

              {trip && trip.Status < 8 && (
                <React.Fragment>
                  <ConfirmationDialog
                    open={this.state.confirm && !invoiceEmail}
                    title={
                      <div>
                        <Icon className='mr-6 align-middle mb-4'>
                          check_circle
                        </Icon>
                        All Done?
                      </div>
                    }
                    content={
                      <div className='w-full pt-8 pb-8'>{`It looks like you might be done with this visit. Would you like to go ahead and mark Trip #${trip.Trip} as completed?`}</div>
                    }
                    confirmText='Mark as Completed'
                    cancelText='No Thanks'
                    onCancel={() =>
                      this.setState({ ...this.state, confirm: false }, () =>
                        this.closeComposeDialog(),
                      )
                    }
                    onConfirm={() =>
                      this.setState(
                        { ...this.state, confirm: false, labor: true },
                        () => {
                          this.props.updateTrip({
                            ...trip,
                            Data: { ErrMsg: null },
                            Status: 8,
                          })
                        },
                      )
                    }
                  />
                  <TimecardLaborDialog
                    date={moment(
                      trip.InProgressDate ||
                        trip.EnRouteDate ||
                        trip.ScheduledDate,
                    ).format('M/D/YYYY')}
                    trips={[trip]}
                    employee={{
                      Co: trip.Data.Technician.Co,
                      Employee: trip.Data.Technician.Employee,
                    }}
                    open={this.state.labor}
                    source={'technician'}
                    title={
                      <div>
                        <Icon className='mr-6 align-middle mb-4'>
                          access_time
                        </Icon>
                        Add to Timecard?
                      </div>
                    }
                    message={
                      <div className='w-full pt-8 pb-8'>{`Would you like to add labor for Work Order #${trip.WorkOrder} to your timecard?`}</div>
                    }
                    data={{
                      Co: trip.Co,
                      Employee: trip.Data.Technician.Employee,
                      Date: moment(
                        trip.InProgressDate ||
                          trip.EnRouteDate ||
                          trip.ScheduledDate,
                      ).format('M/D/YYYY'),
                      Type: 'W',
                      WorkOrder: trip.WorkOrder,
                      Scope: trip.Data.Assignments[0].Scope,
                      Qty: (trip.ActualDuration || 1).toFixed(2),
                    }}
                    onClose={() =>
                      this.setState({ ...this.state, labor: false }, () =>
                        this.closeComposeDialog(),
                      )
                    }
                  />
                </React.Fragment>
              )}

              {!this.state.ID && !this.state.OfflineID ? (
                <DialogActions className='dialog-actions justify-between pl-16'>
                  <DebounceButton
                    buttonText={'Finish'}
                    processingText={'Finishing'}
                    isDisabled={!this.canBeSubmitted()}
                    variant={'contained'}
                    color={'primary'}
                    clickFxn={() => {
                      // window["warn"](`Accept Payment for $${due}?`);
                      if (
                        due &&
                        due > 0 &&
                        (!inv.Data.Payments || inv.Data.Payments.length < 1) &&
                        billCust.ApprovedAccountYN !== 'Y' &&
                        !this.state.IsFinanced &&
                        !this.state.IsApprovedAccount
                      ) {
                        this.setState({ ...this.state, promptPayment: true })
                      } else {
                        if (agreement) {
                          Promise.all([
                            addAgreementInvoice({
                              ...this.state,
                              Data: {
                                ...this.state.Data,
                                PaymentDetail: inv.Data.PaymentDetail,
                              },
                            }),
                          ])
                        } else {
                          Promise.all([
                            addInvoice({
                              ...this.state,
                              Data: {
                                ...this.state.Data,
                                PaymentDetail: inv.Data.PaymentDetail,
                              },
                            }),
                          ])
                        }
                      }
                    }}
                    debounceInterval={1000}
                  />
                  <ConfirmationDialog
                    open={this.state.promptPayment}
                    title={
                      <div>
                        <Icon className='mr-6 align-middle mb-4'>payment</Icon>
                        Collect Payment?
                      </div>
                    }
                    content={
                      <div className='w-full pt-8 pb-8'>{`Before you submit Invoice #${inv.InvoiceNumber}, would you like to add a payment?`}</div>
                    }
                    confirmText='Yes'
                    cancelText='No'
                    onCancel={() =>
                      this.setState(
                        { ...this.state, promptPayment: false },
                        () => {
                          if (agreement) {
                            addAgreementInvoice({
                              ...this.state,
                              Data: {
                                ...this.state.Data,
                                PaymentDetail: inv.Data.PaymentDetail,
                              },
                            })
                          } else {
                            addInvoice({
                              ...this.state,
                              Data: {
                                ...this.state.Data,
                                PaymentDetail: inv.Data.PaymentDetail,
                              },
                            })
                          }
                        },
                      )
                    }
                    onConfirm={() =>
                      this.setState({
                        ...this.state,
                        promptPayment: false,
                        addPayment: true,
                      })
                    }
                  />
                </DialogActions>
              ) : (
                !readOnly &&
                this.state.ID &&
                accessLevel &&
                accessLevel.AccessLevel !== 'R' &&
                (this.state.Status < 2 ||
                  (accessLevel && accessLevel.AccessLevel === 'F')) && (
                  <DialogActions className='dialog-actions justify-between pl-16'>
                    <div className='flex'>
                      <DebounceButton
                        buttonText={'Save'}
                        processingText={'Saving'}
                        isDisabled={!this.canBeSubmitted()}
                        variant={'contained'}
                        color={'primary'}
                        clickFxn={() => {
                          updateInvoice(this.state)
                          // this.closeComposeDialog();
                        }}
                        debounceInterval={1000}
                      />
                      {this.state.ID && (
                        <React.Fragment>
                          <div className='min-w-12'></div>
                          {accessLevel &&
                            accessLevel.AccessLevel === 'F' &&
                            this.state.Status < 2 && (
                              <DebounceButton
                                buttonText={'Post'}
                                processingText={'Posting'}
                                isDisabled={!this.canBeSubmitted()}
                                variant={'contained'}
                                color={'primary'}
                                clickFxn={() => {
                                  updateInvoice({ ...this.state, Status: 2 })
                                  this.closeComposeDialog()
                                }}
                                debounceInterval={1000}
                              />
                            )}
                        </React.Fragment>
                      )}
                    </div>
                    <IconButton
                      onClick={() => {
                        this.setState({ ...this.state, confirmDelete: true })
                      }}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                    <ConfirmationDialog
                      open={this.state.confirmDelete}
                      title={
                        <div>
                          <Icon className='mr-6 align-middle mb-4'>
                            check_circle
                          </Icon>
                          Are You Sure?
                        </div>
                      }
                      content={
                        <div className='w-full pt-8 pb-8'>{`Are you sure you wish to delete Invoice #${inv.InvoiceNumber}? This action cannot be undone.`}</div>
                      }
                      confirmText='Delete Invoice'
                      cancelText='Cancel'
                      onCancel={() =>
                        this.setState({ ...this.state, confirmDelete: false })
                      }
                      onConfirm={() =>
                        this.setState(
                          { ...this.state, confirmDelete: false },
                          () => {
                            removeInvoice(this.state)
                            this.closeComposeDialog()
                          },
                        )
                      }
                    />
                  </DialogActions>
                )
              )}
            </DraggableDialog>
          </React.Fragment>
        )}
      </Media>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      refreshEditInvoiceDialog: Actions.refreshEditInvoiceDialog,
      closeEditInvoiceDialog: Actions.closeEditInvoiceDialog,
      closeNewInvoiceDialog: Actions.closeNewInvoiceDialog,
      addInvoice: Actions.addInvoice,
      addAgreementInvoice: Actions.addAgreementInvoice,
      updateInvoice: Actions.updateInvoice,
      updateInvoiceDialog: Actions.updateEditInvoiceDialog,
      openEditInvoiceDialog: Actions.openEditInvoiceDialog,
      syncInvoice: Actions.syncInvoice,
      removeInvoice: Actions.removeInvoice,
      openEditCustomerSiteDialog,
      openEditWorkOrderDialog,
      openEditWorkOrderUsageDialog,
      sendInvoice: Actions.sendInvoice,
      updateNotes: updateInvoiceNotes,
      updateInvoiceDetail: Actions.updateInvoiceDetail,
      removeInvoiceDetail: Actions.removeInvoiceDetail,
      updateWorkOrderScope: WorkOrderScopeActions.updateWorkOrderScope,
      showMessage: showMessage,
      updateTrip,
      openEditCustomerDialog,
      openEditEstimateDialog,
      openMinimizedDialog,
      openPreloadWorkInvoiceDialog,
    },
    dispatch,
  )
}

function mapStateToProps({
  invoicesApp,
  workOrdersApp,
  spReducers,
  emailTemplateApp,
  minimizedDialogApp,
  estimatesApp,
}) {
  return {
    invoiceDialog: invoicesApp.invoices.invoiceDialog,
    workOrderDialog: workOrdersApp.workOrders.workOrderDialog,
    companies: spReducers.companies.List,
    Co: spReducers.companies.Co,
    businessUnits: spReducers.businessUnits,
    divisions: spReducers.divisions,
    technicians: spReducers.technicians,
    templates: emailTemplateApp.templates.Templates,
    formOfPayments: spReducers.formOfPayments,
    User: spReducers.userProfiles.User,
    Users: spReducers.userProfiles.Users,
    services: spReducers.services,
    securables: spReducers.userProfiles.User.Data.Securables,
    users: spReducers.userProfiles.Users,
    notes: spReducers.invoices.notes,
    serviceUnitTypes: spReducers.serviceUnitTypes,
    materials: spReducers.materials.materials,
    laborTypes: spReducers.laborTypes,
    taxCodes: spReducers.taxCodes,
    minimizedDialogs: minimizedDialogApp.minimizedDialog.data,
    estimates: estimatesApp,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(InvoiceDialog),
)
