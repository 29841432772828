import React, { Component } from 'react';
import { matchRoutes } from 'react-router-config';
import DocumentTitle from 'react-document-title';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { App } from '@capacitor/app';
import { showMessage, updateUserPresence } from 'store/actions';
import axios from 'axios';
import _ from '@lodash';

let redirect = false;

let defaultTitle = "Service Point Pro - Revolutionizing Field Service Productivity";

let title = defaultTitle

class FuseAuthorization extends Component {

    constructor(props) {
        super(props);
        this.checkAuth();
    }

    componentWillMount() {
        console.log('App Listener: ', App, this.props.history);
        App.addListener('appUrlOpen', (event) => {
            console.log('Received App Link: ', event.url);
            const slug = event.url.split('.com').pop();
            console.log('Received App Link Slug: ', slug);
            if (slug) {
                this.props.history.push({ pathname: slug });
            }
            // If no match, do nothing - let regular routing
            // logic take over
        });
        App.addListener('appStateChange', (state) => {
            if (this.props.loggedIn) {
                if (!state.isActive) {
                    const { user } = this.props;
                    if (user && user.UserName) {
                        const data = {
                            UserName: user.UserName,
                            Status: 1
                        };
                        if (data.UserName) {
                            const request = axios.put(`${window["apiLocation"]}/api/UserStatus?UserName=${encodeURIComponent(data.UserName)}`, data);

                            request.then((response) => {
                                this.props.updateUserPresence(data);
                            });
                        }
                    }
                } else {
                    const { user } = this.props;
                    if (user && user.UserName) {
                        const data = {
                            UserName: user.UserName,
                            Status: 2
                        };
                        if (data.UserName) {
                            const request = axios.put(`${window["apiLocation"]}/api/UserStatus?UserName=${encodeURIComponent(data.UserName)}`, data);

                            request.then((response) => {
                                this.props.updateUserPresence(data);
                            }).catch((e) => {
                                this.props.showMessage({
                                    message: `Unable to resume session.`,
                                    autoHideDuration: 5000,
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    },
                                    variant: 'error'
                                });
                            });
                        }
                    }
                }
            }
            console.warn('App State Changed', state);
        });
    }

    componentDidUpdate(prevProps) {
        /**
         * If route is changed
         * Update auths
         */
        if (this.props.user && this.props.user.Data) {
            if (!_.isEqual(this.props.user, prevProps.user) || !_.isEqual(this.props.userRoles, prevProps.userRoles) || !_.isEqual(this.props.Co, prevProps.Co) || !_.isEqual(this.props.location.pathname, prevProps.location.pathname)) {
                this.checkAuth();
            }
        }
    }

    getSecurable = (url) => {
        const { Co, user, userRoles, pages } = this.props;
        if (Co && user && user.Data && userRoles && pages) {
            let id = null;
            let isSecurable = false;
            pages.map((page) => {
                if (url.indexOf(page.url) > -1) {
                    id = page.id;
                    title = page.title;
                    isSecurable = page.securables;
                } else {
                    title = defaultTitle;
                }
            });
            const roles = _.filter(userRoles, (o) => { return _.findIndex(user.Data.Roles, { Co, Role: o.Role }) > -1 });
            const securables = [];
            roles.map((value) => {
                value.Data.Securables.map((sec) => {
                    securables.push(sec.Securable);
                });
            });
            return !isSecurable ? true : (securables.indexOf(id) > -1);
        }
    }

    checkAuth = () => {
        const { Co, user, userRoles, } = this.props;
        const matched = matchRoutes(this.props.routes, this.props.location.pathname)[0];
        if (matched) {
            const hasAccess = this.getSecurable(matched.route.path);
            if (!hasAccess) {
                if (!Co || userRoles.length < 1 || user.Data.Roles.length < 1) {
                    redirect = true;
                } else {
                    redirect = true;
                    this.props.history.push({
                        pathname: '/'
                    });
                }
            }
        }
    }

    shouldComponentUpdate(nextProps) {
        if (redirect) {
            redirect = false;
            return false;
        }
        else {
            return true;
        }
    }

    render() {
        const { children } = this.props;

        return (
            <React.Fragment>
                {children}
                <DocumentTitle title={title} />
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateUserPresence,
        showMessage,
    }, dispatch);
}

function mapStateToProps({ fuse, auth, spReducers }) {
    return {
        Co: spReducers.companies.Co,
        user: spReducers.userProfiles.User,
        userRoles: spReducers.userRoles,
        pages: spReducers.userProfiles.Pages,
        loggedIn: auth.login.success,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FuseAuthorization));

// import React, { Component } from 'react';
// import { matchRoutes } from 'react-router-config';
// import { bindActionCreators } from 'redux';
// import { withRouter } from 'react-router-dom';
// import { connect } from 'react-redux';
// import _ from '@lodash';

// let redirect = false;

// class FuseAuthorization extends Component {

//     constructor(props) {
//         super(props);
//         this.checkAuth();
//     }

//     componentDidUpdate(prevProps) {
//         /**
//          * If route is changed
//          * Update auths
//          */
//         if (!_.isEqual(this.props.user, prevProps.user) || !_.isEqual(this.props.userRoles, prevProps.userRoles) || !_.isEqual(this.props.Co, prevProps.Co) || !_.isEqual(this.props.pages, prevProps.pages) || !_.isEqual(this.props.location.pathname, prevProps.location.pathname)) {
//             this.checkAuth();
//         }
//     }

//     getSecurable = (url) => {
//         const { pages } = this.props;
//         let access = null;
//         if (pages) {
//             pages.map((page) => {
//                 if (url.indexOf(page.url) > -1) {
//                     access = page;
//                 }
//             });
//         }
//         return access;
//     }

//     checkAuth = () => {
//         const { Co, user, userRoles, } = this.props;
//         const matched = matchRoutes(this.props.routes, this.props.location.pathname)[0];
//         if (matched) {
//             const hasAccess = this.getSecurable(matched.route.path);
//             if (!hasAccess) {
//                 if (!Co || userRoles.length < 1 || user.Data.Roles.length < 1) {
//                     redirect = true;
//                 } else {
//                     redirect = true;
//                     this.props.history.push({
//                         pathname: '/'
//                     });
//                 }
//             }
//         }
//     }

//     shouldComponentUpdate(nextProps) {
//         if (redirect) {
//             redirect = false;
//             return false;
//         }
//         else {
//             return true;
//         }
//     }

//     render() {
//         const { children } = this.props;

//         return (
//             <React.Fragment>
//                 {children}
//             </React.Fragment>
//         );
//     }
// }

// function mapDispatchToProps(dispatch) {
//     return bindActionCreators({}, dispatch);
// }

// function mapStateToProps({ fuse, auth, spReducers }) {
//     return {
//         Co: spReducers.companies.Co,
//         user: spReducers.userProfiles.User,
//         userRoles: spReducers.userRoles,
//         pages: spReducers.userProfiles.User.Data.Pages,
//     }
// }

// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FuseAuthorization));
