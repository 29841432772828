import _ from '@lodash'
import {
  AppBar,
  Avatar,
  Badge,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  FormControlLabel,
  Icon,
  IconButton,
  InputAdornment,
  MenuItem,
  Switch,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles/index'
import {
  AccountCircle,
  Assignment,
  AttachFile,
  List as ListIcon,
  LocalShipping,
  Payment,
  Receipt,
} from '@material-ui/icons'
import { DatePicker } from '@material-ui/pickers'
import axios from 'axios'
import classNames from 'classnames'
import FileList from 'main/content/apps/file-manager/FileList'
import { impress } from 'main/content/compression/impress'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Media from 'react-media'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import MaskedInput from 'react-text-mask'
import { bindActionCreators } from 'redux'
import { showMessage } from 'store/actions'
import Autocomplete from '../../components/autocomplete/Autocomplete'
import PhoneInput from '../../components/inputs/PhoneInput'
import * as WorkOrderActions from '../work-orders/store/actions'
import * as Actions from './store/actions'
import DraggableDialog from '../draggable-dialog/DraggableDialog'
import { openPreloadEmployeeDialog } from './store/actions/employees.actions'
import getProfileImage from '../../../functions/getProfileImageUrl'

function TabContainer(props) {
  return (
    <Typography
      ref={props.refProp}
      component='div'
      style={{
        padding: props.padding ? props.padding : 8 * 3,
        maxHeight: 'calc(100% - 144px)',
        overflow: 'auto',
      }}
    >
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

const drawerWidth = 240

const googleMapsApiKey = process.env.REACT_APP_MAP_KEY

const styles = theme => ({
  root: {},
  paper: {
    margin: 12,
    minHeight: 'calc(100% - 64px)',
  },
  addButton: {
    float: 'right',
    width: 30,
    height: 30,
    minHeight: 0,
  },
  userAvatar: {
    backgroundColor: '#555555',
    fontSize: 16,
    color: '#fff',
    width: 32,
    height: 32,
    marginTop: -6,
    marginBottom: -6,
  },
  status: {
    marginBottom: 24,
    maxWidth: 100,
  },
  newWOButton: {
    float: 'right',
    fontSize: 'x-small',
    width: '100%',
    fontWeight: 'bold',
    padding: '15px',
    marginBottom: 24,
  },
  streetView: {
    borderRadius: '5px',
  },
  histTable: {
    marginLeft: theme.spacing(7) + 1,
  },
  formControl: {
    marginBottom: 24,
  },
  hist: {
    display: 'flex',
    position: 'relative',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#fafafa',
    color: '#333333',
    paddingLeft: 12,
    fontWeight: 'bold',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  header: {},
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    position: 'absolute',
    height: '100%',
  },
  shelf: {
    position: 'relative',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: '55px',
  },
  content: {
    width: '100%',
    minHeight: '300px',
  },
  profile: {
    backgroundImage: 'url("assets/images/backgrounds/SP_Header.png")',
    backgroundColor: 'rgba(0,0,0,.75)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
    borderRadius: 5,
    paddingTop: 64,
    marginBottom: 16,
  },
})
const newEmployeeState = {
  ID: null,
  avatar: 'assets/images/avatars/profile.jpg',
  Co: null,
  Employee: null,
  FirstName: null,
  LastName: null,
  MiddleInitial: null,
  Suffix: null,
  Gender: null,
  DOB: null,
  SSN: 'N/A',
  UserName: null,
  Phone: null,
  CellPhone: null,
  DefaultBusinessUnit: null,
  DefaultDivision: null,
  DefaultDepartment: null,
  Email: '',
  HireDate: new Date().toLocaleDateString('en-US'),
  TermDate: null,
  SalaryYN: null,
  HourlyAmt: null,
  SalaryAmt: null,
  WeeklyHrs: 40,
  EmailPayStubYN: 'N',
  ExemptYN: null,
  AttachmentID: null,
  Notes: null,
  ActiveYN: 'Y',
  VPCompany: null,
  VPEmployee: null,
  value: 0,
  histTab: 0,
  showPassword: true,
  Data: {},
  QBOCompany: null,
  QBOEmployee: null,
  DefaultReferralBonus: null,
  DefaultReferralBonusType: null,
  isSearching: false,
  searchDescription: null,
  vpEmployees: [],
  dialogHeight: null,
  minHeight: null,
  location: null,
}

function FormatDollars(props) {
  const { inputRef, onChange, name, type, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: name,
            type: type,
            value: values.value,
          },
        })
      }}
      type={type}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
      prefix='$'
    />
  )
}

FormatDollars.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

function FormatPhone(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

FormatPhone.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

const EmployeeMarker = ({ text }) => (
  <Tooltip title={text} placement='top'>
    <Icon color='action'>gps_fixed</Icon>
  </Tooltip>
)

class EmployeesDialog extends Component {
  state = { ...newEmployeeState }

  ref = React.createRef()

  handleChange = event => {
    this.setState(
      _.set(
        { ...this.state },
        event.target.name,
        event.target.type === 'checkbox'
          ? event.target.checked
            ? 'Y'
            : 'N'
          : event.target.value,
      ),
    )
  }

  handleTabChange = (event, value) => {
    this.setState({ value })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { vpCompany } = this.props
    /**
     * After Dialog Open
     */
    const initialState = _.cloneDeepWith(newEmployeeState)
    initialState.Co = this.props.Co
    if (
      !prevProps.employeeDialog.props.open &&
      this.props.employeeDialog.props.open
    ) {
      /**
       * Dialog type: 'edit'
       * Update State
       */
      if (
        this.props.employeeDialog.type === 'edit' &&
        this.props.employeeDialog.data &&
        !_.isEqual(
          this.props.employeeDialog.data,
          prevProps.employeeDialog.data,
        )
      ) {
        this.props.employeeDialog.data.showPassword = false
        const data = this.props.employeeDialog.data
        this.setState(
          { ...initialState, ...this.props.employeeDialog.data },
          () => {
            if (vpCompany && this.state.VPEmployee) {
              this.handleEmployeeSearch()
            }
          },
        )
      }

      /**
       * Dialog type: 'new'
       * Update State
       */

      if (
        this.props.employeeDialog.type === 'new' &&
        !_.isEqual(initialState, prevState)
      ) {
        this.setState({ ...initialState })
      }

      if (
        this.props.employeeDialog.type === 'customer' &&
        this.props.employeeDialog.data &&
        !_.isEqual(
          this.props.employeeDialog.data,
          prevProps.employeeDialog.data,
        )
      ) {
        const data = this.props.employeeDialog.data
        this.props.employeeDialog.type = 'new'
        this.props.employeeDialog.data.showPassword = false
        this.setState({ ...initialState, ...this.props.employeeDialog.data })
      }
    }
    //window["log"]('STATE COMPARISON', this.props.employeeDialog, prevProps, prevState);
    if (
      prevProps.employeeDialog.props.open &&
      this.props.employeeDialog.props.open
    ) {
      if (
        this.props.employeeDialog.type === 'data' &&
        this.props.employeeDialog.data &&
        !_.isEqual(
          this.props.employeeDialog.data,
          prevProps.employeeDialog.data,
        )
      ) {
        const data = this.props.employeeDialog.data
        this.props.employeeDialog.type = 'edit'
        this.props.employeeDialog.data.showPassword = false
        this.setState({ ...this.props.employeeDialog.data })
      }
    }
    if (
      prevProps.employeeDialog.props.open &&
      this.props.employeeDialog.props.open
    ) {
      if (
        this.props.employeeDialog.type === 'add' &&
        this.props.employeeDialog.data &&
        !_.isEqual(
          this.props.employeeDialog.data,
          prevProps.employeeDialog.data,
        )
      ) {
        const data = this.props.employeeDialog.data
        this.props.employeeDialog.type = 'edit'
        this.props.employeeDialog.data.showPassword = false
        this.setState({ ...this.props.employeeDialog.data })
      }
    }
    if (
      prevProps.employeeDialog.props.open &&
      this.props.employeeDialog.props.open
    ) {
      if (
        this.props.employeeDialog.type === 'update' &&
        this.props.employeeDialog.data &&
        !_.isEqual(
          this.props.employeeDialog.data,
          prevProps.employeeDialog.data,
        )
      ) {
        const data = this.props.employeeDialog.data
        this.props.employeeDialog.type = 'edit'
        this.props.employeeDialog.data.showPassword = false
        this.setState({ ...this.props.employeeDialog.data })
      }
    }
  }

  closeComposeDialog = () => {
    this.props.employeeDialog.type === 'edit'
      ? this.props.closeEditEmployeeDialog()
      : this.props.closeNewEmployeeDialog()
    this.setState({
      ...this.state,
      removedFromSplitscreen: true,
      screenOverlay: null,
      posReset: true,
    })
  }

  closeDialogForSplitscreen = () => {
    this.props.employeeDialog.type === 'edit'
      ? this.props.closeEditEmployeeDialog()
      : this.props.closeNewEmployeeDialog()
  }

  canBeSubmitted() {
    const {
      Co,
      FirstName,
      LastName,
      DOB,
      Email,
      Phone,
      DefaultBusinessUnit,
      DefaultDivision,
      DefaultDepartment,
    } = this.state
    return (
      Co > 0 &&
      FirstName &&
      FirstName.length > 0 &&
      LastName &&
      LastName.length > 0 &&
      DefaultBusinessUnit &&
      DefaultDivision &&
      DefaultDepartment
    )
  }
  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }))
  }

  handleDOBChange = event => {
    let { DOB } = this.state
    DOB = event._d.toLocaleDateString('en-US')
    window['warn'](event, DOB)
    this.setState({ ...this.state, DOB })
  }

  handleHireDateChange = event => {
    let { HireDate } = this.state
    HireDate = event._d.toLocaleDateString('en-US')
    window['warn'](event, HireDate)
    this.setState({ ...this.state, HireDate })
  }

  handleTermDateChange = event => {
    let { TermDate } = this.state
    TermDate = event._d.toLocaleDateString('en-US')
    window['warn'](event, TermDate)
    this.setState({ ...this.state, TermDate })
  }

  formatDate(date) {
    const dt = new Date(date)
    const mm = dt.getMonth() + 1
    const dd = dt.getDate()
    const yyyy = dt.getFullYear()
    const formatted =
      yyyy + '-' + (mm <= 9 ? '0' + mm : mm) + '-' + (dd <= 9 ? '0' + dd : dd)
    return date ? formatted : ''
  }

  fileSelectedHandler = event => {
    let { Co, Data } = this.state
    const { employeeDialog } = this.props
    impress(event.target.files[0], 1024, 50, true, img => {
      window['log'](img)
      const fd = new FormData()
      fd.append('image', img.file, img.fileName)
      axios
        .post(
          `${
            window['apiLocation']
          }/api/Attachment/PostProfileImage?Co=${encodeURIComponent(
            this.state.Co,
          )}&Type=Employee&ID=${encodeURIComponent(
            this.state.Employee,
          )}&User=${encodeURIComponent(this.props.user)}`,
          fd,
        )
        .then(res => {
          if (Data) {
            Data.Avatar = img.base64
          } else {
            Data = { Avatar: img.base64 }
          }
          this.setState({ Data })
        })
    })
  }

  handleEmployeeSearch = _.debounce(() => {
    const { vpCompany } = this.props
    const { HQCo1 } = vpCompany || {}
    const { searchDescription, VPEmployee } = this.state
    if (VPEmployee || (searchDescription && searchDescription.length > 0)) {
      const inputValue = (searchDescription || VPEmployee.toString())
        .trim()
        .toLowerCase()
      this.setState({ isSearching: true }, () => {
        axios
          .get(
            `${
              window['apiLocation']
            }/api/VPSettings/Employees?Co=${HQCo1}&search=${encodeURIComponent(
              inputValue,
            )}`,
          )
          .then(response => {
            this.setState({ vpEmployees: response.data, isSearching: false })
          })
          .catch(() => this.setState({ isSearching: false, vpEmployees: [] }))
      })
    } else {
      this.setState({ vpEmployees: [] })
    }
  }, 750)

  AppBarContents = (matches, minimizeFxn, closeFxn) => {
    const { value } = this.state

    return (
      <>
        <IconButton
          style={{
            position: 'absolute',
            right: 54,
            top: !matches.small ? 8 : 4,
            color: 'white',
            //display: matches ? 'block' : 'none'
          }}
          className='dialog-header-icon'
          onClick={minimizeFxn}
        >
          <Icon>minimize</Icon>
        </IconButton>
        <IconButton
          style={{
            position: 'absolute',
            right: 10,
            top: matches ? 10 : 4 /*if fullScreen*/,
            color: 'white',
          }}
          onClick={e => {
            closeFxn(e)
          }}
          className='dialog-header-icon' /*if fullScreen*/
        >
          <Icon>close</Icon>
        </IconButton>
        <Tabs
          value={value}
          onChange={this.handleTabChange}
          variant='scrollable'
          scrollButtons='on'
        >
          <Tab disableRipple icon={<AccountCircle />} label='Info' />
          {/* <Tab disableRipple icon={<Badge badgeContent={this.state.Data && this.state.Data.WorkOrders ? this.state.Data.WorkOrders.length : 0} color="error"><History /></Badge>} label="History" /> */}
          <Tab
            disableRipple
            icon={
              <Badge
                badgeContent={
                  this.state.Data && this.state.Data.Attachments
                    ? this.state.Data.Attachments.length
                    : 0
                }
                color='error'
              >
                <AttachFile />
              </Badge>
            }
            label='Attachments'
          />
          {/* <Tab disableRipple icon={<Dashboard />} label="Dashboard" /> */}
        </Tabs>
      </>
    )
  }

  ToolbarContents = () => {
    const { employeeDialog } = this.props

    return (
      <>
        <Typography variant='subtitle1' color='inherit'>
          {['new', 'customer'].indexOf(employeeDialog.type) > -1
            ? 'New Employee'
            : `Employee #${this.state.Employee} Overview`}
        </Typography>
      </>
    )
  }

  render() {
    const {
      classes,
      securables,
      theme,
      employeeDialog,
      addEmployee,
      updateEmployee,
      removeEmployee,
      openNewWorkOrderDialog,
      googleMapsApiKey,
      businessUnits,
      divisions,
      departments,
      impress,
      users,
      vpCompany,
      qboCompany,
      qboEmployees,
    } = this.props
    const {
      Co,
      Employee,
      Phone,
      value,
      histTab,
      vpEmployees,
      searchDescription,
      isSearching,
    } = this.state
    const histTabs = ['Work Order', 'Visit', 'Invoice', 'Payment', 'Estimate']
    const userAvatar = '../../assets/images/avatars/profile.jpg'
    const accessLevel = _.find(securables, { Securable: 'employees' })
    const histIcons = [
      <Assignment className='text-24 ml-12 mr-12' />,
      <LocalShipping className='text-24 ml-12 mr-12' />,
      <Receipt className='text-24 ml-12 mr-12' />,
      <Payment className='text-24 ml-12 mr-12' />,
      <ListIcon className='text-24 ml-12 mr-12' />,
    ]

    const dialogProps = {
      ...employeeDialog.props,
      className: classes.root,
      onClose: this.closeComposeDialog,
      fullWidth: true,
      maxWidth: 'md',
      //hideBackdrop: true,
      disableEnforceFocus: true,
    }

    return (
      <Media query='(min-width: 1200px)' /**/>
        {matches => (
          <DraggableDialog
            SlideUp={null}
            matches={matches}
            dialogProps={{ ...dialogProps }}
            AppBarChildren={this.AppBarContents}
            ToolbarChildren={this.ToolbarContents}
            closeComposeDialog={this.closeComposeDialog.bind(this)}
            closeDialogForSplitscreen={this.closeDialogForSplitscreen.bind(
              this,
            )}
            header={`Employee ${this.state.Employee}`}
            type='Employee'
            picRef={this.ref.current}
            icon='people'
            dialogState={this.state}
            reopenDialogFxn={this.props.openPreloadEmployeeDialog}
            description={this.state.Description}
            storeDialog={employeeDialog}
            TypeState={this.state.Employee}
            screenOverlay={this.state.screenOverlay}
            //posSet={this.state.posSet}
            //initialWidthSet={initialWidthSet}
            //minHeight={this.state.minHeight}
            //location={this.state.location}
            //heightUpdateFxn={this.heightUpdateFxn.bind(this)}
            //dialogHeight={this.state.dialogHeight}
          >
            {value === 0 && (
              <TabContainer refProp={this.ref}>
                {this.state.FirstName &&
                  this.state.FirstName.length > 0 &&
                  this.state.LastName &&
                  this.state.LastName.length > 0 && (
                    <div
                      className={classNames(
                        classes.profile,
                        'flex flex-col items-center justify-center',
                      )}
                    >
                      <Avatar
                        style={{
                          cursor: 'pointer',
                          boxShadow: '2px 2px 10px 2px #3f3f3f',
                        }}
                        onClick={() => this.fileInput.click()}
                        className='w-1/4 h-auto'
                        alt='contact avatar'
                        src={
                          this.state.Data && this.state.Data.Avatar
                            ? !isNaN(this.state.Data.Avatar)
                              ? getProfileImage(
                                  `Co=${this.state.Co}&ID=${this.state.Data.Avatar}`,
                                )
                              : this.state.Data.Avatar
                            : this.state.avatar
                        }
                      />
                      <input
                        type='file'
                        style={{ display: 'none' }}
                        onChange={this.fileSelectedHandler}
                        ref={fileInput => (this.fileInput = fileInput)}
                      />
                      <Typography variant='h6' color='inherit' className='p-16'>
                        {`${this.state.FirstName} ${
                          this.state.MiddleInitial
                            ? `${this.state.MiddleInitial} `
                            : ''
                        }${this.state.LastName}`}
                      </Typography>
                    </div>
                  )}
                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>account_circle</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='First Name'
                    id='firstName'
                    name='FirstName'
                    value={this.state.FirstName || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    required
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='M.I.'
                    id='middleInitial'
                    name='MiddleInitial'
                    value={this.state.MiddleInitial || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    width={100}
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Last Name'
                    id='lastName'
                    name='LastName'
                    value={this.state.LastName || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    required
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Suffix'
                    id='suffix'
                    name='Suffix'
                    value={this.state.Suffix || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    width={100}
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'></div>
                  <DatePicker
                    variant='inline'
                    inputVariant='outlined'
                    className={classes.formControl}
                    label='DOB'
                    id='dob'
                    name='DOB'
                    format='MM/DD/YYYY'
                    value={this.state.DOB}
                    onChange={this.handleDOBChange}
                    showTodayButton
                    autoOk
                    animateYearScrolling
                    fullWidth
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.status}
                    label='Gender'
                    id='gender'
                    name='Gender'
                    value={this.state.Gender}
                    onChange={this.handleChange}
                    variant='outlined'
                    select
                    fullWidth
                  >
                    <MenuItem value={null}>Unspecified</MenuItem>
                    <MenuItem value='M'>Male</MenuItem>
                    <MenuItem value='F'>Female</MenuItem>
                  </TextField>
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <Autocomplete
                    className={classes.formControl}
                    title='User Account'
                    options={[
                      { UserName: null, FirstName: 'None', LastName: '' },
                      ...users,
                    ]}
                    menuItemComponent={value => {
                      return (
                        <MenuItem value={value.UserName}>
                          <div className='flex'>
                            <Avatar
                              style={{ marginRight: 8 }}
                              classes={{ root: classes.avatarRoot }}
                              className={classes.userAvatar}
                              alt={value.Technician}
                              src={
                                value.Data && value.Data.Avatar
                                  ? getProfileImage(
                                      `Co=${Co}&ID=${value.Data.Avatar}&Thumb=true`,
                                    )
                                  : userAvatar
                              }
                            />
                            {`${value.FirstName} ${value.LastName}`}
                          </div>
                        </MenuItem>
                      )
                    }}
                    noflip={true}
                    portal={!matches}
                    value={this.state.UserName}
                    onSelect={option =>
                      this.setState({
                        ...this.state,
                        UserName: option.UserName,
                      })
                    }
                  />

                  {vpCompany && (
                    <div className='sm:flex w-full'>
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <div className='w-full flex relative'>
                        <Autocomplete
                          className={classes.formControl}
                          title='VP Employee'
                          options={vpEmployees}
                          menuItemComponent={value => (
                            <MenuItem value={value.Employee}>
                              {`${value.Employee} - ${value.FirstName} ${value.LastName}`}
                            </MenuItem>
                          )}
                          portal={true}
                          debounce={750}
                          onChange={text =>
                            this.setState(
                              { ...this.state, searchDescription: text },
                              this.handleEmployeeSearch,
                            )
                          }
                          value={this.state.VPEmployee || ''}
                          onSelect={option =>
                            this.setState({
                              ...this.state,
                              VPCompany: option.PRCo,
                              VPEmployee: option.Employee,
                            })
                          }
                        />
                        {isSearching && (
                          <CircularProgress
                            color='primary'
                            className='absolute pin-r pin-t m-auto'
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: 9,
                              marginTop: 18,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {qboCompany && (
                    <div className='sm:flex w-full'>
                      <div className='hidden sm:block min-w-12 pt-20'></div>
                      <Autocomplete
                        className={classes.formControl}
                        title='QBO Employee'
                        options={qboEmployees}
                        menuItemComponent={value => (
                          <MenuItem value={value.Id}>
                            {`${value.Name}`}
                          </MenuItem>
                        )}
                        portal={true}
                        value={this.state.QBOEmployee || ''}
                        onSelect={option => {
                          if (employeeDialog.type !== 'new') {
                            this.setState({
                              ...this.state,
                              QBOCompany: qboCompany.Co,
                              QBOEmployee: option.Id,
                            })
                          } else {
                            this.setState({
                              ...this.state,
                              QBOCompany: qboCompany.Co,
                              QBOEmployee: option.Id,
                            })
                          }
                        }}
                        // required
                      />
                    </div>
                  )}
                </div>

                {/* <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    <Icon color="action">location_on</Icon>
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Address"
                                    id="address1"
                                    name="Address1"
                                    value={this.state.Address1}
                                    onChange={this.handleChange}
                                    onBlur={this.getAddress}
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                            </div>

                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Address (cont'd)"
                                    id="address2"
                                    name="Address2"
                                    value={this.state.Address2}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>

                            <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="City"
                                    id="city"
                                    name="City"
                                    value={this.state.City}
                                    onChange={this.handleChange}
                                    onBlur={this.getAddress}
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="State"
                                    id="state"
                                    name="State"
                                    value={this.state.State}
                                    onChange={this.handleChange}
                                    onBlur={this.getAddress}
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Zip Code"
                                    id="zip"
                                    name="Zip"
                                    value={this.state.Zip}
                                    onChange={this.handleChange}
                                    onBlur={this.getAddress}
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                            </div> */}

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>phone</Icon>
                  </div>
                  {/* <TextField
                                    className={classes.formControl}
                                    label="Phone"
                                    id="phone"
                                    name="Phone"
                                    value={this.state.Phone || ''}
                                    onChange={this.handlePhoneChange}
                                    variant="outlined"
                                    InputProps={{
                                        inputComponent: FormatPhone
                                    }}
                                    required
                                    fullWidth
                                /> */}
                  <PhoneInput
                    {...this.props}
                    className={classes.formControl}
                    label='Phone'
                    id='phone'
                    name='Phone'
                    value={this.state.Phone || ''}
                    onChange={value => {
                      this.setState({ ...this.state, Phone: value })
                    }}
                    variant='outlined'
                    fullWidth={true}
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  {/* <TextField
                                    className={classes.formControl}
                                    label="Cell"
                                    id="cellPhone"
                                    name="CellPhone"
                                    value={this.state.CellPhone || ''}
                                    onChange={this.handlePhoneChange}
                                    variant="outlined"
                                    InputProps={{
                                        inputComponent: FormatPhone
                                    }}
                                    required
                                    fullWidth
                                /> */}
                  <PhoneInput
                    {...this.props}
                    className={classes.formControl}
                    label='Cell'
                    id='cellPhone'
                    name='CellPhone'
                    value={this.state.CellPhone || ''}
                    onChange={value => {
                      this.setState({ ...this.state, CellPhone: value })
                    }}
                    variant='outlined'
                    fullWidth={true}
                  />
                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Email'
                    id='email'
                    name='Email'
                    value={this.state.Email || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    fullWidth
                  />
                </div>

                {/* <div className="sm:flex">
                                <div className="hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20">
                                    <Icon color="action">account_balance</Icon>
                                </div>
                                <DatePicker variant="inline" inputVariant="outlined"
                                    className={classes.formControl}
                                    label="Hire Date"
                                    id="hireDate"
                                    name="HireDate"
                                    format="MM/DD/YYYY"
                                    value={this.state.HireDate}
                                    onChange={this.handleHireDateChange}
                                    variant="outlined"
                                    showTodayButton
                                    autoOk
                                    animateYearScrolling
                                    required
                                    fullWidth
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.formControl}
                                    label="Weekly Hours"
                                    id="weeklyHrs"
                                    name="WeeklyHrs"
                                    value={this.state.WeeklyHrs}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <TextField
                                    className={classes.status}
                                    label="Salary ?"
                                    id="salaryYN"
                                    name="SalaryYN"
                                    value={this.state.SalaryYN}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    required
                                    select
                                    fullWidth
                                >
                                    <MenuItem value="N">
                                        No
                                        </MenuItem>
                                    <MenuItem value="Y">
                                        Yes
                                        </MenuItem>
                                </TextField>
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                {
                                    (this.state.SalaryYN == 'Y' ?
                                        <TextField
                                            className={classes.formControl}
                                            label="Salary Amount"
                                            id="salaryAmt"
                                            name="SalaryAmt"
                                            value={this.state.SalaryAmt}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            InputProps={{
                                                inputComponent: FormatDollars,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="Toggle Sensitive Info visibility"
                                                            onClick={this.handleClickShowPassword}
                                                        >
                                                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        :
                                        <TextField
                                            className={classes.formControl}
                                            label="Hourly Rate"
                                            id="hourlyAmt"
                                            name="HourlyAmt"
                                            value={this.state.HourlyAmt}
                                            onChange={this.handleChange}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            InputProps={{
                                                inputComponent: FormatDollars,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="Toggle Sensitive Info visibility"
                                                            onClick={this.handleClickShowPassword}
                                                        >
                                                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )
                                }
                                <div className="hidden sm:block min-w-12 pt-20">
                                </div>
                                <DatePicker variant="inline" inputVariant="outlined"
                                    className={classes.formControl}
                                    label="Term Date"
                                    id="termDate"
                                    name="TermDate"
                                    format="MM/DD/YYYY"
                                    value={this.state.TermDate}
                                    onChange={this.handleTermDateChange}
                                    variant="outlined"
                                    showTodayButton
                                    autoOk
                                    animateYearScrolling
                                    required
                                    fullWidth
                                    disabled={this.state.ActiveYN === 'Y'}
                                />
                            </div> */}

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>business</Icon>
                  </div>

                  <TextField
                    className={classes.formControl}
                    label='Default Business Unit'
                    select
                    id='defaultBusinessUnit'
                    name='DefaultBusinessUnit'
                    value={this.state.DefaultBusinessUnit || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    required
                    fullWidth
                  >
                    {businessUnits && businessUnits.length > 0
                      ? businessUnits.map((value, index) => (
                          <MenuItem key={index} value={value.BusinessUnit}>
                            {value.Description}
                          </MenuItem>
                        ))
                      : ''}
                  </TextField>

                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Default Division'
                    id='defaultDivision'
                    select
                    name='DefaultDivision'
                    value={this.state.DefaultDivision || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    required
                    fullWidth
                  >
                    {this.state.DefaultBusinessUnit &&
                    this.state.DefaultBusinessUnit.length > 0 ? (
                      divisions && divisions.length > 0 ? (
                        divisions.map(value =>
                          this.state.DefaultBusinessUnit ==
                          value.BusinessUnit ? (
                            <MenuItem value={value.Division}>
                              {value.Description}
                            </MenuItem>
                          ) : (
                            ''
                          ),
                        )
                      ) : (
                        ''
                      )
                    ) : (
                      <MenuItem value=''>
                        Please Select a Business Unit
                      </MenuItem>
                    )}
                  </TextField>

                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Default Department'
                    id='defaultDepartment'
                    select
                    name='DefaultDepartment'
                    value={this.state.DefaultDepartment || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    required
                    fullWidth
                  >
                    {this.state.DefaultDivision &&
                    this.state.DefaultDivision.length > 0 &&
                    this.state.DefaultBusinessUnit &&
                    this.state.DefaultBusinessUnit.length > 0 ? (
                      departments && departments.length > 0 ? (
                        departments.map(value =>
                          this.state.DefaultDivision == value.Division &&
                          this.state.DefaultBusinessUnit ==
                            value.BusinessUnit ? (
                            <MenuItem value={value.Department}>
                              {value.Description}
                            </MenuItem>
                          ) : (
                            ''
                          ),
                        )
                      ) : (
                        ''
                      )
                    ) : (
                      <MenuItem value=''>Please Select a Division</MenuItem>
                    )}
                  </TextField>
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>monetization_on</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Default Referral Bonus Type'
                    id='defaultReferralBonusType'
                    select
                    name='DefaultReferralBonusType'
                    value={this.state.DefaultReferralBonusType || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    fullWidth
                  >
                    <MenuItem value={null}>None</MenuItem>
                    <MenuItem value='P'>Percentage</MenuItem>
                    <MenuItem value='F'>Fixed Amount</MenuItem>
                  </TextField>

                  <div className='hidden sm:block min-w-12 pt-20'></div>
                  <TextField
                    className={classes.formControl}
                    label='Default Referral Bonus'
                    id='defaultReferralBonus'
                    name='DefaultReferralBonus'
                    value={this.state.DefaultReferralBonus || ''}
                    onChange={this.handleChange}
                    InputProps={{
                      startAdornment:
                        this.state.DefaultReferralBonusType === 'F' ? (
                          <InputAdornment position='start'>$</InputAdornment>
                        ) : null,
                      endAdornment:
                        this.state.DefaultReferralBonusType === 'P' ? (
                          <InputAdornment position='end'>%</InputAdornment>
                        ) : null,
                    }}
                    inputProps={{
                      type: 'number',
                    }}
                    variant='outlined'
                    fullWidth
                    disabled={!this.state.DefaultReferralBonusType}
                  />
                </div>

                <div className='sm:flex'>
                  <div className='hidden sm:block min-w-48 pb-12 sm:pb-4  p-0 sm:pt-20'>
                    <Icon color='action'>note</Icon>
                  </div>
                  <TextField
                    className={classes.formControl}
                    label='Notes'
                    id='notes'
                    name='Notes'
                    value={this.state.Notes || ''}
                    onChange={this.handleChange}
                    variant='outlined'
                    multiline
                    rows={5}
                    fullWidth
                  />
                </div>
                <div className='flex'>
                  <div className='w-1/2'>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.ActiveYN === 'Y'}
                          name='ActiveYN'
                          onChange={this.handleChange}
                          color='primary'
                        />
                      }
                      label='Active?'
                    />
                  </div>
                </div>
              </TabContainer>
            )}
            {/* {value === 1 && employeeDialog.type == 'edit' && <TabContainer>
                            <div className={classes.hist}>
                                <Drawer
                                    variant="permanent"
                                    className={classNames(classes.drawer, {
                                        [classes.drawerOpen]: this.state.open,
                                        [classes.drawerClose]: !this.state.open,
                                    })}
                                    classes={{
                                        paper: classNames(classes.shelf, {
                                            [classes.drawerOpen]: this.state.open,
                                            [classes.drawerClose]: !this.state.open,
                                        }),
                                    }}
                                    open={this.state.open}
                                >
                                    <List>
                                        <ListItem onClick={() => this.setState({ histTab: 0 })} button key="Work Orders">
                                            <ListItemIcon><Tooltip title="Work Orders" placement="right"><Assignment /></Tooltip></ListItemIcon>
                                        </ListItem>
                                        <ListItem onClick={() => this.setState({ histTab: 1 })} button key="Visits">
                                            <ListItemIcon><Tooltip title="Visits" placement="right"><LocalShipping /></Tooltip></ListItemIcon>
                                        </ListItem>
                                        <ListItem onClick={() => this.setState({ histTab: 2 })} button key="Invoices">
                                            <ListItemIcon><Tooltip title="Invoices" placement="right"><Receipt /></Tooltip></ListItemIcon>
                                        </ListItem>
                                        <ListItem onClick={() => this.setState({ histTab: 3 })} button key="Payments">
                                            <ListItemIcon><Tooltip title="Payments" placement="right"><Payment /></Tooltip></ListItemIcon>
                                        </ListItem>
                                        <ListItem onClick={() => this.setState({ histTab: 4 })} button key="Estimates">
                                            <ListItemIcon><Tooltip title="Estimates" placement="right"><ListIcon /></Tooltip></ListItemIcon>
                                        </ListItem>
                                    </List>
                                </Drawer>
                                <div className={classes.content}>
                                    <div className={classes.histTable}>
                                        {histTab === 0 &&
                                            <TabContainer padding={8}>
                                                <WorkOrdersList />
                                            </TabContainer>
                                        }
                                        {histTab === 1 && <TabContainer>Visits</TabContainer>}
                                        {histTab === 2 && <TabContainer>Invoices</TabContainer>}
                                        {histTab === 3 && <TabContainer>Payments</TabContainer>}
                                        {histTab === 4 && <TabContainer>Estimates</TabContainer>}
                                    </div>
                                </div>
                            </div>
                        </TabContainer>} */}
            {value === 1 && employeeDialog.type !== 'new' && (
              <TabContainer>
                <FileList attachments={this.state.Data.Attachments} />
              </TabContainer>
            )}
            {value === 2 && employeeDialog.type == 'edit' && (
              <TabContainer></TabContainer>
            )}

            {accessLevel && accessLevel.AccessLevel !== 'R' && value === 0 && (
              <DialogActions className='dialog-actions justify-between pl-16'>
                {['new', 'customer'].indexOf(employeeDialog.type) > -1 ? (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      var rec = this.state
                      addEmployee(rec)
                      //this.closeComposeDialog();
                    }}
                    disabled={!this.canBeSubmitted()}
                  >
                    Add
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      var rec = this.state
                      updateEmployee(rec)
                    }}
                    disabled={!this.canBeSubmitted()}
                  >
                    Save
                  </Button>
                )}
              </DialogActions>
            )}
          </DraggableDialog>
        )}
      </Media>
    )
  }
}
EmployeesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeEditEmployeeDialog: Actions.closeEditEmployeeDialog,
      closeNewEmployeeDialog: Actions.closeNewEmployeeDialog,
      addEmployee: Actions.addEmployee,
      updateEmployee: Actions.updateEmployee,
      removeEmployee: Actions.removeEmployee,
      openNewWorkOrderDialog: WorkOrderActions.openNewWorkOrderDialog,
      showMessage: showMessage,
      impress: impress,
      openPreloadEmployeeDialog,
    },
    dispatch,
  )
}

function mapStateToProps({ auth, employeesApp, spReducers }) {
  return {
    employeeDialog: employeesApp.employees.employeeDialog,
    googleMapsApiKey: googleMapsApiKey,
    businessUnits: spReducers.businessUnits,
    divisions: spReducers.divisions,
    departments: spReducers.departments,
    user: spReducers.userProfiles.User.UserName,
    users: spReducers.userProfiles.Users,
    Co: spReducers.companies.Co,
    securables: spReducers.userProfiles.User.Data.Securables,
    vpCompany: spReducers.vpSettings.vpCompany,
    vpEmployees: spReducers.vpSettings.employees,
    qboCompany: spReducers.qboSettings.qboCompany,
    qboEmployees: spReducers.qboSettings.employees,
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(EmployeesDialog),
)
