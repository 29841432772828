import { FuseAnimate, FuseUtils } from '@fuse';
import _ from '@lodash';
import { Avatar, Badge, Card, CardHeader, Icon, IconButton, MenuItem, TextField, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker } from "@material-ui/pickers";
import classNames from 'classnames';
import * as ChatActions from 'main/chatPanel/store/actions';
import * as DialerActions from 'main/dialer/store/actions';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { bindActionCreators } from 'redux';
import { openEditTechnicianDialog } from '../technicians/store/actions';
import { updateTableSettings } from '../users/store/actions';
import DispatchBoardDialog from './DispatchBoardDialog';
import DispatchDropCell from './DispatchDropCell';
import DispatchBoardTechnician from './DispatchBoardTechnician';
import * as Actions from './store/actions';
import moment from 'moment';
import axios from 'axios';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const techCriteriaLists = {
    businessUnits: {
        key: "DefaultBusinessUnit",
        format: ["Description"],
        label: "Business Unit",
        listName: "Business Units",
        icon: 'business_center'
    },
    divisions: {
        key: "DefaultDivision",
        format: ["Description"],
        label: "Division",
        listName: "Divisions",
        icon: 'location_city'
    },
    departments: {
        key: "DefaultDepartment",
        format: ["Description"],
        label: "Department",
        listName: "Departments",
        icon: 'business'
    },
    teams: {
        key: "Team",
        format: ["Name"],
        label: "Team",
        listName: "Teams",
        icon: 'supervised_user_circle'
    },
    technicians: {
        key: "Technician",
        format: ["FirstName", "LastName"],
        label: "Technician",
        listName: "Technicians",
        icon: 'people'
    },
}

function LoadingDiv() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .65)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div className="loader stretchBar" style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
            }}>
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div><br />
                <img style={{ width: 80, marginTop: 15 }} src="assets/images/splash/SPLoading.png" />
            </div>
        </div>
    );
};

function NoData() {
    return (
        <div style={{
            padding: 20,
            color: 'rgba(0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, .25)',
            transition: 'all .3s ease',
            zIndex: 10,
            opacity: 1,
            pointerEvents: 'none',
        }}>
            <div style={{
                margin: 'auto',
                left: 0,
                right: 0,
                top: 0,
                bottom: 30,
                width: '100%',
                position: 'absolute',
                paddingTop: 75,
                textAlign: 'center',
                fontWeight: 'bold',
            }}>
                No Technicians Found
            </div>
        </div>
    )
}

const styles = theme => ({
    root: {
        maxHeight: 'calc(100vh - 306px)',
    },
    mobileRoot: {
        maxHeight: 'calc(100vh - 360px)',
    },
    mailItem: {},
    avatarRoot: {
        marginRight: 0,
    },
    avatar: {
        borderRadius: '50%',
        boxShadow: '2px 2px 6px #333',
        width: 48,
        height: 48,
    },
    action: {
        margin: 8,
        marginRight: -4,
        marginTop: 8,
    },
    icon: {
        marginLeft: 12,
        marginRight: 12,
    },
    title: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
    },
    subheader: {
    },
    tripAvatar: {
        borderRadius: '50%',
        boxShadow: '1px 1px 2px rgba(0,0,0,.25)',
        width: 26,
        height: 26,
        backgroundColor: 'rgb(100,100,100)',
    },
    tripAction: {
        margin: 0,
        marginRight: -4,
        marginTop: 0,
    },
    tripIcon: {
        marginLeft: 4,
        marginRight: 12,
    },
    labels: {},
    technician: {
        width: '100%',
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
        alignSelf: 'start',
    },
    trip: {
        width: 'auto',
        border: 'none',
        zIndex: 1,
        overflow: 'visible',
        alignSelf: 'start',
        borderTopLeftRadius: 24,
        borderBottomLeftRadius: 24,
        background: 'rgb(250, 250, 250)',
        boxShadow: '1px 1px 4px #333',
    },
    content: {
        overflow: 'hidden',
    },
    header: {
        width: '100%',
    },
    popper: {
        opacity: 1,
        background: 'transparent',
        margin: 10,
    },
    tooltip: {
        opacity: 1,
        padding: 0,
        margin: 0,
        boxShadow: '2px 2px 6px #3333',
    },
    popper: {
        opacity: 1,
        marginTop: 8,
        zIndex: 4,
        background: '#fff',
    },
    tooltip: {
        opacity: 1,
        padding: 8,
        margin: 0,
        borderRadius: 4,
        background: '#fff',
        boxShadow: '2px 2px 6px #3333',
    },
    badge: {
        top: 5,
        right: 15,
    },
    techBadge: {
        top: 5,
    },
    techNameBadge: {
        top: '90%',
        right: '90%',
        backgroundColor: 'rgba(0,0,0,.5)',
        color: '#fff',
        fontSize: 'x-small',
    },
    openBadge: {
        backgroundColor: '#4dbce9',
        color: '#fff',
    },
    busyBadge: {
        backgroundColor: '#6c4de9',
        color: '#fff',
    },
    capacityBadge: {
        backgroundColor: '#f75151',
        color: '#fff',
    },
    header: {
        zIndex: 4,
    },
    timeLine: {
        borderRight: '1px solid #333',
        height: '75%',
        position: 'absolute',
        left: 0,
        top: '25%'
    },
    timeLineFoot: {
        borderRight: '1px solid #333',
        position: 'absolute',
        left: 0,
    },
    timeLineLabel: {
        position: 'absolute',
        backgroundColor: '#333',
        color: 'white',
        borderRadius: '3px',
        padding: '2px',
        width: 56,
        fontSize: 10
    },
    dbSelect: {
        '& > .menu-icon': {
            display: 'none'
        }
    }
});

class DispatchBoard extends Component {

    state = {
        technicians: this.props.technicians,
        date: this.props.date,
        technicians: [],
        trips: [],
        loading: this.props.loading,
        selectedBoard: 'default',
        pinAnytime: true,
        schedule: {
            board: {},
            list: [],
        },
        down: false,
        x: null,
        left: null,
        isDatepickerOpen: false,
        filtered: false,
        now: moment(),
        dragOverTech: null,
        draggingTech: null,
        dragPosition: 'bottom',
        boardData: [],
        realTrips: [],
        technicianUA: []
    };

    componentDidMount() {
        window["warn"]('Dispatch Board CDM called');
        let { Co, technicians, trips, date, loading, schedule, selectedBoard, sharedBoards, user, techUA } = this.props;

        const dt = moment(date).startOf('day');
        const technicianUA = _.filter(techUA, (o) => moment(o.StartDate) <= dt && moment(o.EndDate || o.StartDate) >= dt);

        const now = moment();

        const sec = (60 - now.toDate().getSeconds());
        setTimeout(() => {
            this.setState({ now: moment() }, () => {
                this.interval = setInterval(() => this.setState({ now: moment() }), 60000);
            })
        }, sec * 1000);

        if (technicians && trips) {

            let boards = user && user.Data && user.Data.DispatchBoards ? _.filter(user.Data.DispatchBoards, (o) => o.SharedYN !== 'Y') : [];
            boards = [...sharedBoards, ...boards];
            const board = _.find(boards, { Co, Board: selectedBoard });

            this.setState({ ...this.state, technicians, trips, date, loading, schedule, selectedBoard, now, board, technicianUA }, this.getData);
        }
        if (moment().format('M/D/YYYY') === moment(date).format('M/D/YYYY')) {
            document.getElementsByClassName('rt-table')[0].scrollLeft = new Date().getHours() * 192;
        } else {
            document.getElementsByClassName('rt-table')[0].scrollLeft = 8 * 192
        }

    }

    componentDidUpdate(prevProps, prevState) {
        let { Co, technicians, trips, date, loading, schedule, selectedBoard, searchText, sharedBoards, user, techUA } = this.props;

        const dt = moment(date).startOf('day');
        const technicianUA = _.filter(techUA, (o) => moment(o.StartDate) <= dt && moment(o.EndDate || o.StartDate) >= dt);

        let boards = user && user.Data && user.Data.DispatchBoards ? _.filter(user.Data.DispatchBoards, (o) => o.SharedYN !== 'Y') : [];
        boards = [...sharedBoards, ...boards];
        const board = _.find(boards, { Co, Board: selectedBoard });

        if (!_.isEqual(board, this.state.board) || !_.isEqual(schedule, prevProps.schedule) || loading !== prevProps.loading || !_.isEqual(selectedBoard, prevProps.selectedBoard) || !_.isEqual(searchText, prevProps.searchText) || !_.isEqual(techUA, prevProps.techUA)) {

            this.setState({ ...this.state, technicians, trips, date, loading, schedule, selectedBoard, board, technicianUA }, this.getData);
        }

        window["warn"]("dispatch date info", date)
    };

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if (searchText.length === 0) {
            return arr;
        }
        let filterData = FuseUtils.filterArrayByString(arr, searchText, ['FirstName', 'LastName', 'Name', 'Address1', 'Address2', 'City', 'State', 'Zip', 'Service', 'Description']);

        return filterData;
    };

    openSelectedDispatchBoardMenu = (event) => {
        this.setState({ selectedDispatchBoardMenu: event.currentTarget });
    };

    closeSelectedDispatchBoardMenu = () => {
        this.setState({ selectedDispatchBoardMenu: null });
    };

    dragOver = (event, el) => {
        event.preventDefault();
    }

    dragLeave = (event, el) => {
        event.preventDefault();
    }

    drop = (event, technician, time) => {
        event.preventDefault();
        window["log"](JSON.parse(event.dataTransfer.getData('text')), technician, time);
    }

    handleRefresh = () => {
        let { date } = this.state;
        const old = this.formatDateInput(date);
        const oldDate = this.formatDateInput(this.props.date);
        this.props.setBoardDate(this.props.Co, date, old);
        this.updateDate(this.props.Co, oldDate, oldDate);
    }

    handleDateChange = (event) => {
        window["warn"](event);
        const date = event._d;
        const old = this.formatDateInput(date);
        const oldDate = this.formatDateInput(this.props.date);
        window["warn"](old);
        const newDate = this.formatDateInput(date);
        window["warn"](newDate);
        if (oldDate !== newDate) {
            this.setState({ ...this.state, date, loading: true, trips: [], }, () => {
                this.props.setBoardDate(this.props.Co, date, oldDate);
                this.updateDate(this.props.Co, newDate, oldDate);
            });
        }
    }

    handleToday = (event) => {
        const date = new Date();
        const old = this.formatDateInput(date);
        const oldDate = this.formatDateInput(this.props.date);
        window["warn"](old);
        const newDate = this.formatDateInput(date);
        window["warn"](newDate);
        this.setState({ ...this.state, date, loading: true, trips: [], }, () => {
            this.props.setBoardDate(this.props.Co, date, oldDate);
            this.updateDate(this.props.Co, newDate, oldDate);
        });
    }

    handleNextDate = () => {
        let { date } = this.state;
        const old = this.formatDateInput(date);
        const oldDate = this.formatDateInput(this.props.date);
        date.setDate(date.getDate() + 1);
        const newDate = this.formatDateInput(date);
        this.setState({ ...this.state, date, loading: true, trips: [], }, () => {
            this.props.setBoardDate(this.props.Co, date, old);
            this.updateDate(this.props.Co, newDate, oldDate);
        });
    }

    handlePrevDate = () => {
        let { date } = this.state;
        const old = this.formatDateInput(date);
        const oldDate = this.formatDateInput(this.props.date);
        date.setDate(date.getDate() - 1);
        const newDate = this.formatDateInput(date);
        this.setState({ ...this.state, date, loading: true, trips: [], }, () => {
            this.props.setBoardDate(this.props.Co, date, old);
            this.updateDate(this.props.Co, newDate, oldDate);
        });
    }

    updateDate = _.debounce((Co, date, oldDate) => {
        this.props.getTripData(Co, date, oldDate);
        this.props.getWOData(Co, date, oldDate);
        if (moment(date).format('M/D/YYYY') !== moment(oldDate).format('M/D/YYYY')) {
            if (moment().format('M/D/YYYY') === moment(date).format('M/D/YYYY')) {
                document.getElementsByClassName('rt-table')[0].scrollLeft = new Date().getHours() * 192;
            } else {
                document.getElementsByClassName('rt-table')[0].scrollLeft = 8 * 192
            }
        }
    }, 1000);

    formatDateInput(dt) {
        return dt.toLocaleDateString('en-US').replace(/\//g, '-');
    }

    formatDateText(dt) {
        window["warn"]('Dispatch Board Date: ', dt);
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return `${days[dt.getDay()]} ${months[dt.getMonth()]} ${dt.getDate()} ${dt.getFullYear()}`;
    }

    handleChange = (event) => {
        window["log"](this, event.target);
        this.setState(_.set({ ...this.state }, event.target.name, event.target.type === 'checkbox' ? event.target.checked ? "Y" : "N" : event.target.value));
    };

    getWindow(time) {
        let hour = time.getHours();
        let minutes = time.getMinutes();
        let ordinal = hour >= 12 ? "P" : "A";
        hour = hour > 12 ? hour -= 12 : (hour < 1 ? hour = 12 : hour);
        minutes = minutes >= 30 ? "30" : "";
        return `${hour}${minutes}${ordinal}`;
    }

    openDatePicker = (e) => {
        this.setDatepickerOpen(true)
    }

    renderDateLabel = (e) => (
        <span onClick={this.openDatePicker} className="cursor-pointer mr-8">{this.formatDateText(this.state.date)}</span>
    );

    handleAnytimePin = (e) => {
        const { pinAnytime } = this.state;
        this.setState({ ...this.state, pinAnytime: !pinAnytime });
    }

    openMenu = (event, tech) => {
        window["warn"](tech);
        this.setState({ ...this.state, anchorEl: event.currentTarget, anchorTech: tech });
    };

    closeMenu = () => {
        this.setState({ ...this.state, anchorEl: null, anchorTech: null });
    };

    handleContactClick = (tech) => {
        this.props.openChatPanel();
        const { employees, user } = this.props;
        const { Co, Employee } = tech;
        const employee = _.find(employees, { Co, Employee: tech.Employee });
        if (employee && employee.UserName && user.UserName !== employee.UserName) {
            this.props.getChat(employee.UserName);
        }
        // this.scrollToTop();
    };

    handleCall = (tech) => {
        const { closeChatPanel, toggleDialer, initCall, dialer } = this.props;
        /*toggleDialer(true);
        initCall(dialer, {
            destinationNumber: tech.Phone.replace(/\(|\)|\.|,|-| /g, ''),
            audio: true,
            video: false,
        });*/
    }

    handleNotify = (tech) => {
        const { Co, date, notifyAll } = this.props;
        const dt = new Date(date).toLocaleDateString('en-US').replace(/\//g, '-');
        window["warn"](Co, tech.Technician, dt);
        notifyAll(Co, tech.Technician, dt);
    }

    handleRowCount = (count) => {
        const { user } = this.props;
        const { TableSettings } = user.Data;
        let prefs = _.find(TableSettings, { Table: 'dispatch-board' });
        if (!prefs) {
            prefs = {
                UserName: user.UserName,
                Table: 'dispatch-board',
                Preferences: {
                    RowCount: count
                }
            };
            TableSettings.push(prefs);
        } else {
            prefs.Preferences.RowCount = count;
        }
        this.props.updateTableSettings(prefs);
    }

    handleSortOrder = (sort) => {
        const { user } = this.props;
        const { TableSettings } = user.Data;
        let prefs = _.find(TableSettings, { Table: 'dispatch-board' });
        if (!prefs) {
            prefs = {
                UserName: user.UserName,
                Table: 'dispatch-board',
                Preferences: {
                    SortOrder: sort
                }
            };
            TableSettings.push(prefs);
        } else {
            prefs.Preferences.SortOrder = sort;
        }
        this.props.updateTableSettings(prefs);
    }

    selectBoard = (e) => {
        const selectedBoard = e.target.value;
        if (selectedBoard !== "+") {
            this.setState({ ...this.state, selectedBoard }, () => this.props.selectDispatchBoard(selectedBoard));
        } else {
            this.props.openNewDispatchBoardDialog();
        }
    }

    setDatepickerOpen = (bool) => {
        this.setState({ isDatepickerOpen: bool });
    }

    setFiltered = () => {
        const { filtered } = this.state;
        this.setState({ filtered: !filtered }, this.getData);
    }

    getData = (update) => {
        const { Co, teams, searchText } = this.props;
        const { loading, schedule, filtered, board } = this.state;
        let data = loading ? [] : this.getFilteredArray(schedule.list ? _.filter(schedule.list, (o) => { return o.Technician.ActiveYN === 'Y' && (!filtered || o.Technician.trips.length > 0) }) : {}, searchText);

        if (board) {
            const options = JSON.parse(board.Options);
            window["warn"]('Custom Board Options: ', options);
            const technicianOrder = options.technicianOrder || [];
            const { criteria } = options;
            Object.keys(criteria).map((paramKey) => {
                const params = criteria[paramKey];
                if (params.length > 0) {
                    const list = techCriteriaLists[paramKey];
                    if (paramKey !== "teams") {
                        if (paramKey !== 'technicians' || !options.inAddition) {
                            data = _.filter(data, (o) => { return params.indexOf(o.Technician[list.key]) > -1 || (criteria.technicians && criteria.technicians.indexOf(o.Technician.Technician) > -1) });
                        }
                    } else {
                        data = _.filter(data, (o) => {
                            let exists = false;
                            params.map((Team) => {
                                const team = _.find(teams, { Co, Team });
                                if (_.find(team.Data.Members, { Co, TeamMember: o.Technician.Technician, ActiveYN: 'Y' }) || _.find(team.Data.Leads, { Co, TeamLead: o.Technician.Technician, ActiveYN: 'Y' })) {
                                    exists = true;
                                }
                            })
                            return exists
                        });
                    }
                }
            });


            let newData = [];

            technicianOrder.map((tech) => {
                const techIndex = _.findIndex(data, (o) => o.Technician.Technician === tech);
                if (techIndex > -1) {
                    const techData = data[techIndex];
                    if (techData) {
                        newData.push(techData);
                        data.splice(techIndex, 1);
                    }
                }
            });

            data = [...newData, ...data];
        }
        const realTrips = _.flatten(_.map(data, (o) => _.filter(o.Technician.trips, (t) => t.Trip > 0)));

        this.setState({ data, realTrips });
    }

    dragStart = (event, tech) => {
        this.setState({ draggingTech: tech }, () => this.props.setDragging(true));
    }

    dragEnd = (event) => {
        this.setState({ draggingTech: null, dragOverTech: null }, () => this.props.setDragging(false));
    }

    dropTech = (e, tech, dragTech, position) => {
        e.preventDefault();
        const { Co, teams } = this.props;
        const { board, schedule } = this.state;

        let sched = _.cloneDeepWith(schedule.list);

        if (board && tech !== dragTech) {
            const options = JSON.parse(board.Options);
            const technicianOrder = options.technicianOrder || [];
            const { criteria } = options;
            Object.keys(criteria).map((paramKey) => {
                const params = criteria[paramKey];
                if (params.length > 0) {
                    const list = techCriteriaLists[paramKey];
                    if (paramKey !== "teams") {
                        sched = _.filter(sched, (o) => { return params.indexOf(o.Technician[list.key]) > -1 });
                    } else {
                        sched = _.filter(sched, (o) => {
                            let exists = false;
                            params.map((Team) => {
                                const team = _.find(teams, { Co, Team });
                                if (_.find(team.Data.Members, { Co, TeamMember: o.Technician.Technician, ActiveYN: 'Y' }) || _.find(team.Data.Leads, { Co, TeamLead: o.Technician.Technician, ActiveYN: 'Y' })) {
                                    exists = true;
                                }
                            })
                            return exists
                        });
                    }
                }
            })

            let newData = [];

            technicianOrder.map((tech) => {
                const techIndex = _.findIndex(sched, (o) => o.Technician.Technician === tech);
                if (techIndex > -1) {
                    const techData = sched[techIndex];
                    if (techData) {
                        newData.push(techData);
                        sched.splice(techIndex, 1);
                    }
                }
            });

            const data = [...newData, ...sched];


            let oldIndex = _.findIndex(data, (o) => o.Technician.Technician === dragTech);
            const techSched = (oldIndex > -1 ? _.cloneDeepWith(data[oldIndex]) : null);
            if (oldIndex > -1) {
                data.splice(oldIndex, 1);
            }
            let index = _.findIndex(data, (o) => o.Technician.Technician === tech);
            if (techSched && index > -1) {
                if (position === "bottom") {
                    index += 1;
                }
                data.splice(index, 0, techSched);
            }

            const newOrder = data.map((tech) => {
                return tech.Technician.Technician;
            });

            const newBoard = {
                ...board,
                Options: JSON.stringify({ ...options, technicianOrder: newOrder })
            }

            // window["warn"]('New Board Tech Order: ', technicianOrder, newOrder, newData, data);
            console.log('new board ', newBoard);
            this.setState({ dragOverTech: null, draggingTech: null, data, board: newBoard }, () => this.props.updateDispatchBoard(newBoard));
        } else {
            this.setState({ dragOverTech: null, draggingTech: null });
        }
    }

    render() {
        const { Co, theme, classes, user, teams, securables, sharedBoards } = this.props;
        const { TableSettings } = user.Data;
        const prefs = _.find(TableSettings, { Table: 'dispatch-board' });
        const accessLevel = _.find(securables, { Securable: 'custom-dispatch-boards' });
        const { trips, date, loading, pinAnytime, anchorTech, down, selectedBoard, filtered, now, draggingTech, dragOverTech, dragPosition, board, data, realTrips, technicianUA } = this.state;
        const nowHrs = now.hour() + (now.minute() / 60);
        const timeLine = Math.round((nowHrs / 24) * 4608);
        // window["warn"]('Dispatch Board Date: ', this.props.date, this.state.date);
        const slots = ["12A", "1230A", "1A", "130A", "2A", "230A", "3A", "330A", "4A", "430A", "5A", "530A", "6A", "630A", "7A", "730A", "8A", "830A", "9A", "930A", "10A", "1030A", "11A", "1130A", "12P", "1230P", "1P", "130P", "2P", "230P", "3P", "330P", "4P", "430P", "5P", "530P", "6P", "630P", "7P", "730P", "8P", "830P", "9P", "930P", "10P", "1030P", "11P", "1130P"];
        const cellTemplate = {
            headerStyle: {
                padding: 20,
                cursor: 'pointer',
                fontSize: 12,
                fontWeight: 'bold'
            },
            style: {
                overflow: 'visible',
                borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                display: 'block',
                padding: 0,
            },
            resizable: false,
            sortable: false,
            width: 96
        };
        const scheduled = [];
        if (anchorTech && anchorTech.tripObj) {
            Object.keys(anchorTech.tripObj).map((key) => {
                const trip = anchorTech.tripObj[key];
                if (trip.Status === 0) {
                    scheduled.push(trip);
                }
            })
        }

        let boards = user && user.Data && user.Data.DispatchBoards ? _.filter(user.Data.DispatchBoards, (o) => o.SharedYN !== 'Y') : [];
        boards = [...sharedBoards, ...boards];

        return (
            <Media query="(min-width: 1024px)">
                {matches =>
                    <div className="p-12 pb-0">
                        <FuseAnimate animation="transition.slideUpIn">
                            <div className="sm:flex p-12 sm:p-0">
                                <Typography variant="h6" style={{ alignItems: 'center' }} className="flex mb-8 cursor-pointer">
                                    <Tooltip placement="top" title={`${realTrips.length} Trips`}>
                                        <Badge classes={{ badge: classes.badge }} max={999} badgeContent={realTrips.length} color="primary">
                                            <Icon onClick={this.setFiltered} color={filtered ? "secondary" : "primary"} className="text-32 mr-12">{filtered ? "filter_list" : "dvr"}</Icon>
                                        </Badge>
                                    </Tooltip>
                                    <TextField
                                        className="m-0"
                                        id="dispatchBoard"
                                        name="CustomBoard"
                                        value={selectedBoard || "default"}
                                        select
                                        margin="dense"
                                        InputProps={{
                                            disableUnderline: true,
                                            className: "text-20"
                                        }}
                                        inputProps={{
                                            className: classes.dbSelect
                                        }}
                                        onChange={this.selectBoard}
                                    >
                                        <MenuItem value="default">
                                            Dispatch Board
                                        </MenuItem>
                                        {_.filter(boards, { Co }).map((value => (
                                            <MenuItem value={value.Board}>
                                                {value.Name}{value.SharedYN === 'Y' && <Icon className="ml-6 text-16 menu-icon" color="action">share</Icon>}
                                            </MenuItem>
                                        )))}
                                        {accessLevel && accessLevel.AccessLevel !== 'R' &&
                                            <MenuItem value="+" className="text-12">
                                                <Icon className="mr-6 text-16" color="primary">add_circle_outline</Icon>New Dispatch Board
                                            </MenuItem>
                                        }
                                    </TextField>
                                    {/* <span>Dispatch Board</span> */}
                                    <Icon color="action" onClick={this.handleRefresh} className={!loading ? "text-24 ml-8 cursor-pointer" : "text-24 ml-8 mt-4 cursor-pointer spin"}>refresh</Icon>
                                </Typography>
                                <div className="flex sm:absolute sm:pin-r pt-4">
                                    <Tooltip title="View Today's Schedule" placement="top">
                                        <div className="flex mr-12">
                                            <Icon onClick={this.handleToday} className="text-24 cursor-pointer" color="action">today</Icon>
                                        </div>
                                    </Tooltip>
                                    <Typography variant="caption" style={{ fontWeight: 'bold', alignItems: 'center', }} className="flex">
                                        <Tooltip title="Select Date" placement="top">
                                            <div className="picker">
                                                <DatePicker
                                                    inputVariant="outlined"
                                                    onOpen={() => this.setDatepickerOpen(true)}
                                                    onClose={() => this.setDatepickerOpen(false)}
                                                    open={this.state.isDatepickerOpen}
                                                    label="Date"
                                                    inputProps={
                                                        {
                                                            style: {
                                                                padding: 12,
                                                            }
                                                        }
                                                    }
                                                    TextFieldComponent={this.renderDateLabel}
                                                    value={date}
                                                    onChange={this.handleDateChange}
                                                    showTodayButton
                                                    autoOk
                                                />
                                            </div>
                                        </Tooltip>
                                        <Icon onClick={this.handlePrevDate} color="action" className="text-24 mr-4 cursor-pointer">keyboard_arrow_left</Icon>
                                        <Icon onClick={this.handleNextDate} color="action" className="text-24 ml-4 cursor-pointer">keyboard_arrow_right</Icon>
                                    </Typography>
                                </div>
                            </div>
                        </FuseAnimate>
                        <ReactTableFixedColumns
                            stripedColor={theme.palette.background.contrast}
                            highlightColor={theme.palette.background.contrast}
                            NoDataComponent={loading ? LoadingDiv : NoData}
                            classes={{ header: classes.header }}
                            getTableProps={(state) => {
                                return matches ? {
                                    className: selectedBoard !== "default" ? down ? "cursor-grabbing" : "cursor-grab" : null,
                                    onMouseDown: (e) => {
                                        e.preventDefault();
                                        const left = document.getElementsByClassName('rt-table')[0].scrollLeft;
                                        const { down, x } = this.state;
                                        const { clientX } = e;
                                        this.setState({ ...this.state, down: true, x: clientX, left }, () => { if (this.props.infoWindow) { this.props.openInfoWindow(null) } });
                                    },
                                    onMouseUp: (e) => {
                                        e.preventDefault();
                                        const left = document.getElementsByClassName('rt-table')[0].scrollLeft;
                                        const { down, x } = this.state;
                                        const { clientX } = e;
                                        this.setState({ ...this.state, down: false, x: null, left: null });
                                    },
                                    onMouseMove: (e) => {
                                        e.preventDefault();
                                        const { down, x, left } = this.state;
                                        const { clientX } = e;
                                        if (down && x && left >= 0) {
                                            document.getElementsByClassName('rt-table')[0].scrollLeft = (left - clientX + x);
                                        }
                                    },
                                    onMouseLeave: (e) => {
                                        this.setState({ ...this.state, down: false, x: null, left: null });
                                    }
                                } : matches
                            }}
                            className={classNames(matches ? classes.root : classes.mobileRoot, "-striped -highlight border-0 rounded p-0"/*, "max-h-full-320 md:max-h-full-264 lg:max-h-full-272"*/)}
                            getTrProps={(state, rowInfo, column) => {
                                return {
                                    className: down ? "min-h-96 cursor-grabbing" : "min-h-96 cursor-grab",
                                    onClick: (e, handleOriginal) => {
                                        //if (rowInfo) {
                                        //    window["warn"](rowInfo.original);
                                        //}
                                    }
                                }
                            }}
                            data={data}
                            columns={[
                                {
                                    Header: "Tech",
                                    fixed: "left",
                                    accessor: "Technician",
                                    style: {
                                        padding: '0px 0px',
                                        // minHeight: loading ? 64 : undefined,
                                        zIndex: 3,
                                        borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                                    },
                                    headerStyle: {
                                        padding: 20,
                                        zIndex: 4,
                                        borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                                        fontSize: 12,
                                        fontWeight: 'bold'
                                    },
                                    getProps: (tr, rowInfo) => {
                                        const { Technician } = (rowInfo && rowInfo.original && rowInfo.original.Technician && rowInfo.original.Technician ? rowInfo.original.Technician : {})
                                        if (board && ((accessLevel && accessLevel.AccessLevel === 'F') || board.AddedBy === user.UserName)) {
                                            return {
                                                style: {
                                                    // paddingTop: (Technician && dragOverTech === Technician) ? 64 : undefined,
                                                    display: (Technician && draggingTech && dragOverTech === Technician) ? 'inline-block' : undefined,
                                                },
                                                onDragEnter: (e) => {
                                                    e.preventDefault();
                                                    if (Technician && draggingTech && Technician !== dragOverTech) {
                                                        this.setState({ dragOverTech: draggingTech !== Technician ? Technician : null });
                                                    }
                                                },
                                                onDragOver: (e) => {
                                                    e.preventDefault();
                                                    const { pageX, pageY, screenX, screenY, movementX, movementY, currentTarget } = e;
                                                    const rect = currentTarget.getBoundingClientRect();
                                                    const { top, bottom, left, right, height } = rect;
                                                    const pos = dragPosition === 'top' && (pageY > (bottom - (height / 4))) ? "bottom" : dragPosition === 'bottom' && (pageY < ((height / 4) + top)) ? "top" : dragPosition;
                                                    // window["log"]('Drag Over Position: ', pageX, pageY, screenX, screenY, movementX, movementY, rect, e);
                                                    if (dragPosition !== pos) {
                                                        this.setState({ dragPosition: pos });
                                                    }
                                                },
                                                onDragLeave: (e) => {
                                                    e.preventDefault();
                                                    // if (Technician === dragOverTech) {
                                                    //     this.setState({ dragOverTech: null });
                                                    // }
                                                },
                                                onDrop: (e) => this.dropTech(e, Technician, draggingTech, dragPosition),
                                                draggable: true,
                                                onDragStart: Technician ? (e) => this.dragStart(e, Technician) : undefined,
                                                onDragEnd: (e) => this.dragEnd(e),
                                                onMouseDown: (e) => e.stopPropagation(),
                                                onMouseMove: (e) => e.stopPropagation(),
                                                onMouseUp: (e) => e.stopPropagation(),
                                            }
                                        } else {
                                            return {
                                                style: {
                                                    // paddingTop: (Technician && dragOverTech === Technician) ? 64 : undefined,
                                                    display: (Technician && draggingTech && dragOverTech === Technician) ? 'inline-block' : undefined,
                                                },
                                            }
                                        }
                                    },
                                    Cell: row => {
                                        return (
                                            <React.Fragment>
                                                {board && dragPosition === 'top' && draggingTech && (dragOverTech === row.value.Technician) &&
                                                    <div className="w-full min-h-96 p-4" style={{ backgroundColor: '#9f9f9f' }}>
                                                    </div>
                                                }
                                                <DispatchBoardTechnician rows={row.original.Rows} technician={row.value} />
                                                {board && dragPosition === 'bottom' && draggingTech && (dragOverTech === row.value.Technician) &&
                                                    <div className="w-full min-h-96 p-4" style={{ backgroundColor: '#9f9f9f' }}>
                                                    </div>
                                                }
                                            </React.Fragment>
                                        );
                                    },
                                    width: matches ? 300 : 76,
                                },
                                {
                                    ...cellTemplate,
                                    style: {
                                        ...cellTemplate.style,
                                        zIndex: pinAnytime ? 3 : 0,
                                    },
                                    headerStyle: {
                                        ...cellTemplate.headerStyle,
                                        zIndex: pinAnytime ? 4 : 0,
                                    },
                                    getProps: (tr, rowInfo) => {
                                        const { Technician } = (rowInfo && rowInfo.original && rowInfo.original.Technician && rowInfo.original.Technician ? rowInfo.original.Technician : {})
                                        return {
                                            style: {
                                                // paddingTop: (Technician && dragOverTech === Technician) ? 64 : undefined,
                                                display: (Technician && draggingTech && dragOverTech === Technician) ? 'inline-block' : undefined,
                                            },
                                        }
                                    },
                                    Header: () => (<Tooltip interactive title={`${!pinAnytime ? 'Pin' : 'Un-pin'} Anytime Column`} placement="top" className="w-full" ><div onClick={this.handleAnytimePin}>Anytime<Icon className="text-24 pin-r pin-t absolute" color={pinAnytime ? "primary" : "action"}>{pinAnytime ? 'bookmark' : 'bookmark_border'}</Icon></div></Tooltip>),
                                    fixed: pinAnytime ? "left" : false,
                                    accessor: "ANYTIME",
                                    Cell: row => {
                                        const { Technician } = row.original.Technician || {};
                                        return (
                                            <React.Fragment>
                                                {board && dragPosition === 'top' && draggingTech && (dragOverTech === Technician) &&
                                                    <div className="w-full min-h-96 p-4" style={{ backgroundColor: '#9f9f9f' }}>
                                                    </div>
                                                }
                                                <DispatchDropCell disableDrop={Boolean(draggingTech)} row={row} rows={row.original.Rows} technician={{ ...row.original.Technician }} now={now} time={null} occupied={row.value ? row.value.occupied : []} trips={row.value && Array.isArray(row.value.trips) ? row.value.trips : []} />
                                                {board && dragPosition === 'bottom' && draggingTech && (dragOverTech === Technician) &&
                                                    <div className="w-full min-h-96 p-4" style={{ backgroundColor: '#9f9f9f' }}>
                                                    </div>
                                                }
                                            </React.Fragment>
                                        );
                                    },
                                    width: 225,
                                },
                                {
                                    ...cellTemplate,
                                    headerStyle: {
                                        padding: 0,
                                        cursor: 'pointer',
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                    },
                                    width: 4608,
                                    maxWidth: 4608,
                                    getProps: (tr, rowInfo) => {
                                        const { Technician } = (rowInfo && rowInfo.original && rowInfo.original.Technician && rowInfo.original.Technician ? rowInfo.original.Technician : {})
                                        return {
                                            style: {
                                                // paddingTop: (Technician && dragOverTech === Technician) ? 64 : undefined,
                                                display: (Technician && draggingTech && dragOverTech === Technician) ? 'inline-block' : undefined,
                                            },
                                        }
                                    },
                                    Header: () => (
                                        <div className="w-full flex">
                                            <div className={classNames(classes.timeLineLabel)} style={{ zIndex: 1, left: timeLine - 28 }}>
                                                {now.format("h:mm A")}
                                            </div>
                                            <div className={classNames(classes.timeLine)} style={{ zIndex: 1, left: timeLine }}></div>
                                            {slots.map((slot, index) => {
                                                const ndt = moment();
                                                ndt.set('hour', 0);
                                                ndt.set('minute', (index * 30));
                                                const time = ndt.format("h:mm A");
                                                return (
                                                    <div className="w-full" style={{ borderRight: '1px solid lightgrey', width: 96, minWidth: 96, maxWidth: 96, position: 'relative' }} onClick={(e) => { e.stopPropagation(); document.getElementsByClassName('rt-table')[0].scrollLeft = (pinAnytime ? 0 : 225) + (96 * index); }}>

                                                        <div className="py-20">{time}</div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ),
                                    accessor: "12A",
                                    Cell: row => {
                                        const { Technician } = row.original.Technician || {};
                                        return (
                                            <React.Fragment>
                                                {board && dragPosition === 'top' && draggingTech && (dragOverTech === Technician) &&
                                                    <div className="w-full min-h-96 p-4" style={{ backgroundColor: '#9f9f9f' }}>
                                                    </div>
                                                }
                                                <div className="w-full flex" style={{ width: 4608, minWidth: 4608, maxWidth: 4608 }}>
                                                    {slots.map((slot, index) => {
                                                        const trips = row.original[slot] && Array.isArray(row.original[slot].trips) ? row.original[slot].trips : [];
                                                        const occupied = row.original[slot] ? row.original[slot].occupied : [];
                                                        const ndt = moment();
                                                        ndt.set('hour', 0);
                                                        ndt.set('minute', (index * 30));
                                                        const time = ndt.format("H:mm");
                                                        return (
                                                            <DispatchDropCell techUA={technicianUA} disableDrop={Boolean(draggingTech)} dragging={this.state.down} border={Boolean(index < 48)} row={row} rows={row.original.Rows} now={now} technician={{ ...row.original.Technician }} time={time} occupied={occupied} trips={trips} />
                                                        );
                                                    })}
                                                </div>
                                                {board && dragPosition === 'bottom' && draggingTech && (dragOverTech === Technician) &&
                                                    <div className="w-full min-h-96 p-4" style={{ backgroundColor: '#9f9f9f' }}>
                                                    </div>
                                                }
                                            </React.Fragment>
                                        )
                                    },
                                    Footer: () => {
                                        return (
                                            <div className="w-full flex relative">
                                                <div className={classNames(classes.timeLineFoot, "pin-b")} style={{ zIndex: 1, left: timeLine, height: 4000 }}></div>
                                            </div>
                                        );
                                    }
                                },
                            ]}
                            defaultSorted={prefs ? (prefs.Preferences.SortOrder || []) : []}
                            defaultPageSize={prefs ? (prefs.Preferences.RowCount || 10) : 10}
                            onPageSizeChange={this.handleRowCount}
                            onSortedChange={this.handleSortOrder}
                            noDataText="No Technicians found"
                        />
                        {accessLevel && accessLevel.AccessLevel !== 'R' &&
                            <div>
                                {board ?
                                    (accessLevel.AccessLevel === 'F' || board.AddedBy === user.UserName) &&
                                    <Typography onClick={() => this.props.openEditDispatchBoardDialog(board)} variant="h6" className="text-12 w-full text-right mt-16 mb-12 pr-12 cursor-pointer pr-36"><Icon className="align-middle text-16 mr-6 mb-4" color="primary">edit</Icon>Edit Dispatch Board</Typography>
                                    :
                                    <Typography onClick={this.props.openNewDispatchBoardDialog} variant="h6" className="text-12 w-full text-right mt-16 mb-12 pr-12 cursor-pointer pr-36"><Icon className="align-middle text-16 mr-6 mb-4" color="primary">add_circle_outline</Icon>New Dispatch Board</Typography>
                                }
                            </div>
                        }
                        <DispatchBoardDialog />
                    </div>
                }
            </Media>
        );
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getWOData: Actions.getWOData,
        getTripData: Actions.getTripData,
        openTechnician: openEditTechnicianDialog,
        setTrips: Actions.setTrips,
        setBoardDate: Actions.setBoardDate,
        loadingTrips: Actions.loadingTrips,
        updateTrips: Actions.updateTrips,
        setSchedule: Actions.setSchedule,
        updateDispatchBoard: Actions.updateDispatchBoard,
        getChat: ChatActions.getChat,
        openChatPanel: ChatActions.openChatPanel,
        toggleDialer: DialerActions.toggleDialer,
        initCall: DialerActions.initCall,
        openInfoWindow: Actions.openInfoWindow,
        notifyAll: Actions.notifyAll,
        openNewDispatchBoardDialog: Actions.openNewDispatchBoardDialog,
        openEditDispatchBoardDialog: Actions.openEditDispatchBoardDialog,
        selectDispatchBoard: Actions.selectDispatchBoard,
        setDragging: Actions.setDragging,
        updateTableSettings,
    }, dispatch);
}

function mapStateToProps({ spReducers, dispatchBoardApp, dialer }) {
    return {
        searchText: dispatchBoardApp.dispatchBoard.searchText,
        user: spReducers.userProfiles.User,
        dispatchBoardData: spReducers.dispatchBoards,
        technicians: spReducers.technicians,
        trips: dispatchBoardApp.dispatchBoard.trips,
        schedule: dispatchBoardApp.dispatchBoard.schedule,
        selectedBoard: dispatchBoardApp.dispatchBoard.selectedBoard,
        date: dispatchBoardApp.dispatchBoard.date,
        loading: dispatchBoardApp.dispatchBoard.loading,
        Co: spReducers.companies.Co,
        dialer: dialer.sipPhone,
        infoWindow: dispatchBoardApp.dispatchBoard.infoWindow,
        securables: spReducers.userProfiles.User.Data.Securables,
        employees: spReducers.employees,
        teams: spReducers.teams,
        techUA: spReducers.techUA,
        sharedBoards: spReducers.boards
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(DispatchBoard)));
