import React, { Component } from 'react';
import StackErrorPage from 'main/content/pages/errors/stack/StackErrorPage';

const initialState = {
    error: false,
    isChunkLoadingError: false
}
class ErrorHandler extends Component {
    constructor(props) {
        super(props)
        this.state = { ...initialState }
    }

    componentDidCatch = (error, info) => {
        console.warn('Error from Component: ', error, info, this.state);

        const isChunkLoadingError = error.message.includes('Loading chunk');

        this.setState({ error, isChunkLoadingError });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.isChunkLoadingError === true) {
            window.location.href = "/";
            return;
        }
    }

    onReset = () => {
        this.setState({ ...initialState });
    }

    render() {
        const { routes, type, noReport } = this.props;

        return this.state.error ? <StackErrorPage noReport={noReport} routes={routes} exception={this.state.error} onReset={this.onReset} snip={this.state.screenshot} /> : this.props.children;
    }
}

export default ErrorHandler;