import axios from 'axios/index'
import {
  openEditTripDialog,
  addTripAssignment,
  ADD_TRIP,
  // @ts-ignore
} from 'main/content/apps/trips/store/actions/trips.actions'
// @ts-ignore
import { openMap } from 'main/chatPanel/store/actions'
import {
  showMessage,
  SUBSCRIBE_LIST,
  UNSUBSCRIBE_LIST,
  GET_WORK_ORDER_DATA,
  setOfflineData,
  setUserProfileData,
  setBoardData,
  setWorkOrderData,
  // @ts-ignore
} from 'store/actions'
// @ts-ignore
import moment from 'moment'
// @ts-ignore
import _ from '@lodash'
import {
  DispatchBoardResponse,
  WorkOrderResponse,
} from '../../../../../../types/apiCalls'
import { RootAppState } from '../../../../../../types/store'

export const SET_BOARD_DATE = '[DISPATCH BOARD APP] SET BOARD DATE'
export const SET_WORK_ORDERS = '[DISPATCH BOARD APP] SET WORK ORDERS'
export const UPDATE_WORK_ORDERS = '[DISPATCH BOARD APP] UPDATE WORK ORDERS'
export const GET_TRIPS = '[DISPATCH BOARD APP] GET TRIPS'
export const SET_TRIPS = '[DISPATCH BOARD APP] SET TRIPS'
export const GET_UATIMES = '[DISPATCH BOARD APP] GET UATIMES'
export const SET_UATIMES = '[DISPATCH BOARD APP] SET UATIMES'
export const SET_TECHNICIANS = '[DISPATCH BOARD APP] SET TECHNICIANS'
export const UPDATE_TRIPS = '[DISPATCH BOARD APP] UPDATE TRIPS'
export const SET_SELECTED_WO = '[DISPATCH BOARD APP] SET SELECTED WO'
export const SET_SCHEDULE = '[DISPATCH SCHEDULE APP] SET SCHEDULE'
export const RESET_SCHEDULE = '[DISPATCH SCHEDULE APP] RESET SCHEDULE'
export const SET_SEARCH_TEXT = '[DISPATCH BOARD APP] SET SEARCH TEXT'
export const SET_WORK_ORDER_INFO = '[DISPATCH BOARD APP] SET WORK ORDER INFO'
export const SET_TRIP_INFO = '[DISPATCH BOARD APP] SET TRIP INFO'
export const SET_TRIP_TOOLTIP = '[DISPATCH BOARD APP] OPEN TRIP TOOLTIP'
export const CREATE_TRIP = '[DISPATCH BOARD APP] CREATE TRIP'
export const UPDATE_TRIP = '[DISPATCH BOARD APP] UPDATE TRIP'
export const DELETE_TRIP = '[DISPATCH BOARD APP] DELETE TRIP'
export const TRIPS_LOADED = '[DISPATCH BOARD APP] TRIPS LOADED'
export const SET_DRAGGING = '[DISPATCH BOARD APP] SET DRAGGING'
export const OPEN_INFO_WINDOW = '[DISPATCH BOARD APP] OPEN INFO WINDOW'
export const OPEN_NEW_DISPATCH_FOLDER_DIALOG =
  '[DISPATCH BOARD APP] OPEN NEW DISPATCH FOLDER DIALOG'
export const CLOSE_NEW_DISPATCH_FOLDER_DIALOG =
  '[DISPATCH BOARD APP] CLOSE NEW DISPATCH FOLDER DIALOG'
export const OPEN_EDIT_DISPATCH_FOLDER_DIALOG =
  '[DISPATCH BOARD APP] OPEN EDIT DISPATCH FOLDER DIALOG'
export const CLOSE_EDIT_DISPATCH_FOLDER_DIALOG =
  '[DISPATCH BOARD APP] CLOSE EDIT DISPATCH FOLDER DIALOG'
export const ADD_DISPATCH_FOLDER = '[DISPATCH BOARD APP] ADD DISPATCH FOLDER'
export const UPDATE_DISPATCH_FOLDER =
  '[DISPATCH BOARD APP] UPDATE DISPATCH FOLDER'
export const OPEN_NEW_DISPATCH_BOARD_DIALOG =
  '[DISPATCH BOARD APP] OPEN NEW DISPATCH BOARD DIALOG'
export const CLOSE_NEW_DISPATCH_BOARD_DIALOG =
  '[DISPATCH BOARD APP] CLOSE NEW DISPATCH BOARD DIALOG'
export const OPEN_EDIT_DISPATCH_BOARD_DIALOG =
  '[DISPATCH BOARD APP] OPEN EDIT DISPATCH BOARD DIALOG'
export const CLOSE_EDIT_DISPATCH_BOARD_DIALOG =
  '[DISPATCH BOARD APP] CLOSE EDIT DISPATCH BOARD DIALOG'
export const ADD_DISPATCH_BOARD = '[DISPATCH BOARD APP] ADD DISPATCH BOARD'
export const UPDATE_DISPATCH_BOARD =
  '[DISPATCH BOARD APP] UPDATE DISPATCH BOARD'
export const SET_SELECTED_DISPATCH_BOARD =
  '[DISPATCH BOARD APP] SET SELECTED DISPATCH BOARD'

export function getTripData(Co: number, dt: string, oldDate: string) {
  if (dt) {
    const request = axios.get(`${window['apiLocation']}/api/DispatchBoard`, {
      params: {
        Co,
        Date: dt,
      },
    })

    return (dispatch, getState) => {
      const state: RootAppState = getState()

      const { technicians } = state.dispatchBoardApp.dispatchBoard
      request.then(response => {
        const data: DispatchBoardResponse = response.data

        Promise.all([
          dispatch(buildBoard(technicians, data, dt.replace(/-/g, '/'))),
          dispatch({
            type: SUBSCRIBE_LIST,
            List: `${Co}_${dt}_Trips`,
          }),
        ])
      })
    }
  }
}

export function getWOData(Co: number, dt: string, oldDate: string) {
  if (dt) {
    const request = axios.get(`${window['apiLocation']}/api/WorkOrder`, {
      params: {
        Co,
        DueBy: dt,
      },
    })

    return (dispatch, getState) => {
      const state: RootAppState = getState()
      const oldWOs = state.spReducers.workOrders
      dispatch({
        type: SET_WORK_ORDERS,
        WorkOrders: [],
      })
      dispatch({
        type: GET_WORK_ORDER_DATA,
        payload: [],
      })
      request
        .then(response => {
          const data: WorkOrderResponse = response.data

          Promise.all([
            dispatch({
              type: SET_WORK_ORDERS,
              WorkOrders: data,
            }),
            dispatch({
              type: GET_WORK_ORDER_DATA,
              payload: data,
            }),
            dispatch({
              type: SUBSCRIBE_LIST,
              List: `${Co}_${dt}_WorkOrders`,
            }),
          ]).then(() => {
            const state: RootAppState = getState()
            const { trips } = state.dispatchBoardApp.dispatchBoard
            dispatch(setBoardTrips(trips))
          })
        })
        .catch(err => {
          dispatch(
            showMessage({
              message: `Error: ${err.message}`,
              autoHideDuration: 30000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
          if (
            new Date(dt).toLocaleDateString() ===
            new Date(oldDate).toLocaleDateString()
          ) {
            dispatch({
              type: SET_WORK_ORDERS,
              WorkOrders: oldWOs,
            })
            dispatch({
              type: GET_WORK_ORDER_DATA,
              payload: oldWOs,
            })
          }
        })
    }
  }
}
export function openInfoWindow(id: string | null) {
  return {
    type: OPEN_INFO_WINDOW,
    id,
  }
}

export function setDragging(dragging) {
  return {
    type: SET_DRAGGING,
    dragging,
  }
}

export function loadingTrips() {
  return {
    type: TRIPS_LOADED,
  }
}

export function selectDispatchBoard(selectedBoard) {
  return {
    type: SET_SELECTED_DISPATCH_BOARD,
    selectedBoard,
  }
}

export function openNewDispatchFolderDialog() {
  return {
    type: OPEN_NEW_DISPATCH_FOLDER_DIALOG,
  }
}

export function closeNewDispatchFolderDialog() {
  return {
    type: CLOSE_NEW_DISPATCH_FOLDER_DIALOG,
  }
}

export function openEditDispatchFolderDialog(data) {
  return {
    type: OPEN_EDIT_DISPATCH_FOLDER_DIALOG,
    data,
  }
}

export function closeEditDispatchFolderDialog() {
  return {
    type: CLOSE_EDIT_DISPATCH_FOLDER_DIALOG,
  }
}

export function addDispatchFolder(newDispatchFolder) {
  return (dispatch, getState) => {
    const state = getState()
    const { spReducers } = state
    const { User } = spReducers.userProfiles

    const folders = _.cloneDeepWith(User.Data.DispatchFolders)

    const request = axios.post(
      `${window['apiLocation']}/api/CustomFolder`,
      newDispatchFolder,
    )

    return request.then(response =>
      Promise.all([
        dispatch({
          type: ADD_DISPATCH_FOLDER,
        }),
      ]).then(() => {
        if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
          dispatch(
            showMessage({
              message: `Error: ${response.data.Data.ErrMsg}`,
              autoHideDuration: 30000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
        } else {
          folders.push(response.data)
          dispatch(
            setUserProfileData({
              ...User,
              Data: { ...User.Data, DispatchFolders: folders },
            }),
          )
          dispatch(
            showMessage({
              message: `Dispatch Folder #${response.data.Folder} has been successfully created.`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          )
        }
      }),
    )
  }
}

export function updateDispatchFolder(dispatchFolder) {
  return (dispatch, getState) => {
    const state = getState()
    const { spReducers } = state
    const { User } = spReducers.userProfiles

    const folders = _.cloneDeepWith(User.Data.DispatchFolders)

    const { Co, Folder } = dispatchFolder

    const request = axios.put(
      `${window['apiLocation']}/api/CustomFolder?Co=${encodeURIComponent(
        Co,
      )}&Folder=${encodeURIComponent(dispatchFolder.Folder)}`,
      dispatchFolder,
    )

    return request.then(response =>
      Promise.all([
        dispatch({
          type: UPDATE_DISPATCH_FOLDER,
        }),
      ]).then(() => {
        if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
          dispatch(
            showMessage({
              message: `Error: ${response.data.Data.ErrMsg}`,
              autoHideDuration: 30000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
        } else {
          const index = _.findIndex(folders, { Co, Folder })
          if (index > -1) {
            folders.splice(index, 1, response.data)
            dispatch(
              setUserProfileData({
                ...User,
                Data: { ...User.Data, DispatchFolders: folders },
              }),
            )
          }
          dispatch(
            showMessage({
              message: `Dispatch Folder ${dispatchFolder.Folder} has been successfully updated.`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          )
        }
      }),
    )
  }
}

export function openNewDispatchBoardDialog() {
  return {
    type: OPEN_NEW_DISPATCH_BOARD_DIALOG,
  }
}

export function closeNewDispatchBoardDialog() {
  return {
    type: CLOSE_NEW_DISPATCH_BOARD_DIALOG,
  }
}

export function openEditDispatchBoardDialog(data) {
  return {
    type: OPEN_EDIT_DISPATCH_BOARD_DIALOG,
    data,
  }
}

export function closeEditDispatchBoardDialog() {
  return {
    type: CLOSE_EDIT_DISPATCH_BOARD_DIALOG,
  }
}

export function addDispatchBoard(newDispatchBoard) {
  return (dispatch, getState) => {
    const state = getState()
    const { spReducers } = state
    const { User } = spReducers.userProfiles

    const boards = _.cloneDeepWith(User.Data.DispatchBoards)

    const request = axios.post(
      `${window['apiLocation']}/api/CustomDispatchBoard`,
      newDispatchBoard,
    )

    return request.then(response =>
      Promise.all([
        dispatch({
          type: ADD_DISPATCH_BOARD,
        }),
      ]).then(() => {
        if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
          dispatch(
            showMessage({
              message: `Error: ${response.data.Data.ErrMsg}`,
              autoHideDuration: 30000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
        } else {
          boards.push(response.data)
          Promise.all([
            dispatch(
              setUserProfileData({
                ...User,
                Data: { ...User.Data, DispatchBoards: boards },
              }),
            ),
          ]).then(() => {
            dispatch(selectDispatchBoard(response.data.Board))
          })
          dispatch(
            showMessage({
              message: `Dispatch Board #${response.data.Board} has been successfully created.`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          )
        }
      }),
    )
  }
}

export function updateDispatchBoard(dispatchBoard) {
  return (dispatch, getState) => {
    const state = getState()
    const { spReducers } = state
    const { User } = spReducers.userProfiles

    const { Co, Board } = dispatchBoard

    const boards = _.cloneDeepWith(User.Data.DispatchBoards)
    const sharedBoards = _.cloneDeepWith(spReducers.boards)

    const request = axios.put(
      `${window['apiLocation']}/api/CustomDispatchBoard?Co=${encodeURIComponent(
        Co,
      )}&Board=${encodeURIComponent(dispatchBoard.Board)}`,
      dispatchBoard,
    )

    return request.then(response =>
      Promise.all([
        dispatch({
          type: UPDATE_DISPATCH_BOARD,
        }),
      ]).then(() => {
        if (response.data.Data.ErrMsg && response.data.Data.ErrMsg.length > 0) {
          dispatch(
            showMessage({
              message: `Error: ${response.data.Data.ErrMsg}`,
              autoHideDuration: 30000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
        } else {
          const index = _.findIndex(boards, { Co, Board })
          const sharedIndex = _.findIndex(sharedBoards, { Co, Board })
          if (index > -1) {
            boards.splice(index, 1, response.data)
            dispatch(
              setUserProfileData({
                ...User,
                Data: { ...User.Data, DispatchBoards: boards },
              }),
            )
          } //else

          if (sharedIndex > -1) {
            sharedBoards.splice(sharedIndex, 1, response.data)
            dispatch(setBoardData(sharedBoards))
          }
          dispatch(
            showMessage({
              message: `Dispatch Board #${dispatchBoard.Board} has been successfully updated.`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          )
        }
      }),
    )
  }
}

export function setBoardDate(Co, date, oldDate) {
  return dispatch => {
    Promise.all([
      dispatch({
        type: SET_BOARD_DATE,
        date,
      }),
      dispatch({
        type: UNSUBSCRIBE_LIST,
        List: `${Co}_${oldDate}_Trips`,
      }),
      dispatch({
        type: UNSUBSCRIBE_LIST,
        List: `${Co}_${oldDate}_WorkOrders`,
      }),
    ])
  }
}

export function setWorkOrders(WorkOrders) {
  return {
    type: SET_WORK_ORDERS,
    WorkOrders,
  }
}

export function setSchedule(schedule) {
  return {
    type: SET_SCHEDULE,
    schedule,
  }
}

export function setTrips(Trips) {
  return (dispatch, getState) => {
    const state = getState()
    const { technicians, date } = state.dispatchBoardApp.dispatchBoard
    dispatch(buildBoard(technicians, Trips, date))
  }
}

export function updateTrips(Trips) {
  return (dispatch, getState) => {
    const state = getState()
    const { technicians, date } = state.dispatchBoardApp.dispatchBoard
    dispatch(buildBoard(technicians, Trips, date))
  }
}

export function setTechnicians(Technicians) {
  return (dispatch, getState) => {
    const state = getState()
    const { trips, date } = state.dispatchBoardApp.dispatchBoard
    dispatch(buildBoard(Technicians, trips, date))
  }
}

export function setSearchText(event) {
  return {
    type: SET_SEARCH_TEXT,
    searchText: event.target.value,
  }
}

export function setWorkOrderInfo(WorkOrder) {
  return {
    type: SET_WORK_ORDER_INFO,
    WorkOrder,
  }
}

export function setTripInfo(Trip) {
  return {
    type: SET_TRIP_INFO,
    Trip,
  }
}

export function setTripTooltip(Trip) {
  return {
    type: SET_TRIP_TOOLTIP,
    Trip,
  }
}

export function createTrip(trip, multi) {
  return (dispatch, getState) => {
    const { Co, WorkOrder, Trip, Data } = trip
    const { Assignments } = Data || {}
    const workOrders = _.cloneDeepWith(getState().spReducers.workOrders)
    const wo = _.find(workOrders, { Co, WorkOrder })
    if (wo) {
      ;(Assignments || []).forEach(assn => {
        const scp = _.find(wo.Data.Scopes, { Scope: assn.Scope })
        if (scp) {
          scp.Data.Assignments.push(_.cloneDeepWith(assn))
        }
      })
      Promise.all([dispatch(setWorkOrderData(workOrders))]).then(() => {
        dispatch(addTrip(trip, multi))
      })
    } else {
      dispatch(addTrip(trip, multi))
    }
  }
}

export function addTrip(trip, multi) {
  // window["warn"]('New Trip pre-send info: ', trip, multi);
  return (dispatch, getState) => {
    const state = getState()
    const url = `${window['apiLocation']}/api/DispatchBoard`
    const request = axios.post(url, { ...trip })
    if (multi) {
      const boardDate = moment(
        state.dispatchBoardApp.dispatchBoard.date,
      ).format('MM/DD/YYYY')
      return request
        .then(response => {
          let ErrMsg = ''
          response.data.map(res => {
            if (res.Data.ErrMsg && res.Data.ErrMsg.length > 0) {
              ErrMsg += `${res.Data.ErrMsg} | `
            } else {
              const {
                InProgressTime,
                ArrivedTime,
                EnRouteTime,
                ScheduledTime,
                ScheduledDate,
              } = res
              const dt = moment(
                InProgressTime ||
                  ArrivedTime ||
                  EnRouteTime ||
                  ScheduledTime ||
                  ScheduledDate,
              ).format('MM/DD/YYYY')
              // window["log"]('BoardDate > Trip Date: ', boardDate, dt)
              if (dt === boardDate) {
                dispatch(updateTechnicianTrip(res, 'ADD'))
              }
              dispatch({
                type: ADD_TRIP,
                data: res,
              })
            }
          })
          if (ErrMsg.length > 0) {
            dispatch(
              showMessage({
                message: `Error: ${ErrMsg}`,
                autoHideDuration: 30000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'error',
              }),
            )
          } else {
            dispatch(
              showMessage({
                message: `Trips were successfully created for Work Order #${trip.WorkOrder}.`,
                autoHideDuration: 5000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'success',
              }),
            )
          }
        })
        .catch(error => {
          console.warn('Error adding trip: ', error)
          if (error.message) {
            dispatch(
              showMessage({
                message: `${error.message}`,
                autoHideDuration: 5000,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
                variant: 'offline',
              }),
            )
          }
        })
    } else {
      return request
        .then(response => {
          if (
            !response.data.Data.Assignments ||
            response.data.Data.Assignments.length < 1
          ) {
            if (
              trip.Data &&
              trip.Data.Scopes &&
              trip.Data.Scopes.length === 1
            ) {
              const { Co, WorkOrder, Trip } = response.data
              const assignment = {
                Co,
                WorkOrder,
                Trip,
                Scope: trip.Data.Scopes[0].Scope,
              }
              if (response.data.Data.Assignments) {
                response.data.Data.Assignments.push(assignment)
              } else {
                response.data.Data.Assignments = [assignment]
              }
              dispatch(
                addTripAssignment(response.data, trip.Data.Scopes[0].Scope),
              )
            } else {
              if (response.data.Data.Scopes) {
                if (response.data.Data.Scopes.length === 1) {
                  const { Co, WorkOrder, Trip } = response.data
                  const assignment = {
                    Co,
                    WorkOrder,
                    Trip,
                    Scope: response.data.Data.Scopes[0].Scope,
                  }
                  if (response.data.Data.Assignments) {
                    response.data.Data.Assignments.push(assignment)
                  } else {
                    response.data.Data.Assignments = [assignment]
                  }
                  dispatch(
                    addTripAssignment(
                      response.data,
                      response.data.Data.Scopes[0].Scope,
                    ),
                  )
                } else {
                  dispatch(openEditTripDialog(response.data))
                }
              }
            }
          }
          if (
            response.data.Data.ErrMsg &&
            response.data.Data.ErrMsg.length > 0
          ) {
            dispatch(
              showMessage({
                message: `Error: ${response.data.Data.ErrMsg}`,
                autoHideDuration: 30000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'error',
              }),
            )
          } else {
            dispatch(
              showMessage({
                message: `Trip #${response.data.Trip} has been successfully created for Work Order #${response.data.WorkOrder}.`,
                autoHideDuration: 5000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'success',
              }),
            )
          }
        })
        .catch(error => {
          console.warn('Error adding trip: ', error)
          if (error.message) {
            dispatch(
              showMessage({
                message: `${error.message}`,
                autoHideDuration: 5000,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
                variant: 'offline',
              }),
            )
          }
        })
    }
  }
}

export function notifyAll(Co, tech, date) {
  return (dispatch, getState) => {
    const request = axios.put(
      `${window['apiLocation']}/api/DispatchBoard?Co=${encodeURIComponent(
        Co,
      )}&Technician=${encodeURIComponent(tech)}&Date=${encodeURIComponent(
        date,
      )}`,
    )

    return request.then(response =>
      Promise.all([
        dispatch({
          type: UPDATE_TRIP,
          data: response.data,
        }),
      ]).then(() => {
        if (response.data && response.data.length > 0) {
          dispatch(
            showMessage({
              message: `Error: ${response.data}`,
              autoHideDuration: 30000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
        } else {
          dispatch(
            showMessage({
              message: `This Technician's scheduled trips have now been marked as Notified.`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          )
        }
      }),
    )
  }
}

export function setSelectedWO(selectedWO) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_SELECTED_WO,
      selectedWO,
    })
    dispatch(openMap())
  }
}

export function updateTrip(trip) {
  return (dispatch, getState) => {
    const { Co, WorkOrder, Trip } = trip
    const url = `${
      window['apiLocation']
    }/api/DispatchBoard?Co=${encodeURIComponent(
      Co,
    )}&WorkOrder=${encodeURIComponent(WorkOrder)}&Trip=${encodeURIComponent(
      Trip,
    )}`
    const request = axios.put(url, { ...trip })

    return request
      .then(response =>
        Promise.all([
          dispatch({
            type: UPDATE_TRIP,
            data: response.data,
          }),
        ]).then(() => {
          if (
            response.data.Data.ErrMsg &&
            response.data.Data.ErrMsg.length > 0
          ) {
            dispatch(
              showMessage({
                message: `Error: ${response.data.Data.ErrMsg}`,
                autoHideDuration: 30000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'error',
              }),
            )
          } else {
            dispatch(
              showMessage({
                message: `Trip #${response.data.Trip} has been successfully updated for Work Order #${response.data.WorkOrder}.`,
                autoHideDuration: 5000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                variant: 'success',
              }),
            )
          }
        }),
      )
      .catch(error => {
        console.warn('Error updating trip: ', error, request)
        if (error.message) {
          dispatch(
            showMessage({
              message: `${error.message}`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              variant: 'offline',
            }),
          )
        }
        if (error.request) {
          dispatch(
            setOfflineData('UPDATE', 'put', url, { ...trip }, 'dispatch-board'),
          )
        }
      })
  }
}

export function deleteTrip(trip) {
  return (dispatch, getState) => {
    const { Co, WorkOrder, Trip, Data } = trip
    const { Assignments } = Data || {}
    const workOrders = _.cloneDeepWith(getState().spReducers.workOrders)
    const wo = _.find(workOrders, { Co, WorkOrder })
    if (wo) {
      ;(Assignments || []).forEach(assn => {
        const scp = _.find(wo.Data.Scopes, { Scope: assn.Scope })
        if (scp) {
          const index = _.findIndex(scp.Data.Assignments, { Trip })
          if (index > -1) {
            scp.Data.Assignments.splice(index, 1)
            if (scp.Data.Assignments.length < 1) {
              scp.LeadTechnician = scp.TentativeLeadTechnician
            }
          }
        }
      })
      Promise.all([dispatch(setWorkOrderData(workOrders))]).then(() => {
        dispatch(removeTrip(trip))
      })
    } else {
      dispatch(removeTrip(trip))
    }
  }
}

export function removeTrip(trip) {
  return (dispatch, getState) => {
    const { Co, WorkOrder, Trip } = trip
    const url = `${
      window['apiLocation']
    }/api/DispatchBoard?Co=${encodeURIComponent(
      Co,
    )}&WorkOrder=${encodeURIComponent(WorkOrder)}&Trip=${encodeURIComponent(
      Trip,
    )}`
    const request = axios.delete(url)

    return request
      .then(response => {
        if (response.data && response.data.length > 0) {
          dispatch(
            showMessage({
              message: `Error: ${response.data}`,
              autoHideDuration: 30000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'error',
            }),
          )
        } else {
          dispatch(
            showMessage({
              message: `Trip #${Trip} has been successfully deleted from Work Order #${WorkOrder}.`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          )
        }
      })
      .catch(error => {
        console.warn('Error deleting trip: ', error, request)
        if (error.message) {
          dispatch(
            showMessage({
              message: `${error.message}`,
              autoHideDuration: 5000,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              variant: 'offline',
            }),
          )
        }
        if (error.request) {
          dispatch(
            setOfflineData(
              'REMOVE',
              'delete',
              url,
              { ...trip },
              'dispatch-board',
            ),
          )
        }
      })
  }
}

function getWindow(time) {
  let hour = time.getHours()
  let minutes = time.getMinutes()
  let ordinal = hour >= 12 ? 'P' : 'A'
  hour = hour > 12 ? (hour -= 12) : hour < 1 ? (hour = 12) : hour
  minutes = minutes >= 30 ? '30' : ''
  return `${hour}${minutes}${ordinal}`
}

export function resetBoard() {
  return {
    type: RESET_SCHEDULE,
  }
}

export function setBoardTechnicians(technicians) {
  return (dispatch, getState) => {
    const state = getState()
    const { dispatchBoard } = state.dispatchBoardApp
    const { trips, date } = dispatchBoard
    dispatch(buildBoard(technicians, trips, date))
  }
}

export function setBoardTrips(trips) {
  return (dispatch, getState) => {
    const state = getState()
    const { dispatchBoard } = state.dispatchBoardApp
    const { technicians, date } = dispatchBoard
    dispatch(buildBoard(technicians, trips, date))
  }
}

function getTimespan(data) {
  if (typeof data !== 'object' || data === null) {
    return null
  }
  let StartTime = data.ScheduledTime ? new Date(data.ScheduledTime) : null
  let EndTime = data.ScheduledTime ? new Date(data.ScheduledTime) : null
  if (EndTime) {
    EndTime.setHours(EndTime.getHours() + (data.Duration || 1))
  }
  if (data.Status >= 4) {
    const activeTime =
      data.EnRouteTime ||
      data.ArrivedTime ||
      data.InProgressTime ||
      data.BoardTime
    const st = new Date(activeTime)
    const min = new Date(st.toLocaleDateString('en-us'))
    const max = new Date(min)
    max.setDate(max.getDate() + 1)
    let end = new Date(activeTime)
    end.setHours(end.getHours() + (data.Duration || 0.5))
    if (data.CompletedTime) {
      end = new Date(data.CompletedTime)
    }
    if (!StartTime) {
      StartTime = st
      EndTime = end
    }
    if (StartTime < min) {
      StartTime = min
    }
    if (EndTime > max) {
      EndTime = max
    }
    if (StartTime > st) {
      StartTime = st
    } else {
      EndTime = end
    }
  }
  data.StartTime = StartTime
  data.EndTime = EndTime
  window['warn'](typeof data, StartTime, EndTime)
  return { StartTime, EndTime }
}

export function buildBoard(technicians, trips, dt) {
  const date = new Date(dt)
  // window['warn']('Build Board with: ', technicians, trips, date, dt)
  return (dispatch, getState) => {
    const wos = []
    const state = getState()
    const { workOrders, arrivalWindows, services } = state.spReducers
    const unassigned = _.filter(workOrders, o => {
      return (
        _.filter(o.Data.Scopes, s => {
          return (
            s.Status !== 4 &&
            new Date(s.DueBy).toLocaleDateString('en-US') ===
              new Date(date).toLocaleDateString('en-US') &&
            s.Data.Assignments.length < 1
          )
        }).length > 0
      )
    })
    unassigned.map(wo => {
      wo.Data.Scopes.map(scp => {
        if (
          new Date(scp.DueBy).toLocaleDateString('en-US') ===
            new Date(date).toLocaleDateString('en-US') &&
          Boolean(scp.LeadTechnician) &&
          scp.Status !== 4 &&
          scp.Data.Assignments < 1
        ) {
          const {
            Co,
            WorkOrder,
            Scope,
            BusinessUnit,
            Division,
            Service,
            ArrivalWindow,
            LeadTechnician,
            DueBy,
            ExactTime,
          } = scp
          const srv = _.find(services, { Co, Service })
          const Duration = scp.EstDuration || srv ? srv.DefaultDuration || 1 : 1
          const aw = _.find(arrivalWindows, { Co, BusinessUnit, ArrivalWindow })
          const ndt = moment(DueBy).format('MM/DD/YYYY')
          const BoardTime = ExactTime
            ? `${ndt} ${ExactTime}`
            : aw && aw.StartTime
              ? `${ndt} ${aw.StartTime}`
              : null
          const ScheduledTime = ExactTime
            ? new Date(`${ndt} ${ExactTime}`)
            : aw && aw.StartTime
              ? new Date(`${ndt} ${aw.StartTime}`)
              : null
          wos.push({
            ID: `${Co}-${WorkOrder}-${Scope}`,
            Co,
            WorkOrder,
            Trip: 0,
            Technician: LeadTechnician,
            Status: 0,
            Duration,
            BoardTime,
            ScheduledDate: new Date(DueBy),
            ScheduledTime,
            Data: {
              Assignments: [{ Co, WorkOrder, Trip: 0, Scope }],
            },
          })
        }
      })
    })
    let list = []
    let board = {}
    const end = new Date(date).setDate(date.getDate() + 1)
    var sorted = _.sortBy(
      [...wos, ...trips],
      o => getTimespan(o).StartTime || end,
    )
    _.filter(technicians, { ActiveYN: 'Y' }).map(tech => {
      if (tech) {
        const { Co, Technician } = tech
        var trp = _.filter(sorted, { Co, Technician })
        board[Technician] = {
          ANYTIME: { occupied: [], trips: [] },
          '12A': { occupied: [], trips: [] },
          '1230A': { occupied: [], trips: [] },
          '1A': { occupied: [], trips: [] },
          '130A': { occupied: [], trips: [] },
          '2A': { occupied: [], trips: [] },
          '230A': { occupied: [], trips: [] },
          '3A': { occupied: [], trips: [] },
          '330A': { occupied: [], trips: [] },
          '4A': { occupied: [], trips: [] },
          '430A': { occupied: [], trips: [] },
          '5A': { occupied: [], trips: [] },
          '530A': { occupied: [], trips: [] },
          '6A': { occupied: [], trips: [] },
          '630A': { occupied: [], trips: [] },
          '7A': { occupied: [], trips: [] },
          '730A': { occupied: [], trips: [] },
          '8A': { occupied: [], trips: [] },
          '830A': { occupied: [], trips: [] },
          '9A': { occupied: [], trips: [] },
          '930A': { occupied: [], trips: [] },
          '10A': { occupied: [], trips: [] },
          '1030A': { occupied: [], trips: [] },
          '11A': { occupied: [], trips: [] },
          '1130A': { occupied: [], trips: [] },
          '12P': { occupied: [], trips: [] },
          '1230P': { occupied: [], trips: [] },
          '1P': { occupied: [], trips: [] },
          '130P': { occupied: [], trips: [] },
          '2P': { occupied: [], trips: [] },
          '230P': { occupied: [], trips: [] },
          '3P': { occupied: [], trips: [] },
          '330P': { occupied: [], trips: [] },
          '4P': { occupied: [], trips: [] },
          '430P': { occupied: [], trips: [] },
          '5P': { occupied: [], trips: [] },
          '530P': { occupied: [], trips: [] },
          '6P': { occupied: [], trips: [] },
          '630P': { occupied: [], trips: [] },
          '7P': { occupied: [], trips: [] },
          '730P': { occupied: [], trips: [] },
          '8P': { occupied: [], trips: [] },
          '830P': { occupied: [], trips: [] },
          '9P': { occupied: [], trips: [] },
          '930P': { occupied: [], trips: [] },
          '10P': { occupied: [], trips: [] },
          '1030P': { occupied: [], trips: [] },
          '11P': { occupied: [], trips: [] },
          '1130P': { occupied: [], trips: [] },
          Technician: {
            ...tech,
            trips: _.filter(trp, o => o.Trip > 0),
            tripObj: {},
            duration: 0,
          },
          Rows: 0,
        }
        trp.map(trip => {
          var { BoardTime, ActualDuration, Duration, StartTime, EndTime } = trip
          var start = StartTime
          var actualStart = moment(BoardTime).local().toDate()
          if (
            !BoardTime ||
            actualStart.toLocaleDateString('en-US') !==
              date.toLocaleDateString('en-US')
          ) {
            board[Technician]['ANYTIME'].trips.push(trip)
            board[Technician].Technician.tripObj[trip.ID] = trip
            if (!BoardTime && trip.Trip > 0) {
              board[Technician].Technician.duration += trip.Duration || 0
            }
            let row = board[Technician]['ANYTIME'].trips.length
            if (board[Technician].Rows < row) {
              board[Technician].Rows = row
            }
          } else {
            var duration =
              (EndTime.getTime() - StartTime.getTime()) / 1000 / 60 / 60
            if (duration < 0.5) {
              duration = 0.5
            }
            var end = EndTime
            var startWindow = getWindow(StartTime)
            var startWindowRows = board[Technician][startWindow].trips.length
            var startWindowOccupied = board[Technician][startWindow].occupied
            var cells = Math.ceil(duration * 2)
            // window["warn"]('Build Trip: ', trip, start, end, cells, duration);
            if ([0, 30].indexOf(start.getMinutes()) < 0) {
              cells += 1
            }
            let row = 0
            while (startWindowOccupied.indexOf(row) > -1) {
              row += 1
            }
            trip.row = row
            if (board[Technician].Rows < row + 1) {
              board[Technician].Rows = row + 1
            }
            for (var w = 0; w < cells; w++) {
              var occupied = moment(StartTime).local().toDate()
              occupied.setTime(occupied.getTime() + w * 30 * 60 * 1000)
              var padWindow = getWindow(occupied)
              board[Technician][padWindow].occupied.push(row)
            }
            board[Technician][getWindow(actualStart)].trips.push(trip)
            board[Technician].Technician.tripObj[trip.ID] = trip
            if (trip.Trip > 0) {
              board[Technician].Technician.duration += duration
            }
          }
        })
      }
    })
    const keys = Object.keys(board)
    for (var k = 0; k < keys.length; k++) {
      list.push(board[keys[k]])
    }
    const schedule = { board, list }

    dispatch({
      type: SET_SCHEDULE,
      technicians: _.filter(technicians, { ActiveYN: 'Y' }),
      schedule,
      trips,
    })
  }
}

export function updateTechnicianTrip(trp, method) {
  return (dispatch, getState) => {
    const { Co, WorkOrder, Trip, Technician } = trp
    const state = getState()
    const wos = []
    const { workOrders, arrivalWindows, services } = state.spReducers
    const unassigned = _.filter(workOrders, o => {
      return (
        _.filter(o.Data.Scopes, s => {
          return (
            s.Status !== 4 &&
            new Date(s.DueBy).toLocaleDateString('en-US') ===
              new Date(date).toLocaleDateString('en-US') &&
            s.Data.Assignments.length < 1
          )
        }).length > 0
      )
    })
    unassigned.map(wo => {
      wo.Data.Scopes.map(scp => {
        if (
          new Date(scp.DueBy).toLocaleDateString('en-US') ===
            new Date(date).toLocaleDateString('en-US') &&
          Boolean(scp.LeadTechnician) &&
          scp.Status !== 4 &&
          scp.Data.Assignments < 1
        ) {
          const {
            Co,
            WorkOrder,
            Scope,
            BusinessUnit,
            Division,
            Service,
            ArrivalWindow,
            LeadTechnician,
            DueBy,
            ExactTime,
          } = scp
          const srv = _.find(services, { Co, Service })
          const Duration = scp.EstDuration || srv ? srv.DefaultDuration || 1 : 1
          const aw = _.find(arrivalWindows, { Co, BusinessUnit, ArrivalWindow })
          const ndt = moment(DueBy).format('MM/DD/YYYY')
          const BoardTime = ExactTime
            ? `${ndt} ${ExactTime}`
            : aw && aw.StartTime
              ? `${ndt} ${aw.StartTime}`
              : null
          const ScheduledTime = ExactTime
            ? new Date(`${ndt} ${ExactTime}`)
            : aw && aw.StartTime
              ? new Date(`${ndt} ${aw.StartTime}`)
              : null
          wos.push({
            ID: `${Co}-${WorkOrder}-${Scope}`,
            Co,
            WorkOrder,
            Trip: 0,
            Technician: LeadTechnician,
            Status: 0,
            Duration,
            BoardTime,
            ScheduledDate: new Date(DueBy),
            ScheduledTime,
            Data: {
              Assignments: [{ Co, WorkOrder, Trip: 0, Scope }],
            },
          })
        }
      })
    })
    const { dispatchBoard } = state.dispatchBoardApp
    const { technicians, date, schedule } = _.cloneDeepWith(dispatchBoard)
    let { board } = schedule
    const trips = _.cloneDeepWith([...dispatchBoard.trips, ...wos])
    const tech = _.find(technicians, { Technician })
    const index = _.findIndex(trips, { Co, WorkOrder, Trip })
    let oldTech = _.findKey(
      board,
      o => _.findIndex(o.Technician.trips, { Co, WorkOrder, Trip }) > -1,
    )
    // window["warn"]('Old Tech: ', technicians);
    let newSched
    if (oldTech) {
      const old = _.find(technicians, { Technician: oldTech })
      const oldTechTrips = _.filter(
        trips,
        o =>
          o.Technician === oldTech &&
          !(o.WorkOrder === WorkOrder && o.Trip === Trip),
      )
      // window["warn"]('Old Tech: ', oldTech, old, technicians);
      newSched = buildTechnicianBoard(
        technicians,
        old,
        oldTechTrips,
        date,
        board,
      )
      // window["warn"]('New Schedule before new Tech: ', _.cloneDeepWith(newSched));
    }
    switch (method) {
      case 'ADD':
        {
          if (index < 0) {
            trips.push(trp)
          } else {
            trips.splice(index, 1, trp)
          }
        }
        break
      case 'UPDATE':
        {
          if (index < 0) {
            trips.push(trp)
          } else {
            trips.splice(index, 1, trp)
          }
        }
        break
      case 'REMOVE':
        {
          if (index > -1) {
            trips.splice(index, 1)
          }
        }
        break
    }
    const techTrips = _.filter(trips, { Technician })

    // window["warn"]('Dispatch Board Trip Updated: ', technicians, tech, trips, techTrips, date);
    newSched = buildTechnicianBoard(technicians, tech, techTrips, date, board)
    // window["warn"]('New Schedule after new Tech: ', _.cloneDeepWith(newSched));
    dispatch({
      type: SET_SCHEDULE,
      ...newSched,
      trips,
    })
  }
}

export function buildTechnicianBoard(technicians, tech, trips, dt, board) {
  const date = new Date(dt)
  let list = []
  var sorted = _.sortBy(trips, o => getTimespan(o).StartTime)
  if (tech) {
    const { Technician } = tech
    board[Technician] = {
      ANYTIME: { occupied: [], trips: [] },
      '12A': { occupied: [], trips: [] },
      '1230A': { occupied: [], trips: [] },
      '1A': { occupied: [], trips: [] },
      '130A': { occupied: [], trips: [] },
      '2A': { occupied: [], trips: [] },
      '230A': { occupied: [], trips: [] },
      '3A': { occupied: [], trips: [] },
      '330A': { occupied: [], trips: [] },
      '4A': { occupied: [], trips: [] },
      '430A': { occupied: [], trips: [] },
      '5A': { occupied: [], trips: [] },
      '530A': { occupied: [], trips: [] },
      '6A': { occupied: [], trips: [] },
      '630A': { occupied: [], trips: [] },
      '7A': { occupied: [], trips: [] },
      '730A': { occupied: [], trips: [] },
      '8A': { occupied: [], trips: [] },
      '830A': { occupied: [], trips: [] },
      '9A': { occupied: [], trips: [] },
      '930A': { occupied: [], trips: [] },
      '10A': { occupied: [], trips: [] },
      '1030A': { occupied: [], trips: [] },
      '11A': { occupied: [], trips: [] },
      '1130A': { occupied: [], trips: [] },
      '12P': { occupied: [], trips: [] },
      '1230P': { occupied: [], trips: [] },
      '1P': { occupied: [], trips: [] },
      '130P': { occupied: [], trips: [] },
      '2P': { occupied: [], trips: [] },
      '230P': { occupied: [], trips: [] },
      '3P': { occupied: [], trips: [] },
      '330P': { occupied: [], trips: [] },
      '4P': { occupied: [], trips: [] },
      '430P': { occupied: [], trips: [] },
      '5P': { occupied: [], trips: [] },
      '530P': { occupied: [], trips: [] },
      '6P': { occupied: [], trips: [] },
      '630P': { occupied: [], trips: [] },
      '7P': { occupied: [], trips: [] },
      '730P': { occupied: [], trips: [] },
      '8P': { occupied: [], trips: [] },
      '830P': { occupied: [], trips: [] },
      '9P': { occupied: [], trips: [] },
      '930P': { occupied: [], trips: [] },
      '10P': { occupied: [], trips: [] },
      '1030P': { occupied: [], trips: [] },
      '11P': { occupied: [], trips: [] },
      '1130P': { occupied: [], trips: [] },
      Technician: {
        ...tech,
        trips: _.filter(sorted, o => o.Trip > 0),
        tripObj: {},
        duration: 0,
      },
      Rows: 0,
    }
    var trp = sorted
    trp.map(trip => {
      var { BoardTime, ActualDuration, Duration, StartTime, EndTime } = trip
      var start = StartTime
      var actualStart = moment(BoardTime).local().toDate()
      if (
        !BoardTime ||
        actualStart.toLocaleDateString('en-US') !==
          date.toLocaleDateString('en-US')
      ) {
        board[Technician]['ANYTIME'].trips.push(trip)
        board[Technician].Technician.tripObj[trip.ID] = trip
        if (!BoardTime && trip.Trip > 0) {
          board[Technician].Technician.duration += trip.Duration || 0
        }
        let row = board[Technician]['ANYTIME'].trips.length
        if (board[Technician].Rows < row) {
          board[Technician].Rows = row
        }
      } else {
        var duration =
          (EndTime.getTime() - StartTime.getTime()) / 1000 / 60 / 60
        if (duration < 0.5) {
          duration = 0.5
        }
        var end = EndTime
        var startWindow = getWindow(StartTime)
        var startWindowRows = board[Technician][startWindow].trips.length
        var startWindowOccupied = board[Technician][startWindow].occupied
        var cells = Math.ceil(duration * 2)
        // window["warn"]('Build Trip: ', trip, start, end, cells, duration);
        if ([0, 30].indexOf(start.getMinutes()) < 0) {
          cells += 1
        }
        let row = 0
        while (startWindowOccupied.indexOf(row) > -1) {
          row += 1
        }
        trip.row = row
        if (board[Technician].Rows < row + 1) {
          board[Technician].Rows = row + 1
        }
        for (var w = 0; w < cells; w++) {
          var occupied = moment(StartTime).local().toDate()
          occupied.setTime(occupied.getTime() + w * 30 * 60 * 1000)
          var padWindow = getWindow(occupied)
          board[Technician][padWindow].occupied.push(row)
        }
        board[Technician][getWindow(actualStart)].trips.push(trip)
        board[Technician].Technician.tripObj[trip.ID] = trip
        if (trip.Trip > 0) {
          board[Technician].Technician.duration += duration
        }
      }
    })
    const keys = Object.keys(board)
    for (var k = 0; k < keys.length; k++) {
      list.push(board[keys[k]])
    }
  }

  const schedule = { board, list }

  // window["warn"]('Dispatch Board Schedule Updated: ', schedule);
  return {
    technicians: _.filter(technicians, { ActiveYN: 'Y' }),
    schedule,
    trips,
  }
}
